import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { FaStop } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import { useStopwatch } from "react-timer-hook";
import { FaMicrophone } from "react-icons/fa";
import { configJson } from "../../../../Constent/config";
import { convertToKolkataTime, convertToUTC } from "../../../../helpers";
import {
  addPatientToList,
  updatePatientStatus,
} from "../../../../redux/slices/patientsSlice";
import MessageModel from "./MessageModel";
import {
  Feedrecorder,
  TimeFeedCounterContainer,
} from "../../../ThemeStyle/RegisterStyle";

const AudioStreamer = ({ patientId, interactionIdFirst, handleExportImageUpload, }) => {
  const [isStreaming, setIsStreaming] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [decode, setDecode] = useState({});
  const [audioUrl, setAudioUrl] = useState(null);
  const [bucketUrl, setBucketUrl] = useState(null);
  const socketRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const recordedChunks = useRef([]);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const animationFrameRef = useRef(null);
  const canvasRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModalBtn, setShowModalBtn] = useState(false);
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    patient_id: "",
    doctor_id: "",
    timestamputc: "",
    organization_id: "",
    timestamp: "",
  });
  const location = useLocation();

  // console.log(isRecording, id, "isRecordingoutside");
  const [showModal, setShowModal] = useState(false);
  const [streamfailed, setStreamfailed] = useState(false);
  const handleRetry = async () => {
    setShowModalBtn(false);
    setIsLoading(true);
    await handleSubmitAudio(audioUrl);
  };
  // console.log(bucketUrl, "bucketUrl");
  const handleCloseModal = () => {
    setShowModalBtn(false);
  };
  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: false,
  });

  // Keep the ref in sync with the state
  useEffect(() => {
    // Only update sessionStorage when the state 'isRecording' actually changes
    const currentRecordingStatus = sessionStorage.getItem("isRecording");

    // Only set sessionStorage if the value is different from the current state
    if (currentRecordingStatus !== String(isRecording)) {
      sessionStorage.setItem("isRecording", isRecording);
    }
  }, [isRecording]);

  const startStreaming = async () => {
    const baseURL = window.location.origin;
    // Conditionally determine if we're in HTTPS (production) or HTTP (local development)
    const websocketURL = baseURL.startsWith("https://")
      ? baseURL.replace("https://", "wss://")
      : "ws://localhost:5000";
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setIsRecording(true);
      start();
      // WebSocket setup
      console.log(`${websocketURL}`, "`${websocketURL}`");
      // console.log("wss://dev.medscribe.in");
      // const socket = new WebSocket("wss://dev.medscribe.in"); // Replace with your server address
      // const socket = new WebSocket("ws://localhost:5000"); // Replace with your server address

      const socket = new WebSocket(`${websocketURL}`); // Replace with your server address

      socketRef.current = socket;

      socket.onopen = () => {
        console.log("WebSocket connection established.");
        setIsStreaming(true);

        const mediaRecorder = new MediaRecorder(stream, {
          mimeType: "audio/webm",
        });
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunks.current.push(event.data); // Store chunks locally
            socket.send(event.data); // Stream data to server
          }
        };

        mediaRecorder.start(100); // Send audio chunks every 100ms

        // Start visualizing the waveform
        startWaveformVisualization(stream);
      };
      socket.onmessage = async (event) => {
        console.log("Message received:", event);

        try {
          const parsedData = await JSON.parse(event.data); // Parse the JSON string to an object
          if (parsedData) {
            setBucketUrl(parsedData.url); // Use the parsed object to access the URL
          } else {
            toast.error("URL error: 'url' field is missing");
          }
        } catch (error) {
          console.error("Error parsing event data:", error);
          toast.error("Invalid data format received");
        }
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        setStreamfailed(true);
      };

      socket.onclose = () => {
        console.log("WebSocket connection closed.");
        stopWaveformVisualization();
        setIsStreaming(false);
        stream.getTracks().forEach((track) => track.stop());
      };
    } catch (error) {
      console.error("Error accessing microphone:", error);
      setStreamfailed(true);
    }
  };

  const stopStreaming = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(recordedChunks.current, { type: "audio/webm" });
        const audioUrl = URL.createObjectURL(blob);
        setAudioUrl(audioUrl);
        recordedChunks.current = []; // Clear recorded chunks
      };
    }
    if (socketRef.current) {
      socketRef.current.close();
    }
    stopWaveformVisualization();
    setIsStreaming(false);
    setIsRecording(false);
    console.log(bucketUrl, "bucketUrlupload");
    reset(0, false);
    updatedata(bucketUrl);
  };

  const startWaveformVisualization = (stream) => {
    const audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    audioContextRef.current = audioContext;

    const analyser = audioContext.createAnalyser();
    analyser.fftSize = 2048;
    analyserRef.current = analyser;

    const source = audioContext.createMediaStreamSource(stream);
    source.connect(analyser);

    const canvas = canvasRef.current;
    const canvasContext = canvas.getContext("2d");
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);

    const drawWaveform = () => {
      analyser.getByteTimeDomainData(dataArray);

      canvasContext.fillStyle =  "#06AEB6";
      canvasContext.fillRect(0, 0, canvas.width, canvas.height);
      const gradient = canvasContext.createLinearGradient(0, 0, canvas.width, 0);
      gradient.addColorStop(0, "#ffffff");
      gradient.addColorStop(1, "#ffffff");

      canvasContext.lineWidth = 2;
      canvasContext.strokeStyle = gradient;
      canvasContext.beginPath();

      const sliceWidth = canvas.width / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          canvasContext.moveTo(x, y);
        } else {
          canvasContext.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasContext.lineTo(canvas.width, canvas.height / 2);
      canvasContext.stroke();

      animationFrameRef.current = requestAnimationFrame(drawWaveform);
    };

    drawWaveform();
  };

  const stopWaveformVisualization = () => {
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
  };

  //create a new interaction
  async function updatedata(url) {
    if (!url) {
      toast.error("url not working", url);
      setStreamfailed(true);
      return;
    }
    const imageUrl = await handleExportImageUpload(); // Get image URL
    const mydata = {
      interaction_id: interactionIdFirst,
      patient_id: formData.patient_id,
      doctor_id: formData.doctor_id,
      interaction_date: formData.timestamputc,
      organization_id: formData.organization_id,
      audioUrl: url,
      imageUrl: imageUrl,    // Image URL
      // types: decode.role,
    };
    // console.log(mydata);
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${configJson.backend_URL}/interaction/feedRecorder`,
        mydata,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response);
      if (response.data.status === true) {
        console.log("Audio upload success full");
        setBucketUrl(null);
        if (interactionIdFirst) {
          localStorage.removeItem("interactionIdFirst");
        }
        dispatch(addPatientToList(response?.data?.result));
        // dispatch(addPatientToTop(response?.data?.result));
        const changeStatus_color = {
          patient_id: response?.data?.result?.patient_id,
          interaction_status: "0",
        };
        dispatch(updatePatientStatus(changeStatus_color));
        checkInteractionStatus(response?.data?.result?.interaction_id);
        if (decode?.role === "doctor") {
          navigate(`/activity?id=${response?.data?.result?.patient_id}`);
        } else if (decode?.role === "front office staff") {
          navigate(
            `/activity?id=${response?.data?.result?.patient_id}&doctor=null`
          );
        }
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }
  // console.log("checkInteractionStatus:", isRecording);
  //Check Status
  const checkInteractionStatus = async (interaction_id) => {
    try {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      };
      const response = await fetch(
        `${configJson.backend_URL}/interaction/interactions/status/${interaction_id}`,
        requestOptions
      );
      if (!response.ok) {
        toast.error("refersh the page");
      }
      const data = await response.json();
      if (data?.status_ok === "audio") {
        setTimeout(() => startPolling({ interaction_id }), 10000);
      } else if (data?.status_ok === "raw-text") {
        const changeStatus = {
          patient_id: data?.interaction?.patient_id,
          interaction_status: data?.interaction?.interaction_status,
        };
        if (decode?.role === "doctor") {
          dispatch(updatePatientStatus(changeStatus));
        }
        setTimeout(() => startPolling({ interaction_id }), 10000);
      } else if (data?.status_ok === "ingest") {
        const changeStatus = {
          patient_id: data?.interaction?.patient_id,
          interaction_status: data?.interaction?.interaction_status,
        };

        if (decode?.role === "doctor") {
          // console.log(window.location.pathname+window.location.search===`/activity?id=${data?.interaction?.patient_id}` && isRecording ,window.location.pathname+window.location.search===`/activity?id=${data?.interaction?.patient_id}` ,isRecording)
          if (
            window.location.pathname + window.location.search ===
            `/activity?id=${data?.interaction?.patient_id}`
          ) {
            console.log("id inside true");
            const recordStatus = sessionStorage.getItem("isRecording");
            console.log("isRecordingRef");
            if (recordStatus === "true") {
              console.log("isRecordingRef.current");
              const changeStatus_color = {
                patient_id: data?.interaction?.patient_id,
                interaction_status: "6",
              };
              dispatch(updatePatientStatus(changeStatus_color));
            } else if (recordStatus === "false") {
              console.log("id inside isRecording else");
              dispatch(updatePatientStatus(changeStatus));
              navigate(`/activity?id=${data?.interaction?.patient_id}`);
            }
          } else {
            console.log("id inside else");
            const changeStatus_color = {
              patient_id: data?.interaction?.patient_id,
              interaction_status: "6",
            };
            dispatch(updatePatientStatus(changeStatus_color));
          }
        } else if (decode?.role === "front office staff") {
          const targetPath = `/activity?id=${data?.interaction?.patient_id}`;
          if (
            window.location.pathname + window.location.search === targetPath &&
            !isRecording
          ) {
            navigate(
              `/activity?id=${data?.interaction?.patient_id}&doctor=null`
            );
          }
        }
      }
    } catch (error) {
      console.error("Error checking button status:", error);
    }
  };
  //check 10s ones
  function startPolling({ interaction_id }) {
    checkInteractionStatus(interaction_id);
  }

  const handleSubmitAudio = async (blobUrl) => {
 
    if (!blobUrl) {
      setMessage("Please record audio");
      setShowModal(true);
      setIsLoading(false);
      return;
    }

    const audioBlob = await fetch(blobUrl).then((res) => res.blob());
    setIsLoading(true);

    const retryUploadWithFieldValidation = async () => {
      let result = formData?.timestamp
        ?.replace(/[-:]/g, "_")
        .replace(", ", "_");
      const formData2 = new FormData();
      formData2.append(
        "file_name",
        `audio_files/${formData?.patient_id}_${result}.webm`
      );
      formData2.append("audio_file", audioBlob, `${formData?.patient_id}.webm`);

      const maxRetries = 2;
      let attempts = 0;

      while (attempts < maxRetries) {
        try {
          const response = await fetch(
            `${configJson.backend_URL}/soapPrompt/upload`,
            {
              method: "POST",
              body: formData2,
            }
          );

          if (response.ok) {
            const result = await response.json();
            console.log("Audio stored in bucket");
            const url = result.gcs_uri;

            // Here add specific validation for fields if necessary
            if (formData?.patient_id && formData?.timestamp) {
              await updatedata(url);
              setIsLoading(false);
              setShowModalBtn(false); // Ensure modal is closed on success
              return;
            } else {
              console.warn("Invalid fields found.");
              attempts++;
            }
          } else {
            console.warn("Attempt failed:", response.status);
            attempts++;
          }
        } catch (error) {
          console.error("Error on attempt", attempts, ":", error);
          attempts++;
        }
      }

      // If max retries are reached, show the modal
      setShowModalBtn(true);
      setIsLoading(false);
    };

    // Initial upload attempt with field-specific retries
    await retryUploadWithFieldValidation();
  };

  useEffect(() => {
    console.log(" stopWaveformVisualization");
    return () => {
      stopWaveformVisualization();
    };
  }, []);
  useEffect(() => {
    const initializeForm = () => {
      // Convert date to UTC and Kolkata time
      const formattedDateUTC = convertToUTC();
      const formattedDateKolkata = convertToKolkataTime(formattedDateUTC);
      // console.log(formattedDateKolkata);

      // Get token from session storage
      const token = sessionStorage.getItem("orgtoken");
      if (!token) {
        navigate("/login");
        return;
      }

      try {
        // Decode token and update state
        const decoded = jwtDecode(token);
        setDecode(decoded);

        setFormData((prevFormData) => ({
          ...prevFormData,
          patient_id: patientId,
          timestamp: formattedDateKolkata,
          timestamputc: formattedDateUTC,
          organization_id: decoded?.organization_id,
          doctor_id: decoded?.role === "doctor" ? decoded?.doctor_id : null,
        }));
      } catch (error) {
        console.error("Error decoding token:", error);
        navigate("/login");
      }
    };

    initializeForm();
  }, [setFormData, navigate, patientId]);
  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }
  const setShowModals = (data) => {
    setShowModal(false);
  };
  const handleDownload = () => {
    // Create a temporary link element
    let result = formData?.timestamp?.replace(/[-:]/g, "_").replace(", ", "_");
    const a = document.createElement("a");
    a.href = audioUrl; // Set the href to the blob URL
    a.download = `${formData?.patient_id}_${result}.mp3`; // Specify a default file name for download
    a.click(); // Programmatically click the link to trigger the download
    a.remove(); // Clean up the element
  };

  // Modal logic
  const RetryModal = ({ show, onRetry, onClose, handleDownloads }) => (
    <div
      className={`modal ${show ? "d-block" : "d-none"}`}
      tabIndex="-1"
      role="dialog"
      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Upload Failed</h5>
            <button
              type="button"
              className="close"
              onClick={onClose}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Failed to upload the audio file due to invalid fields.</p>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-primary"
              type="button"
              onClick={handleDownloads}
            >
              Download Audio
            </button>
            <button type="button" className="btn btn-primary" onClick={onRetry}>
              Retry
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <div className="flex items-center w-full relative">
        <div
          className={`bg-white rounded-full p-[5px] flex items-center justify-center z-20 border ${
            isRecording ? "border-[#FF0000]" : "border-[#06AEB6]"
          }`}
        >
          <button
            className={`text-white rounded-full bg-[#FF0000] p-3 ${
              isRecording ? "pulse-effect" : ""
            }`}
            onClick={isRecording ? stopStreaming : startStreaming}
          >
            {isRecording ? (
              <FaStop className="text-4xl" />
            ) : (
              <FaMicrophone className="text-4xl" />
            )}
          </button>
        </div>
        <div className="bg-[#06AEB6] text-white p-3 rounded-full absolute left-10  z-10 flex items-center h-[55px] overflow-hidden">
          <div className="flex items-center gap-1 text-sm ml-8">
            <span className="">
              {hours > 9 ? "" : <span>0</span>}
              {hours}:
            </span>
            <span className="">
              {minutes > 9 ? "" : <span>0</span>}
              {minutes}:
            </span>
            <span className="">
              {seconds > 9 ? "" : <span>0</span>}
              {seconds}
            </span>
          </div>
          <div className="min-w-[70px]">
            {/* {isRecording ? (
              <video
                src={`/wave.mp4`}
                autoPlay
                loop
                muted
                playsinline
                width={100}
                height={80}
                className="mix-blend-lighten"
              />
            ) : (
              <img src={`/wave.png`} width={100} height={80} />
            )} */}
            <canvas ref={canvasRef} style={styles.canvas}></canvas>
          </div>
        </div>
        {streamfailed ? (
          <button onClick={() => handleSubmitAudio(audioUrl)}>Submit</button>
        ) : (
          ""
        )}
      </div>
      <MessageModel
        showModal={showModal}
        messageData={message}
        setShowModal={setShowModals}
      />
      {showModalBtn ? (
        <RetryModal
          show={showModalBtn}
          onRetry={handleRetry}
          onClose={handleCloseModal}
          handleDownloads={handleDownload}
        />
      ) : (
        ""
      )}
    </>
    // <Feedrecorder style={styles.container}>
    //   <button
    //     className="btn-theme"
    //     onClick={isStreaming ? stopStreaming : startStreaming}
    //   >
    //     {isStreaming ? (
    //       <FaStop className="text-red-500 text-2xl" /> // Example: "text-4xl" makes the icon extra large
    //     ) : (
    //       <FaMicrophone className="text-white-500 text-2xl" />
    //     )}
    //   </button>

    //   {/*
    //   {audioUrl && !isRecording && (
    //     <div>
    //       <div className="mb-1 audio-wave-out">
    //         <audio src={audioUrl} controls className="w-100" />
    //         <CustomAudioPlayer audioSrc={audioUrl} />
    //       </div>
    //     </div>
    //   )} */}
    //   <TimeFeedCounterContainer>
    //     <div className="time-cards">
    //       <span className="time-font">
    //         {hours > 9 ? "" : <span>0</span>}
    //         {hours}:
    //       </span>
    //       <span className="time-font">
    //         {minutes > 9 ? "" : <span>0</span>}
    //         {minutes}:
    //       </span>
    //       <span className="time-font">
    //         {seconds > 9 ? "" : <span>0</span>}
    //         {seconds}
    //       </span>
    //     </div>
    //   </TimeFeedCounterContainer>
    //   {streamfailed ? (
    //     <button onClick={handleSubmitAudio(audioUrl)}>Submit</button>
    //   ) : (
    //     ""
    //   )}
    //   <MessageModel
    //     showModal={showModal}
    //     messageData={message}
    //     setShowModal={setShowModals}
    //   />
    //   <canvas ref={canvasRef} style={styles.canvas}></canvas>
    //   {showModalBtn ? (
    //     <RetryModal
    //       show={showModalBtn}
    //       onRetry={handleRetry}
    //       onClose={handleCloseModal}
    //       handleDownloads={handleDownload}
    //     />
    //   ) : (
    //     ""
    //   )}
    // </Feedrecorder>
  );
};
const styles = {
  canvas: {
    width: "80%",
    maxWidth: "800px",
    height: "50px",
    // border: "1px solid #ccc",
    borderRadius: "4px",
    margin: "0 auto",
    display: "block",
  },
};

export default AudioStreamer;
