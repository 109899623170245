import React, { useState, useEffect } from "react";
import { Spinner, Alert, Button, Modal, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { configJson } from "../../Constent/config";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { RiEdit2Fill } from "react-icons/ri";
import { PiPillDuotone } from "react-icons/pi";
import Medication from "./Medication";
import { useDispatch, useSelector } from "react-redux";
import { addtemplates, updateData } from "../../redux/slices/medicationTemplateSlice";

function MedicationTemplates() {
  const [medicationTemplates, setMedicationTemplates] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [decode, setDecode] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null); // Track selected template for editing
  const [medicationtableshow, setMedicationtableshow] = useState(false);
  const [tabledata, settabledata] = useState(null);
const dispatch=useDispatch();
const { templates,loading} = useSelector((state) => state.medicationTemplates);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  // const fetchMedicationTemplates = async (organization_id) => {
  //   try {
  //     setLoading(true);
  //     setError(null);
  //     const response = await fetch(
  //       `${configJson.backend_URL}/medicationtemplates/organization/${organization_id}`
  //     );
  //     if (!response.ok) {
  //       throw new Error("Failed to fetch medication templates");
  //     }
  //     const data = await response.json();
  //     setMedicationTemplates(data.data);
  //   } catch (error) {
  //     setError(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      setDecode(decoded);
      if (decoded.organization_id) {
        // fetchMedicationTemplates(decoded.organization_id);
      }
    } else {
      navigate("/login");
    }
  }, [navigate]);
  useEffect(()=>{
    setMedicationTemplates(templates);
  },[templates]);

  const handleEdit = (template) => {
    setEditMode(true);
    setSelectedTemplate(template); // Set the template to be edited
    setShowModal(true);
    reset({ template_name: template.template_name });
  };

  const onSubmit = async (data) => {
    try {
      const apiURL = editMode
        ? `${configJson.backend_URL}/medicationtemplates/temp/${selectedTemplate.template_id}`
        : `${configJson.backend_URL}/medicationtemplates/create`;

      const response = await fetch(apiURL, {
        method: editMode ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          organization_id: decode.organization_id,
        }),
      });

      if (!response.ok) {
        throw new Error(
          editMode
            ? "Failed to update medication template"
            : "Failed to create medication template"
        );
      }

      const newTemplate = await response.json();
      if (editMode) {
        // setMedicationTemplates((prevTemplates) =>
        //   prevTemplates.map((template) =>
        //     template.template_id === selectedTemplate.template_id
        //       ? newTemplate.data
        //       : template
        //   )
        // );
        dispatch(updateData(newTemplate.data))
      } else {
       // setMedicationTemplates([...medicationTemplates, newTemplate.data]);
        dispatch(addtemplates(newTemplate.data))
      }

      setShowModal(false);
      reset();
      setEditMode(false); // Reset edit mode after submission
    } catch (error) {
      setError(error.message);
    }
  };

  const handletable = (data) => {
    settabledata(data);
    setMedicationtableshow(true);
  };

  const handleClosetable=()=>{
    setMedicationtableshow(false);
  }
  if (loading) {
    return (
      <div className="container mt-4">
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mt-4">
        <Alert variant="danger">
          <strong>Error:</strong> {error}
        </Alert>
      </div>
    );
  }

  return (
    <>
      {medicationtableshow ? (
        <Medication tabledata={tabledata}  medicationtableshow={handleClosetable}/>
      ) : (
        <div className="">
          <Button
            variant="primary"
            onClick={() => {
              setEditMode(false);
              setShowModal(true);
              reset();
            }}
          >
            Add New Template
          </Button>

          {medicationTemplates.length === 0 ? (
            <p>No medication templates found.</p>
          ) : (
            <table className="table table-bordered table-striped mt-3">
              <thead>
                <tr>
                  <th>SNO</th>
                  <th>Template Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {medicationTemplates.map((template, index) => (
                  <tr key={template.template_id}>
                    <td>{index + 1}</td>
                    <td>{template.template_name}</td>
                    <td className="flex items-center justify-between ">
                      <RiEdit2Fill
                        size={25}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEdit(template)}
                      />

                      <PiPillDuotone
                        size={25}
                        onClick={() => handletable(template.template_id)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {editMode
                  ? "Edit Medication Template"
                  : "Add Medication Template"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group controlId="templateName">
                  <Form.Label>Template Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter template name"
                    {...register("template_name", {
                      required: "Template name is required",
                    })}
                    isInvalid={!!errors.template_name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.template_name?.message}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3">
                  {editMode ? "Update Template" : "Save Template"}
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
}

export default MedicationTemplates;
