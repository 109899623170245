import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Button, Spinner } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useDispatch } from "react-redux";
import "react-quill/dist/quill.snow.css";
import "./OutpatientDataSheet.css";
import { jwtDecode } from "jwt-decode";
import { configJson } from "../../../../Constent/config";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import axios from "axios";
import html2canvas from "html2canvas";
import ConfirmationModal from "./ConfirmationModal";
import { ReactSketchCanvas } from "react-sketch-canvas";
import DataSheetHeader from "./DatasheetHeader";
import DataSheetFooter from "./DataSheetFooter";
import "./DataSheetFooter.css";
import { updatePatientStatus } from "../../../../redux/slices/patientsSlice";
const getFormattedDateTimeUTC = () => {
  const now = new Date();
  return now.toISOString().replace(/[-:.TZ]/g, "");
};

const OutpatientDataSheet = ({
  doctorId,
  date,
  patientId,
  patientName,
  patientNamewithouspace,
  organizationCountry,
  patientAddress,
  gender,
  audioUrlInteractionId,
  dob,
  mobileNumber,
  fetchInteractions,
  answer,
  onAnswerChange,
  tokenNumber,
  drawingTemplateURL,
  summary,
  handleCloseResponse,
}) => {
  const [editableAnswer, setEditableAnswer] = useState("");
  const [editableSummary, setEditableSummary] = useState("");
  const [updatedSummary, setUpdatedSummary] = useState("");
  const [editableVitals, setEditableVitals] = useState([]);
  const [assessmentAnswer, setAssessmentAnswer] = useState("");
  const [saved, setSaved] = useState(false);
  const printRef = useRef();
  const [decode, setDecode] = useState({});
  const [medicineData, setMedicineData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  // const [medicineList, setMedicineList] = useState([]); // State for medicines in selected template
  // const [editablePrescription, setEditablePrescription] = useState("");
  const navigate = useNavigate();
  const [comments, setComments] = useState("");
  const [doctorDetails, setDoctorDetails] = useState(null);
  const [specialtyName, setSpecialtyName] = useState("");
  const [licenceNo, setLicenceNo] = useState("");
  const [showPopup, setShowPopup] = useState(false); // Popup visibility state
  const [showModal, setShowModal] = useState(false);
  const [showEditor, setShowEditor] = useState(true);
  const [prescriptionData, setPrescriptionData] = useState([]);
  const [templateData, setTemplateData] = useState([]);
  const [prescriptionDataTemplate, setPrescriptionDataTemplate] = useState([
    // Initial template data
  ]);
  const [tempTemplateData, setTempTemplateData] = useState([]);
  const [editableAssessment, setEditableAssessment] = useState("");
  const [assessmentList, setAssessmentList] = useState([]);
  const [editableDate, setEditableDate] = useState(date || ""); // Initialize with passed prop
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const canvasRef = useRef();
  const [isEraser, setIsEraser] = useState(false);
    const [showSignature, setShowSignature] = useState(false);
    const [signatureBase64, setSignatureBase64] = useState("");

  console.log("drawingTemplateURL", drawingTemplateURL);

  // Set default value as today's date in MM-DD-YYYY format
  useEffect(() => {
    const today = new Date();
    const formattedDate = formatDateForDisplay(today);
    setEditableDate(formattedDate);
  }, []);

  // Format the date from MM-DD-YYYY to YYYY-MM-DD for the input field
  const formatDateForInput = (date) => {
    const [month, day, year] = date.split("-");
    return `${year}-${month}-${day}`;
  };

  // Format the date from YYYY-MM-DD to MM-DD-YYYY for display
  const formatDateForDisplay = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };
  const handleClear = () => {
    canvasRef.current.clearCanvas();
  };
  const toggleEraser = () => {
    if (canvasRef.current) {
      if (isEraser) {
        canvasRef.current.eraseMode(false); // Turn off eraser mode
        setIsEraser(false);
      } else {
        canvasRef.current.eraseMode(true); // Turn on eraser mode
        setIsEraser(true);
      }
    }
  };
  // Handle date change
  const handleDateChange = (event) => {
    const selectedDate = event.target.value; // YYYY-MM-DD
    setEditableDate(formatDateForDisplay(new Date(selectedDate))); // Convert to MM-DD-YYYY
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = async () => {
    setShowModal(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) {
      return ""; // Return an empty string if dateString is invalid
    }
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
    const year = date.getFullYear();
    return `${month}-${day}-${year}`;
  };

  const fetchDoctorAndSpecialty = async (doctorId) => {
    try {
      const token = sessionStorage.getItem("orgtoken");
      const doctorResponse = await fetch(
        `${configJson.backend_URL}/doctor/doctorwithbase/${doctorId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (doctorResponse.ok) {
        const doctorInfo = await doctorResponse.json();
        console.log("docre",doctorInfo)
        // convertToBase64(doctorInfo?.doctor?.signature)


        setDoctorDetails(doctorInfo.doctor);
        console.log("doctinfo", doctorInfo.doctor);
        setLicenceNo(doctorInfo.doctor.license_no);

        const specialtyId = doctorInfo.doctor.speciality_id;

        // Fetch specialty details
        const specialtyResponse = await fetch(
          `${configJson.backend_URL}/specialties/${specialtyId}`
        );

        if (specialtyResponse.ok) {
          const specialtyData = await specialtyResponse.json();
          setSpecialtyName(
            specialtyData.specialty_name || "Specialty Not Found"
          );
        } else {
          setSpecialtyName("Specialty Not Found");
        }
      } else {
        console.error("Failed to fetch doctor details.");
      }
    } catch (error) {
      console.error("Error fetching doctor and specialty details:", error);
    }
  };

  useEffect(() => {
    // console.log(doctorId, "Docidget");
    if (doctorId) {
      fetchDoctorAndSpecialty(doctorId);
    }
  }, [doctorId]);

  const formattedDob = formatDate(dob);

  useEffect(() => {
    const token1 = sessionStorage.getItem("orgtoken");
    if (token1) {
      const decoded = jwtDecode(token1);
      setDecode(decoded);
      fetchTemplates(decoded.organization_id); // Correct function name here
      fetchAssessments(decoded.organization_id);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  const organization_id = decode.organization_id;
  const country = decode.country;
  const signatureUrl=doctorDetails?.signature_base64


  const fetchTemplates = async (organization_id) => {
    try {
      const response = await fetch(
        `${configJson.backend_URL}/medicationtemplates/organization/${organization_id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch medication templates");
      }
      const data = await response.json();
      setMedicineData(data.data); // Assuming `data.data` contains the template array
    } catch (err) {
      console.error("Error fetching medication templates:", err.message);
    }
  };

  const fetchAssessments = async (organization_id) => {
    try {
      console.log("Fetching assessments for organization ID:", organization_id);

      const response = await fetch(
        `${configJson.backend_URL}/assessment/list/${organization_id}`
      );

      console.log("Fetch response status:", response.status);

      if (!response.ok) {
        throw new Error("Failed to fetch assessments");
      }

      const data = await response.json();
      console.log("Fetched assessments data:", data);

      setAssessmentList(data.assessments || []); // Ensure it's always an array
      console.log("Updated assessment list:", data.assessments || []);
    } catch (err) {
      console.error("Error fetching assessments:", err.message);
    }
  };

  useEffect(() => {
    if (answer) {
      console.log("Updated ans", answer.updatedSummary);
      setUpdatedSummary(answer.updatedSummary); // Set updatedSummary
      console.log(editableVitals, "editableVitalsupdated");
      //setEditableAnswer(answer);

      setPrescriptionData(handleFormatPrescription(answer, showEditor));
      // console.log(prescriptionData, "prescripdata");

      // Validate and format answer for display
      const formattedAnswer = formatJsonAnswer(answer, showEditor);
      setEditableAnswer(formattedAnswer);
      const formattedVitals = handleVitals(answer, showEditor);
      setEditableVitals(formattedVitals);
    }
  }, [answer]);

  useEffect(() => {
    if (answer) {
      // Handle Assessment Formatting
      const formattedAssessments = formatAssessments(answer, showEditor);
      setEditableAssessment(formattedAssessments);
    }
    setEditableSummary(summary);
  }, [assessmentAnswer, summary]);

  useEffect(() => {}, [prescriptionData]); // Runs when 'prescriptionData' changes
  const handleCloseedit = async () => {
    // Ensure loading is set to true before closing the editor
    setLoading(true);

    try {
      // Your original logic to close the editor
      setShowEditor(false);
    } finally {
      // Delay for a minimum of 1 second
      setTimeout(() => {
        setLoading(false); // Set loading back to false after the delay
      }, 5000);
    }
  };

  const handleSave = async (selectedTemplate) => {
    setSaved(true);

    const updatedData = { comments, prescriptionData, templateData };
    // console.log("editvitals",editableVitals)
    // Declare updatedAssessment at the top of the function
    let updatedAssessment = "";

    if (onAnswerChange) {
      const updatedDiagnosis =
        editableAnswer === answer
          ? formatJsonAnswer(answer, showEditor)
          : editableAnswer;

      // Assign value to updatedAssessment
      updatedAssessment =
        editableAssessment === assessmentAnswer
          ? formatAssessments(assessmentAnswer, showEditor)
          : editableAssessment;

      const updatedSummary =
        editableSummary === summary
          ? summary // If unchanged, use the existing summary
          : editableSummary; // If edited, use the new value

      await onAnswerChange(
        updatedSummary,
        updatedDiagnosis,
        updatedData.prescriptionData,
        updatedAssessment
      );
    }
    setTimeout(() => {
      handlePrint(signatureUrl,showSignature); // Call handlePrint after 0.5 seconds (500ms)
    }, 200); // 500ms = 0.5 seconds

    
    setTimeout(() => {
      handleConvertPDF(); // Call handleConvertPDF after 0.5 seconds (500ms)
    }, 0); // 500ms = 0.5 seconds


    // // // // Handle Prescription Data Upload
    // if (!selectedTemplate && prescriptionData) {
    //   const fileContent = `
    //       ${JSON.stringify(prescriptionData, null, 2)}
    //     `;
    //   const fileName = `updatedprescription/${patientId}_${getFormattedDateTimeUTC()}.txt`;
    //   const success = await uploadFileToGCS(
    //     fileContent,
    //     fileName,
    //     patientId,
    //     doctorId,
    //     "Edited Prescription",
    //     audioUrlInteractionId
    //   );
    //   if (success) {
    //     setShowPopup(true);
    //     setTimeout(() => setShowPopup(false), 5000);
    //   }
    // }

    // // // Handle Template Data Upload
    // if (selectedTemplate && templateData) {
    //   const fileContent = `
    //       ${JSON.stringify(templateData, null, 2)}
    //     `;
    //   const fileName = `updatedtemplate/${patientId}_${getFormattedDateTimeUTC()}.txt`;
    //   const success = await uploadFileToGCS(
    //     fileContent,
    //     fileName,
    //     patientId,
    //     doctorId,
    //     "Edited Prescription",
    //     audioUrlInteractionId
    //   );
    //   if (success) {
    //     setShowPopup(true);
    //     setTimeout(() => setShowPopup(false), 5000);
    //   }
    // }

    // // Handle Updated Assessment Data Upload
    // if (updatedAssessment) {
    //   const fileContent = `
    //         ${JSON.stringify(updatedAssessment, null, 2)}
    //       `;
    //   const fileName = `updatedassessment/${patientId}_${getFormattedDateTimeUTC()}.txt`;
    //   const success = await uploadFileToGCS(
    //     fileContent,
    //     fileName,
    //     patientId,
    //     doctorId,
    //     "Edited Assessment",
    //     audioUrlInteractionId
    //   );
    //   if (success) {
    //     setShowPopup(true);
    //     setTimeout(() => setShowPopup(false), 5000);
    //   }
    // }
  };
  const handleSaveandConvert = async (selectedTemplate) => {
    setSaved(true);

    const updatedData = { comments, prescriptionData, templateData };
    // console.log("editvitals",editableVitals)
    // Declare updatedAssessment at the top of the function
    let updatedAssessment = "";

    if (onAnswerChange) {
      const updatedDiagnosis =
        editableAnswer === answer
          ? formatJsonAnswer(answer, showEditor)
          : editableAnswer;

      // Assign value to updatedAssessment
      updatedAssessment =
        editableAssessment === assessmentAnswer
          ? formatAssessments(assessmentAnswer, showEditor)
          : editableAssessment;

      const updatedSummary =
        editableSummary === summary
          ? summary // If unchanged, use the existing summary
          : editableSummary; // If edited, use the new value

      await onAnswerChange(
        updatedSummary,
        updatedDiagnosis,
        updatedData.prescriptionData,
        updatedAssessment
      );
    }

    setTimeout(() => {
      handleConvertPDF(); // Call handleConvertPDF after 0.5 seconds (500ms)
    }, 0); // 500ms = 0.5 seconds

    // // // // Handle Prescription Data Upload
    // if (!selectedTemplate && prescriptionData) {
    //   const fileContent = `
    //       ${JSON.stringify(prescriptionData, null, 2)}
    //     `;
    //   const fileName = `updatedprescription/${patientId}_${getFormattedDateTimeUTC()}.txt`;
    //   const success = await uploadFileToGCS(
    //     fileContent,
    //     fileName,
    //     patientId,
    //     doctorId,
    //     "Edited Prescription",
    //     audioUrlInteractionId
    //   );
    //   if (success) {
    //     setShowPopup(true);
    //     setTimeout(() => setShowPopup(false), 5000);
    //   }
    // }

    // // // Handle Template Data Upload
    // if (selectedTemplate && templateData) {
    //   const fileContent = `
    //       ${JSON.stringify(templateData, null, 2)}
    //     `;
    //   const fileName = `updatedtemplate/${patientId}_${getFormattedDateTimeUTC()}.txt`;
    //   const success = await uploadFileToGCS(
    //     fileContent,
    //     fileName,
    //     patientId,
    //     doctorId,
    //     "Edited Prescription",
    //     audioUrlInteractionId
    //   );
    //   if (success) {
    //     setShowPopup(true);
    //     setTimeout(() => setShowPopup(false), 5000);
    //   }
    // }

    // // Handle Updated Assessment Data Upload
    // if (updatedAssessment) {
    //   const fileContent = `
    //         ${JSON.stringify(updatedAssessment, null, 2)}
    //       `;
    //   const fileName = `updatedassessment/${patientId}_${getFormattedDateTimeUTC()}.txt`;
    //   const success = await uploadFileToGCS(
    //     fileContent,
    //     fileName,
    //     patientId,
    //     doctorId,
    //     "Edited Assessment",
    //     audioUrlInteractionId
    //   );
    //   if (success) {
    //     setShowPopup(true);
    //     setTimeout(() => setShowPopup(false), 5000);
    //   }
    // }
  };

  const uploadFileToGCS = async (
    fileContent,
    fileName,
    patientId,
    doctorId,
    interactionDetailType,
    audioUrlInteractionId
  ) => {
    try {
      console.log(audioUrlInteractionId, "audiointupload");
      console.log(fileName, "filenamesave");
      // Create a .txt file blob
      const blob = new Blob([fileContent], { type: "text/plain" });

      // Prepare the form data for the GCS upload
      const formData = new FormData();
      formData.append("patient_id", patientId);
      formData.append("doctor_id", parseFloat(doctorId));
      formData.append(
        "interaction_date",
        new Date().toISOString().slice(0, 19).replace("T", " ")
      );
      formData.append("interaction_id", audioUrlInteractionId);
      formData.append("interaction_type", "");
      formData.append("interaction_status", "");
      formData.append("interaction_notes", "");
      formData.append("interaction_detail_type", interactionDetailType);
      formData.append("file", blob, fileName);
      formData.append("file_name", fileName);

      // Upload the .txt file to GCS
      const result = await axios.post(
        `${configJson.backend_URL}/interaction/updateNewInteractionDetail/${audioUrlInteractionId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (result?.status) {
        console.log("File uploaded successfully:", result.data);
        return true; // Return success
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      return false; // Return failure
    }
  };
  // const convertToBase64 = (url) => {
  //   return new Promise((resolve, reject) => {
  //     console.log("Fetching image from URL:", url);
  
  //     fetch(url)
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error(`HTTP Error: ${response.status}`);
  //         }
  //         return response.blob();
  //       })
  //       .then((blob) => {
  //         const reader = new FileReader();
  
  //         reader.onloadend = () => {
  //           const base64 = reader.result;
  //           console.log("Converted Base64:", base64);
  //           localStorage.setItem("doctor_signature", base64);
  //           resolve(base64);
  //         };
  
  //         reader.onerror = () => reject(new Error("Error reading Blob as Base64"));
  
  //         reader.readAsDataURL(blob);
  //       })
  //       .catch((error) => {
  //         console.error("Base64 conversion error:", error);
  //         reject(error);
  //       });
  //   });
  // };

  
  
  
  
  // useEffect(() => {
  //   if (signatureUrl) {
  //     console.log("Converting signature URL:", signatureUrl);

  //     convertToBase64(signatureUrl)
  //       .then((base64) => {
  //         console.log("Converted Base64:", base64);
          
  //         // Save the Base64 image to localStorage
  //         const localStorageKey = "doctor_signature";
  //         localStorage.setItem(localStorageKey, base64);

  //         // Update state with the Base64 image
  //         setSignatureBase64(base64);
  //       })
  //       .catch((err) => console.error("Base64 conversion error:", err));
  //   }
  // }, [signatureUrl]);
    

  const handleSaveTemplate = async () => {
    // Temporarily store the updated data for printout
    setTempTemplateData(prescriptionDataTemplate);
    console.log("Data temporarily saved for printout:", tempTemplateData);
    // You can now use this temporary data for printout or any other purpose
  };

  const handleConvertPDF = async () => {
    setSaved(true); // Indicate that saving is in progress

    const elementsToHide = document.querySelectorAll(
      ".printDisable, .react-quill-container, .no-print"
    );
    elementsToHide.forEach((el) => {
      el.classList.add("hide-during-print");
    });
    // Set the interaction status to "0" (In Progress) before making the request
    const changeStatus_color = {
      patient_id: patientId,
      interaction_status: "0", // Status In Progress
    };
    dispatch(updatePatientStatus(changeStatus_color)); // Dispatch the status change to Redux

    setLoading(true);
    try {
      const style = document.createElement("style");
      style.textContent = `
        p.oupathead {
          padding-bottom: 10px !important;
        }
          .info-row.clearfixer.PatnamePad.row{
  padding-bottom: 10px !important;
}
  .docandsummary.row{
   padding-bottom: 10px !important;
  }
  .docandsummary.row{
  display:flex !important;
  }
  .outpatient-sheet.container{
    border-right: 1px solid #fff !important;
  }
.summaryquick.col{
padding-bottom:5px !important;
}
        .appointmentend.info-col.col::before{
          display:none !important;
          }
        .cstmpad {
          padding-bottom: 10px !important;
        }
        input.inputCommnts::placeholder {
          color: transparent !important;
        }
        .medicinetable th span {
          padding-bottom: 10px !important;
        }
        .medicinetable {
          border: 1px solid #4d4d4d00 !important;
        }
        .medicinetable td, .medicinetable th {
          border: 1px solid #4D4D4D !important;
          padding-bottom: 5px !important;
        }
        .medicinetable th {
          margin-bottom: 10px !important;
          padding-bottom: 20px !important;
        }
        .medicinetable th:first-child {
          margin-bottom: 10px !important;
          padding-bottom: 10px !important;
        }
      `;
      document.head.appendChild(style);

      const element = printRef.current; // Element to capture
      if (!element) return;

      // Capture the element as a canvas
      const canvas = await html2canvas(element, {
        scale: 1, // Use higher scale for better quality
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        unit: "mm",
        format: "a4",
        orientation: "p",
      });

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      let paddingTop = 12; // Top margin
      let paddingBottom = 23; // Bottom margin
      const availableHeight = pdfHeight - paddingTop - paddingBottom; // Usable space for content
      const imgWidth = pdfWidth - 20; // Add horizontal margin
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      if (window.innerWidth <= 850) {
        paddingTop += 2; // Increase top padding by 2px
        paddingBottom += 2; // Increase bottom padding by 2px
      }

      let totalPageCount = Math.ceil(
        canvas.height / (availableHeight * (canvas.width / imgWidth))
      );
      let position = 0;
      let pageCount = 1;

      const processPage = () => {
        if (position >= canvas.height) {
          // Save and upload the PDF
          const pdfBlob = pdf.output("blob");
          const fileName = `${patientNamewithouspace}_${getFormattedDateTimeUTC()}.pdf`;
      
          const formData = new FormData();
          formData.append("patient_id", patientId);
          formData.append("doctor_id", parseFloat(doctorId));
          formData.append(
            "interaction_date",
            new Date().toISOString().slice(0, 19).replace("T", " ")
          );
          formData.append("interaction_type", "");
          formData.append("interaction_status", "");
          formData.append("interaction_notes", "");
          formData.append("interaction_detail_type", "Medicine Template");
          formData.append("file", pdfBlob, fileName);
          formData.append("file_name", `files/${fileName}`);
      
          axios
            .post(`${configJson.backend_URL}/interaction/createImgwithoutingest`, formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((result) => {
              if (result?.status) {
                setShowPopup(true);
                setTimeout(() => setShowPopup(false), 5000); // Hide after 5s
      
                // Update interaction status in Redux
                const changeStatus_color = {
                  patient_id: patientId,
                  interaction_status: "3",
                };
                dispatch(updatePatientStatus(changeStatus_color));
      
                // Handle recording status
                const recordStatus = sessionStorage.getItem("isRecording");
                if (fetchInteractions && recordStatus === "false") {
                  fetchInteractions(patientId);
                }
                if (recordStatus === "true") {
                  dispatch(updatePatientStatus({ patient_id: patientId, interaction_status: "6" }));
                }
              } else {
                console.error("No response data received from the server.");
              }
      
              if (fetchInteractions && sessionStorage.getItem("isRecording") === "false") {
                fetchInteractions(patientId);
              }
            })
            .catch((error) => {
              console.error("Error generating or uploading PDF:", error);
              if (fetchInteractions && sessionStorage.getItem("isRecording") === "false") {
                fetchInteractions(patientId);
              }
            })
            .finally(() => {
              setSaved(false);
              setLoading(false);
              elementsToHide.forEach((el) => el.classList.remove("hide-during-print"));
              document.head.querySelectorAll("style").forEach((style) => {
                if (style.textContent.includes("p.oupathead")) {
                  document.head.removeChild(style);
                }
              });
            });
      
          return;
        }
      
        const sliceHeight = Math.min(availableHeight * (canvas.width / imgWidth), canvas.height - position);
      
        // Create a temporary canvas to extract the image slice
        const tempCanvas = document.createElement("canvas");
        tempCanvas.width = canvas.width;
        tempCanvas.height = sliceHeight;
        const tempCtx = tempCanvas.getContext("2d");
      
        // Draw the slice onto the temporary canvas
        tempCtx.drawImage(canvas, 0, -position, canvas.width, canvas.height);
        const sliceData = tempCanvas.toDataURL("image/png");
      
        // Ensure proper page handling
        if (pageCount > 1) pdf.addPage();
      
        let currentPaddingTop = pageCount === 1 ? paddingTop : 10; // Adjust top margin for subsequent pages
      
        pdf.addImage(
          sliceData,
          "PNG",
          10, // Left margin
          currentPaddingTop, // Dynamic Top margin
          imgWidth,
          (sliceHeight * imgWidth) / canvas.width
        );
      
        // Add page number correctly
        pdf.setFontSize(10);
        pdf.setTextColor(100);
        pdf.text(
          `Page ${pageCount}/${totalPageCount}`,
          pdfWidth / 2,
          pdfHeight - 10, // Bottom center
          { align: "center" }
        );
      
        // Move to the next slice
        position += sliceHeight;
        pageCount++;
      
        // Process the next slice asynchronously
        setTimeout(processPage, 0);
      };
      

      processPage(); // Start processing pages
    } catch (error) {
      console.error("Error generating or uploading PDF:", error);

      if (fetchInteractions) fetchInteractions(patientId); // Fetch interactions on error

      elementsToHide.forEach((el) => {
        el.classList.remove("hide-during-print");
      });

      setSaved(false);
    }
  };

  const calculateAge = (birthDate) => {
    console.log("brthdate", birthDate);
    if (!birthDate) return "Invalid Date"; // Handle missing or invalid date

    const [day, month, year] = birthDate.split("-"); // Assuming input is in DD-MM-YYYY format
    const parsedBirthDate = new Date(`${year}-${month}-${day}`); // Convert to YYYY-MM-DD format

    if (isNaN(parsedBirthDate)) return "Invalid Date"; // Handle invalid date

    const today = new Date();
    let age = today.getFullYear() - parsedBirthDate.getFullYear();
    const monthDiff = today.getMonth() - parsedBirthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < parsedBirthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const formatJsonAnswer = (rawAnswer, showEditor) => {
    // Check if rawAnswer is empty or not a string

    // If showEditor is false, format the raw answer as Markdown
    if (!showEditor) {
      return `<p>${rawAnswer["updatedDiagnosis"]}</p>`;
    }

    // For showEditor being true, you can directly return the formatted markdown if needed
    return formatMarkdownAnswer(rawAnswer);
  };

  // Helper function to format rawAnswer as Markdown
  const formatMarkdownAnswer = (rawAnswer) => {
    // Remove "### Outpatient Datasheet" from the input text
    rawAnswer = rawAnswer
      .replace(/Outpatient Datasheet/gi, "")
      .replace(/^#+\s*/gm, "") // Remove # symbols (Markdown headers) at the start of lines
      .trim();

    const formattedMarkdown = rawAnswer
      .split("\n") // Split the rawAnswer into lines
      .map((line) => {
        const trimmedLine = line.trim();

        // Handle lines with double stars ** at start or end for bolding
        if (/\*\*(.*?)\*\*/.test(trimmedLine)) {
          const withBold = trimmedLine.replace(
            /\*\*(.*?)\*\*/g,
            "<strong>$1</strong>"
          );
          return `<p style="font-size: 14px; margin-top: 5px;">${withBold}</p>`;
        }

        // Handle lines starting with "***" for bolding and remove extra stars
        if (trimmedLine.startsWith("***")) {
          const boldText = trimmedLine
            .replace(/^\*\*\*(.*?)\*\*\*/, "<strong>$1</strong>") // Add bold formatting
            .replace(/^\*\s*/, ""); // Remove extra leading star after bold
          return `<p style="font-size: 14px; margin-top: 5px;">${boldText}</p>`;
        }
        if (trimmedLine.startsWith("*")) {
          const withoutLeadingAsterisk = trimmedLine.replace(/^\*\s*/, ""); // Remove leading "*"
          return `<p style="font-size: 14px; margin-top: 5px; margin-left: 20px;">${withoutLeadingAsterisk}</p>`;
        }

        // Handle bullet points (lines starting with "*")
        if (trimmedLine.startsWith("*")) {
          const withBold = trimmedLine
            .slice(1)
            .trim()
            .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>"); // Convert **bold** to <strong>
          return `<li style="font-size: 14px; margin-left: 20px;">${withBold}</li>`;
        }

        // Handle general text (wrap in <p> tags)
        if (trimmedLine) {
          return `<p style="font-size: 14px; margin-top: 5px;">${trimmedLine}</p>`;
        }

        return ""; // Skip empty lines
      })
      .join(""); // Join the formatted lines into a single string

    // Wrap the formatted output in a <div> tag
    return `<div style="font-family: Arial, sans-serif; font-size: 14px; line-height: 1.6;">${formattedMarkdown}</div>`;
  };

  const handleVitals = (answer, showEditor) => {
    try {
      const parsedAnswer = JSON.parse(answer.trim());
      console.log("Parsed Answer:", parsedAnswer); // Log the parsed answer to verify the input data

      const vitals = parsedAnswer["Vitals"] || [];
      console.log("Vitals Raw Data:", vitals); // Log the raw vitals data

      const formattedVitals = vitals.map((vital, index) => ({
        id: index,
        pulse: vital.pulse,
        height: vital.height,
        weight: vital.weight,
        systolicBP: vital.systolicBP,
        diastolicBP: vital.diastolicBP,
        position: vital.position,
        oxygenSaturation: vital.oxygenSaturation,
      }));

      console.log("Formatted Vitals:", formattedVitals); // Log the formatted vitals data

      return formattedVitals;
    } catch (error) {
      console.error("Error processing vitals:", error);
      return [];
    }
  };

  const handleFormatPrescription = (rawAnswer, showEditor) => {
    // console.log(rawAnswer, "rawans");
    // console.log(showEditor, "showedirprec");
    if (!rawAnswer) return [];

    try {
      // Declare prescriptions array once
      const prescriptions = [];
      // Check if showEditor is false, format the data as required
      if (!showEditor) {
        const answerObject = JSON.parse(rawAnswer.jsonData);
        // console.log(answerObject, "rawansform");
        // Map through the array to format the medication data
        answerObject.forEach((item, index) => {
          const medicationTemplate = item["medication_template"];

          if (
            medicationTemplate &&
            Object.keys(medicationTemplate).length > 0
          ) {
            prescriptions.push({
              id: index + 1, // Unique ID for each prescription
              medication_name: medicationTemplate.medication_name || "",
              dosage: medicationTemplate.dosage || "",
              frequency: {
                morning: medicationTemplate.frequency_morning || "",
                afternoon: medicationTemplate.frequency_afternoon || "",
                night: medicationTemplate.frequency_night || "",
              },
              duration: medicationTemplate.duration || "",
              instructions: medicationTemplate.instructions || "",
            });
          }
        });

        // console.log("Formatted prescriptions after update", prescriptions);
        return prescriptions; // Return formatted prescriptions
      }
      // console.log("eeee");
      const answerObject = JSON.parse(rawAnswer.trim());
      // console.log("ansobj", answerObject);
      // Original logic for "showEditor: true"
      const medicationTemplate = answerObject["medication_template"];
      const clinicalDiagnosis = answerObject["Clinical Diagnosis"];
      const { Prescription } = clinicalDiagnosis || {};

      // Check if medication_template exists and has content
      if (Array.isArray(medicationTemplate) && medicationTemplate.length > 0) {
        medicationTemplate.forEach((medication, index) => {
          prescriptions.push({
            id: index + 1,
            medication_name: medication.medication_name || "",
            dosage: medication.dosage || "",
            frequency: {
              morning: medication.frequency_morning || "",
              afternoon: medication.frequency_afternoon || "",
              night: medication.frequency_night || "",
            },
            duration: medication.duration || "",
            instructions: medication.instructions || "",
          });
        });
      }
      // If medication_template is empty, fall back to Clinical Diagnosis Prescription
      else if (Prescription && Object.keys(Prescription).length > 0) {
        prescriptions.push({
          id: 2,
          medication_name: Prescription.Medication || "",
          dosage: Prescription.Dosage || "",
          frequency: {
            morning: Prescription.Morning || "",
            afternoon: Prescription.Afternoon || "",
            night: Prescription.Night || "",
          },
          duration: "", // Clinical Diagnosis Prescription doesn't have duration
          instructions: Prescription.Instructions || "",
        });
      }

      return prescriptions; // Return the formatted data for the editor
    } catch (error) {
      console.error("Error parsing prescription data:", error);
      return [];
    }
  };

  const formatAssessments = (rawAnswer, showEditor) => {
    if (!rawAnswer) return "";

    if (!showEditor) {
      return `<p>${rawAnswer["updatedAssessment"]}</p>`;
    }

    try {
      const assessments = [];
      const answerObject = JSON.parse(rawAnswer.trim());
      const assessmentData = answerObject["assessment"];

      // Safeguard for empty or undefined assessment array
      if (Array.isArray(assessmentData) && assessmentData.length > 0) {
        assessmentData.forEach((assessment) => {
          const templateName =
            assessment.assessment_template || "Unknown Template";
          const tests = assessment.template_tests
            ? assessment.template_tests.split(",").map((test) => test.trim())
            : ["No tests specified"];

          assessments.push({
            templateName,
            tests,
          });
        });
      }

      return `
      <div class="assessment-container">
  ${assessments
    .map(
      (assessment) => `

    
       <strong>${assessment.templateName}</strong>
  <br>
    
          <ul class="assessment-tests">
            ${assessment.tests
              .map((test) => `<li class="test-item">${test}</li>`)
              .join("")}
          </ul>
  
      </div>`
    )
    .join("")}
</div>

      `;
    } catch (error) {
      console.error("Error parsing assessment data:", error);
      return "<p>Error displaying assessments.</p>";
    }
  };

  const handlePrint = async (signatureUrl,showSignature) => {
    setShowEditor(false);

    const content = printRef.current.innerHTML;
    const printFrame = document.createElement("iframe");
    printFrame.style.position = "absolute";
    printFrame.style.width = "0";
    printFrame.style.height = "0";
    printFrame.style.border = "none";
    document.body.appendChild(printFrame);

    const doc = printFrame.contentWindow.document;
    doc.open();
    doc.write(`
      <!DOCTYPE html>
      <html>
        <head>
      <title>${document.title}</title>
          <style>
         @page {
  size: A4;
  margin: 0.6in; /* Reduced margin for more content space */
}
  .outpatient-sheet .notescolumn.col p {

  line-height: 20px !important;
}
 .notescolumn{

 padding-left:5px !important;
 }
.notesUs {
    display: flex !important;
    justify-content: space-between !important;
}
.summaryquick.col-md-5.col,.col-md-7.notescolumn.col {
    width: 50% !important;
}
   
    .summlft{
    width:100% !important;
    }

@page {
  @bottom-right {
    content: "Page " counter(page) " of " counter(pages);
  }
}

body {
  margin: 0; /* Remove default body margin to align content properly */
}
.lftdoctside{
width:42% !important;
}
.flexprint{
 display: flex !important;
    flex-wrap: nowrap !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.ql-editor h3{
padding:0px !important;
margin:0px !important;
}
  .medinamesaved{
  width:100% !important;
  }
  p.addrCls{
font-size:12px !important;
  }
.doctorSize{

}
.PatnamePad{
padding:0px 7px !important;
}
.rghttnmcreg{
padding-left:0px !important;
padding-right:0px !important;
}
  .printlastnonecol{
  width:60% !important;
  }
  .daysprt{
  width:15% !important;
  }
  span.medinamesaved {
    width: 80% !important;
}
    .dosagewdt{
        width: 30% !important;
    }
    .info-row, .section-label, .section-address {
    font-family: 'Calibri', sans-serif !important;
    font-size: 16px !important;
  }
   .outpatient-sheet .DetailContainer .info-row  p.section-value{
   font-size: 24px !important;
   }

   .cstmapponttble{
    padding-bottom:10px !important;
   }
   
table.cstmapponttble td.frstrow{
    width: 100%;
    font-size:14px !important;
    padding:0px !important;
     margin:0px !important;
}
 .appointmentend info-col col{
   float: right;
    width: 50%;
 }
    .cstmapponttble td{
    font-size:15px !important;
    }
.info-row .info-col {
    float: left;
    width: 50%;
  }

  .info-row .appointmentend {
    float: right;
    width: 45%; /* Ensures right column has enough space */
    border-left: 2px solid rgba(0, 0, 0, 0.5);
    padding-left: 10px !important;
    margint-top:-5px !important;
  }
  .section-label, .section-colon, .section-value {
    padding: 5px;
  }
body {
  font-family: Calibri, sans-serif !important;
}
  .info-col.col p {
  font-size: 16px !important;
}
  .info-col{
  padding:0px !important;
  margin:0px !important;
  }
  .section-address{
  padding-left:5px !important;
  }
  .info-row.clearfixer.row {
    margin-left: -5px;
}

  .section-value{
  font-size:24px !important;
  }
  .cstmapponttble td{
  width:40% !important
}
  .headerPart{
  padding:0px !important;
    margin:0px !important;
  }
    .headercenter p.oupathead{
 
    font-size:20px !important;
    }
.headerlft img{
   width: "auto !important", // Set the exact width
height:auto !important;
}

.outpatient-sheet.container strong {
  padding: 0px !important;
  margin: 0px !important;
  line-height: 10px !important;
}


.outpatient-sheet.container img {
  text-align: left !important;
  margin: 0px !important;
  padding: 0px !important;
  height: auto !important;
}

input.inputCommnts::placeholder {
  color: transparent !important; /* Hides the placeholder text */
}
table.medicinetable.table.table-bordered {
    border-left: 2px solid #e5e7eb !important;
        border-right:  2px solid #e5e7eb !important;
         border-top: 2px solid #e5e7eb !important;
}
    table.medicinetable.table.table-bordered th,table.medicinetable.table.table-bordered td{
          border-bottom: 2px solid #e5e7eb !important;
           border-right: 2px solid #e5e7eb !important;
    }
            table.medicinetable.table.table-bordered th.printlastnonecol,table.medicinetable.table.table-bordered td.printlastnonecol{
           border-right: 2px solid #5b5b5b00 !important;
    }
          
      table.medicinetable.table.table-bordered th.printDisable{
      width:0% !important;
      }
hr {
  border: 1px solid #000 !important; /* Light gray border */
  margin: 0;
  opacity: 0.5;
}

.appointmentend.info-col.col {
padding-left:50px;
}
.info-col.cstmpad.col{
padding-left:15px;
}
  .appointmentend.info-col.col::before {
    content: "";
    background: black;
    position: absolute;
    left: 0px;
    height: 140px;
    width: 2px;
    opacity: 0.5;
    top: 0px;
}
  
.DetailContainer .info-row{
padding-top:10px !important;
} 
.DetailContainer strong{
font-weight:600 !important;
}
.DetailContainer .info-row .bigfrontBoldtemp{
font-size: 24px !important;
 padding-top: 0px !important;
  margin-top: 0px !important;
  padding-bottom: 0px !important;
    margin-bottom: 7px !important;
}
.info-col {
  width: 40%;
  float:left;
}
  .clearfixer::after {
    content: "";
    display: table;
    clear: both;
}
.outpatient-sheet p {
  margin-bottom: 8px;
}
.DetailContainer {
  padding-left: 0px;
  border-top: 2px solid;
  padding-top: 0px !important;
  margin-top: 0px !important;
}
.DetailContainer p {
  padding-top: 0px !important;
  margin-top: 0px !important;
  padding-bottom: 0px !important;
      margin-bottom: 5px !important;
      font-family: Calibri, sans-serif !important;
      font-size:16px !important;

}
      
.DetailContainer .bigfrontBoldtemp{
font-size: 24px;
 padding-top: 0px !important;
  margin-top: 0px !important;
  padding-bottom: 0px !important;
    margin-bottom: 7px !important;
}

hr {
  border: "1px solid black";
  margin: "0";
}

.page-break {
  page-break-before: always;
}

.printDisable {
  display: none;
}
.react-quill-container {
  display: none;
}
.no-print {
  display: none;
}
   .signatureContainer {
   padding-top:20px;
    width: 100%;
  }
      .signaturePosition {
    float: right;
    max-width: 300px;
    width: 100%;

  }
    .signatureImage {
  height: auto;
  display: block;
  margin: 0 auto;
  max-width: 150px;
  margin-top: 20px;
    margin-right:20px;
}
    .signatureTitle {
    text-align: right;
}
    
 .content {
            padding: 0.6in;
            min-height: calc(100vh - 120px); /* Leaves space for the footer */
            position: relative; /* Ensures content doesn't overlap */
          }

          /* Add extra space at the bottom to prevent overlap */
          .content::after {
            content: "";
            display: block;
            height: 100px; /* Reserve space for footer */
          }

          .footer {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100px; /* Adjust height as needed */
            text-align: center;
            border-top: 1px solid #ccc;
            padding-top: 10px;
            font-size: 14px;
            background: white; /* Ensure it doesn't overlap content */
          }

          .footer img {
            max-width: 100px;
            height: auto;
            display: block;
            margin: 0 auto;
          }

          /* Ensure footer only appears on the last page */
          @media print {
            .footer {
              position: relative;
              page-break-after: always;
            }
          }
          </style>
        </head>
        <body onload="window.print(); window.close();">
          ${content}
       
        </body>
      </html>
    `);
    doc.close();
  };

  return (
    <Container
      ref={printRef}
      className="outpatient-sheet"
      style={{
        backgroundColor: "#fff",
        border: "none !important",
        fontFamily: "Calibri, sans-serif",
        fontSize: "10pt",
        boxSizing: "border-box",
        width: "100%",
        maxWidth: "794px",
        margin: "0 auto",
        paddingBottom: "30px",
        paddingTop: "30px",
        position:"relative"
      }}
    >
      {/* Add the popup message */}
      {showPopup && (
        <div
          style={{
            position: "fixed",
            bottom: "20px",
            right: "20px",
            backgroundColor: "#4caf50",
            color: "white",
            padding: "10px 20px",
            borderRadius: "5px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          }}
        >
          Saved Template Updated in Feed
        </div>
      )}

      <div>
        <DataSheetHeader organization_id={organization_id} />
      </div>
      <div className="DetailContainer">
        {/* <h2
              style={{
                margin: "0",
                fontSize: "34px",
                fontWeight: "700",
                color: "#000",
                marginTop: "70px"
              }}
            >
            OUTPATIENT DATA SHEET
            </h2> */}
        <Row
          className="info-row flexprint"
          style={{ paddingBottom: "6px", paddingTop: "6px" }}
        >
          <Col sm={8}>{/* Content goes here */}</Col>
          <Col sm={4}>
            <p style={{ fontSize: "15px", paddingBottom: "0px" }}>
              Date:{" "}
              {showEditor ? (
                <input
                  type="date"
                  value={editableDate ? formatDateForInput(editableDate) : ""}
                  onChange={handleDateChange}
                  style={{
                    fontSize: "15px",
                    padding: "5px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                />
              ) : (
                <span style={{ cursor: "pointer" }}>
                  <strong>{editableDate || "MM-DD-YYYY"}</strong>
                </span>
              )}
            </p>
            {/* <p
              className="bigfrontBoldtemp"
              style={{ fontSize: "24px", paddingBottom: "5px" }}
            >
              
            </p>
            <p
              style={{ fontSize: "15px", paddingBottom: "5px" }}
              className="pt-1"
            >
           
            </p> */}
          </Col>
        </Row>
        <hr
          style={{
            border: "1px solid black",
            margin: "0",
            marginBottom: "0px",
            marginTop: "0px",
            opacity: "0.5",
          }}
        />
        <Row className="info-row clearfixer PatnamePad">
          <Col className="info-col cstmpad" style={{ paddingLeft: "11px" }}>
          <p  style={{ fontSize: "14px" }} className="bigfrontBoldtemp">
              Patient Name
            </p>
            <p className="bigfrontBoldtemp" style={{ fontSize: "24px" }}>
              <strong>{patientName || ""}</strong>
            </p>
            {/* {patientAddress && (
              <p style={{ fontSize: "15px", paddingTop: "5px" }}>
                {patientAddress}
              </p>
            )} */}
          </Col>
          <Col
            className="appointmentend info-col"
            style={{ position: "relative", paddingBottom: "10px" }}
          >
            <table
              className="cstmapponttble"
              style={{ width: "100%", border: "none" }}
            >
              <tbody>
                {tokenNumber && (
                  <tr>
                    <td
                      style={{
                        fontSize: "15px",
                        width: "100%",
                        textAlign: "left",
                        paddingRight: "10px",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      Appointment No
                    </td>
                    <td
                      style={{
                        fontSize: "15px",
                        width: "5%",
                        textAlign: "center",
                        paddingRight: "10px",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      :
                    </td>
                    <td
                      style={{
                        fontSize: "15px",
                        width: "65%",
                        textAlign: "left",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      <strong>{tokenNumber}</strong>
                    </td>
                  </tr>
                )}
                {country !== "United States of America" && formattedDob && (
                  <tr>
                    <td
                      className="agedet"
                      style={{
                        fontSize: "15px",
                        width: "100%",
                        textAlign: "left",
                        paddingRight: "10px",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      Age / Gender
                    </td>
                    <td
                      style={{
                        fontSize: "15px",
                        width: "5%",
                        textAlign: "center",
                        paddingRight: "10px",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      :
                    </td>
                    <td
                      className="agedet"
                      style={{
                        fontSize: "15px",
                        width: "65%",
                        textAlign: "left",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      <strong>
                        {`${calculateAge(formattedDob)} Y`}
                        {dob && gender ? " / " : ""}
                        {gender}
                      </strong>
                    </td>
                  </tr>
                )}
                {formattedDob && (
                  <tr>
                    <td
                      className="agedet"
                      style={{
                        fontSize: "15px",
                        width: "100%",
                        textAlign: "left",
                        paddingRight: "10px",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      Date of Birth
                    </td>
                    <td
                      style={{
                        fontSize: "15px",
                        width: "5%",
                        textAlign: "center",
                        paddingRight: "10px",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      :
                    </td>
                    <td
                      className="agedet"
                      style={{
                        fontSize: "15px",
                        width: "65%",
                        textAlign: "left",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      <strong>{formattedDob}</strong>
                    </td>
                  </tr>
                )}
                {mobileNumber && (
                  <tr>
                    <td
                      className="mobnmbr agedet"
                      style={{
                        fontSize: "15px",
                        width: "100%",
                        textAlign: "left",
                        paddingRight: "10px",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      Mobile Number
                    </td>
                    <td
                      style={{
                        fontSize: "15px",
                        width: "5%",
                        textAlign: "center",
                        paddingRight: "10px",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      :
                    </td>
                    <td
                      className="agedet"
                      style={{
                        fontSize: "15px",
                        width: "65%",
                        textAlign: "left",
                        paddingBottom: "0px",
                        border: "none",
                        paddingTop: "0px",
                      }}
                    >
                      <strong>
                        {country === "United States of America"
                          ? mobileNumber
                          : `+${mobileNumber}`}
                      </strong>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Col>
        </Row>
        <hr
          style={{
            border: "1px solid black",
            margin: "0",
            marginBottom: "0px",
            marginTop: "0px",
            opacity: "0.5",
          }}
        />
        <Row className="docandsummary">
          <Col className="summlft">
            <table
              className="docformat"
              style={{ width: "100%", border: "none", paddingTop: "5px" }}
            >
              <tbody>
                <tr className="doctUs">
                  <td
                    className="doctorSizeUs"
                    style={{
                      fontSize: "15px",
                      width: "15%",
                      textAlign: "left",
                      paddingRight: "10px",
                      paddingBottom: "0px",
                      border: "none",
                      paddingTop: "10px",
                      paddingLeft: "0px",
                    }}
                  >
                    Primary Doctor
                  </td>
                  <td
                    style={{
                      fontSize: "15px",
                      width: "5%",
                      textAlign: "center",
                      paddingRight: "10px",
                      paddingBottom: "0px",
                      border: "none",
                      paddingTop: "0px",
                      paddingLeft: "0px",
                    }}
                    className="doctcolon"
                  >
                    :
                  </td>
                  <td
                    className="Usdoctorsal"
                    style={{
                      fontSize: "15px",
                      width: "80%",
                      textAlign: "left",
                      paddingBottom: "0px",
                      border: "none",
                      paddingTop: "0px",
                      paddingLeft: "0px",
                    }}
                  >
                    {doctorDetails?.salutation || ""}{" "}
                    {doctorDetails?.first_name} {doctorDetails?.last_name}
                    <br></br> ({specialtyName || ""})
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
          {/* <Col className="summaryquick col-md-4">
    <h5
      style={{
        fontSize: "18px",
        marginBottom: "10px",
        textAlign: "left",
        fontWeight: "bold",
        paddingTop:"5px"
      }}
    >
      Summary
    </h5>
    <p
  style={{
    fontSize: "14px",
    margin: "0",
    textAlign: "left",
    lineHeight: "20px",
        marginBottom:"3px"
  }}
>
  {summary ? (
    summary.split('\n').map((line, index) => (
      <p key={index} style={{
        fontSize: "14px",
        textAlign: "left",
        lineHeight: "20px",
        marginBottom:"3px"
         }}>
        {line}
      </p>
    ))
  ) : (
    answer.summary.split('\n').map((line, index) => (
      <p key={index} style={{ marginBottom: "5px",
        marginBottom: "5px",
        fontSize: "14px",
        lineHeight: "20px"}}>
        {line}
      </p>
    ))
  )}
</p>


  </Col> */}
        </Row>

        <hr
          style={{
            border: "1px solid black",
            margin: "0",
            marginBottom: "5px",
            marginTop: "0px",
            opacity: "0.5",
          }}
        />
        <Row className="notesUs">
          <Col className="summaryquick col-md-3">
            <p
              style={{
                fontSize: "14px",
                width: "100%",
                textAlign: "left",
                paddingRight: "10px",
                paddingBottom: "0px",
                border: "none",
                paddingTop: "0px",
                paddingLeft: "0px",
              }}
            >
              Summary:
            </p>
            <p
              style={{
                fontSize: "14px",
                margin: "0",
                textAlign: "left",
                lineHeight: "20px",
                marginBottom: "3px",
              }}
            >
              {showEditor ? (
                <div className="react-quill-container">
                  <ReactQuill
                    value={
                      (editableSummary || "") // Ensure `editableSummary` is a string
                        .replace(/\n/g, "<br />") // Replace \n with <br />
                        .replace(
                          /(^|[\.\n•]\s*)([a-z])/g,
                          (match, prefix, char) =>
                            `${prefix}${char.toUpperCase()}`
                        ) // Capitalize first letter
                    }
                    onChange={(value) => setEditableSummary(value)} // Update the edited content
                    theme="snow"
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        [{ header: [1, 2, 3, false] }],
                        ["link", "image"],
                      ],
                    }}
                    formats={[
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "list",
                      "bullet",
                      "header",
                      "link",
                      "image",
                    ]}
                  />
                </div>
              ) : !showEditor ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: updatedSummary,
                  }}
                />
              ) : null}
            </p>
            {/* Sketch Canvas Below the Editor */}
            <div style={{ marginTop: "10px" }}>
            {drawingTemplateURL && drawingTemplateURL.length > 0 ? (
  <>
    <p>Summary Diagram:</p>
    <img
      src={drawingTemplateURL[0] || null}
      alt="Drawing Template"
      style={{
        height: "auto",
        maxWidth: "170px",
        marginTop: "20px",
      }}
    />
  </>
) : (
  <p></p>
)}
</div>
          </Col>
          <Col className="col-md-9 notescolumn">
            <p
              style={{
                fontSize: "15px",
                width: "30%",
                textAlign: "left",
                paddingRight: "10px",
                paddingBottom: "5px",
                border: "none",
                paddingTop: "2px",
                paddingLeft: "0px",
              }}
            >
              Notes:
            </p>

            {/* Conditionally Display Formatted Content */}
            {!showEditor && (
              <div
                style={{ fontSize: "15px", width: "100%", textAlign: "left" }}
                className="diagnosis-content mb-2"
                dangerouslySetInnerHTML={{
                  __html: formatJsonAnswer(answer, showEditor),
                }}
              ></div>
            )}

            {/* Conditionally Display Editable Quill Editor */}
            {showEditor && (
              <div className="react-quill-container">
                <ReactQuill
                  value={editableAnswer} // Use the editable answer directly
                  onChange={(value) => setEditableAnswer(value)} // Update the edited content
                  theme="snow"
                  style={{ minHeight: "120px" }}
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ header: [1, 2, 3, false] }],
                      ["link", "image"],
                    ],
                  }}
                  formats={[
                    "bold",
                    "italic",
                    "underline",
                    "strike",
                    "list",
                    "bullet",
                    "header",
                    "link",
                    "image",
                  ]}
                />
              </div>
            )}
          </Col>
        </Row>

        <DataSheetFooter 
        organizationCountry={organizationCountry}
  showSignature={showSignature} 
  setShowSignature={setShowSignature}  
  signatureUrl={doctorDetails?.signature_base64} 
  signatureBase64={signatureBase64}  // Pass the base64 data
  organization_id={organization_id} 
/>

        <div className="text-end mt-2 no-print flex items-center justify-end gap-1">
        <button
          style={{
        marginRight: "20px",
        background: ` #06AEB6`, // Ensure the correct path
        paddingLeft: "10px",
        color: "#fff", // Ensure text visibility
        display: "inline-block", // Ensures proper spacing and layout
        borderRadius: "4px", // Optional for rounded corners
        padding: "6px 15px"
      }}
            className="toggleSignatureButton"
            onClick={() => setShowSignature(!showSignature)}
          >
            <span>{showSignature ? "Remove Signature" : "Add Signature"}</span>
          </button>
          <button style={{ display: "flex", alignItems: "center", gap: "0px" }}
            className="bg-[#06AEB6] p-2 rounded-full"
            onClick={handleCloseResponse}
          >
            <img src={`/trash.png`} width={18} height={18} />
            {/* <span
      style={{
        marginRight: "20px",
        background: ` #06AEB6`, // Ensure the correct path
        paddingLeft: "10px",
        color: "#fff", // Ensure text visibility
        display: "inline-block", // Ensures proper spacing and layout
        borderRadius: "4px", // Optional for rounded corners
      }}
    >
      Delete
    </span> */}
          </button>
          <button
            className="bg-[#06AEB6] p-2 rounded-full"
            onClick={async () => {
              await handleCloseedit(); // Ensure this completes first
              await handleSaveandConvert(selectedTemplate); // Save changes first
            }}
          >
           {loading ? (
  <Spinner animation="border" size="sm" />
) : (
  <div style={{ display: "flex", alignItems: "center", gap: "0px" }}>
    <img src={`/save.png`} width={18} height={18} alt="Save Icon" />
    {/* <span
      style={{
        marginRight: "20px",
        background: ` #06AEB6`, // Ensure the correct path
        paddingLeft: "10px",
        color: "#fff", // Ensure text visibility
        display: "inline-block", // Ensures proper spacing and layout
        borderRadius: "4px", // Optional for rounded corners
      }}
    >
      Save
    </span> */}
  </div>
)}
          </button>
          <button
            className="bg-[#06AEB6] p-2 d-flex rounded-full" 
            style={{ display: "flex", alignItems: "center", gap: "0px" }}
            onClick={async () => {
              await handleCloseedit();
              await handleSave(selectedTemplate); // Save changes first
              // handleConvertPDF();
            }}
          >
            <img src={`/printer.png`} width={18} height={18} />
            {/* <span
      style={{
        marginRight: "20px",
        background: ` #06AEB6`, // Ensure the correct path
        paddingLeft: "10px",
        color: "#fff", // Ensure text visibility
        display: "inline-block", // Ensures proper spacing and layout
        borderRadius: "4px", // Optional for rounded corners
      }}
    >
      Print
    </span> */}
          </button>
          {/* <button
            className="bg-[#06AEB6] p-2 d-flex"
            style={{ display: "flex", alignItems: "center", gap: "0px" }}
            onClick={async () => {
              handlePrint(signatureUrl,showSignature); // Call handlePrint after 0.5 seconds (500ms)
            }}
          >
           Print test
       
          </button> */}
          
          {/* {saved && (
            <span style={{ color: "green", marginLeft: "10px" }}>
              Changes saved!
            </span>
          )} */}

          <ConfirmationModal
            loading={loading}
            show={showModal}
            onHide={handleCloseModal}
            onConfirm={() => {
              // Define an async function for the sequence
              const handleConfirmSequence = async () => {
                await handleCloseedit(); // Ensure this completes first
                handleCloseModal(); // Close the modal
                await handleSaveandConvert(selectedTemplate); // Save changes first

                // handleConvertPDF();
                // handlePrint(); // Then trigger the print
              };

              // Call the async function
              handleConfirmSequence();
            }}
          />
        </div>
      </div>
    </Container>
  );
};

export default OutpatientDataSheet;
