import React, { useState } from "react";
import { configJson } from "../../../../Constent/config";
import "./DataSheetFooter.css";

const DataSheetFooter = ({ organization_id, signatureUrl, showSignature, setShowSignature,organizationCountry }) => {
  // Handle showing popup for missing signature
  const [showPopup, setShowPopup] = useState(false);

  // Check for organization ID and render accordingly
  if (organization_id === configJson.ABC) {
    return (
      <div
        className="data-sheet-footer"
        style={{
          backgroundColor: "#ffffff",
          fontFamily: "Arial, sans-serif",
          borderTop: "1px solid #ccc",
          lineHeight: 1.5,
          paddingTop: "20px",
        }}
      >
        {/* Footer Title Section */}
        <div
          className="footer-title container"
          style={{
            fontWeight: "bold",
            fontSize: "10px",
            color: "#d11a1a",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          <div className="row align-items-center">
            <div className="col-md-12">
              <span>*24 Hours LAB & PHARMACY Digital X-Ray / ECG</span>
            </div>
          </div>
          <div style={{ margin: "5px", textAlign: "left" }}>
            <div className="col-md-12" style={{ textAlign: "center" }}>
              <p
                style={{
                  fontWeight: "bold",
                  color: "#d11a1a",
                  fontSize: "10px",
                }}
              >
                OTTIYAMBAKKAM
              </p>
              <span className="contactphone">
                Contact: 9884653905 / 044-22770109
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (organizationCountry=== "United States of America") {
    console.log(organizationCountry,"footercountry")
    return (
      <div className="signatureContainer">
        {/* Signature Section (Only visible when showSignature is true) */}
        {showSignature && (
          <div className="signaturePosition">
            {signatureUrl ? (
              <>
                <img
                  src={signatureUrl}
                  alt="Doctor's Signature"
                  className="signatureImage"
                />
                <h3 className="signatureTitle">Doctor's Signature</h3>
              </>
            ) : (
              <p
                className="noSignatureText"
                onClick={() => setShowPopup(true)} // Trigger popup when no signature
              >
                No signature available. Add it in settings.
              </p>
            )}
          </div>
        )}
      </div>
    );
  }

  return null;
};

// Popup for missing signature
const MissingSignaturePopup = ({ setShowPopup }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <p>No signature available. Please add it in settings.</p>
        <button onClick={() => setShowPopup(false)}>OK</button>
      </div>
    </div>
  );
};

export default DataSheetFooter;
