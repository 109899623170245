import React, { useState, useRef, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import UploadFiles from "../Engine-Page/mainEngine/Components/UploadFiles";
import { jwtDecode } from "jwt-decode";
import ChatbotQuery from "../Engine-Page/mainEngine/Components/ChatbotQuery";
// import { FaCamera, FaPrint } from "react-icons/fa";
import "./table.css";
import {
  convertToKolkataTimeFormat,
  // formatDate,
  renderMedia,
} from "../../helpers";
import logo from "../../assets/gunam.png";
import { IoIosCopy } from "react-icons/io";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdLocalPrintshop } from "react-icons/md";
import { RiFileEditFill } from "react-icons/ri";
import MarkdownEditorModal from "../Doctor-Page/Components/DoctorFeed/SoapNotesEditer";
import { Aibot } from "./ActivityStyle";
import VitalsModal from "./Vitals";
import BillingDefaultTemplate from "../Billing/BillingDefaultTemplate";
import ImgCarousel from "./carousel";
import { Checkbox, Input, Radio } from "antd";
import Masonry from "react-masonry-css";
import PatientSummary from "./summary";
import axios from "axios";
import { configJson } from "../../Constent/config";
import DentalChart from "./DentalChart";
import PrescModal from "./PrescModal";

const isMobile = () => window.innerWidth <= 768;

const breakpointColumnsObj = {
  default: 3,
  1100: 2,
  700: 1,
};

const Feed = ({
  interactions,
  interactionIdFirst,
  selectedPatientName,
  fetchInteractions,
  doctorDetails,
  audioUrlInteractionId,
  patientSummary,
  content,
}) => {
  // console.log("Feed interaction first", interactionIdFirst);
  const navigate = useNavigate();
  const [mediaSrc, setMediaSrc] = useState();
  const [mediaModal, setMediaModal] = useState(false);
  const [images, setImages] = useState([]);
  const [decode, setDecode] = useState({});
  const [userDetails, setUserDetails] = useState({
    patientId: "",
    hospitalId: "",
    dob: "",
    phone_number: "",
    doctorId: "",
    interactionId: "",
  });
  const [imgExpanded, setImgExpanded] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const [showReadBtn, setShowReadBtn] = useState({});
  const ref = useRef([]);
  const [uploadModal, setUploadModal] = useState(false);
  // const [staticQns, setStaticQns] = useState("");
  const comnotes = useRef();
  const [copied, setCopied] = useState(false);
  // const [editmodel, setEditmodel] = useState(false);
  const [editmodel, setEditModel] = useState({ id: null, isOpen: false });
  const printRef = useRef({});
  const imgRef = React.createRef();
  const [vitalsModal, setVitalsModal] = useState(false);
  const [showBillingTemplate, setShowBillingTemplate] = useState(false);
  const [carouselModal, setCarouselModal] = useState(false);
  const [summaryDrawer, setSummaryDrawer] = useState(false);
  // const [content, setContent] = useState("detail");
  const [showTempComp, setShowTempComp] = useState(false);
  // const [patientSummary, setPatientSummary] = useState([]);
  const [parsedVitals, setParsedVitals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editVitals, setEditVitals] = useState({});
  const [vitalsInteractionId, setVitalsInteractionId] = useState("");
  const [lastInteraction, setLastInteraction] = useState("");
  const [medicineData, setMedicineData] = useState(null);
  const [isPrescModal, setIsPrescModal] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const docId = queryParams.get("doctor");
  const patId = queryParams.get("id");

  const style = {
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    overflow: "hidden",
  };

  const getDivContent = () => comnotes.current?.innerText || "";

  const handelOpenmodel = (type, id) => {
    setEditModel({ id, isOpen: true });
    console.log(`Editing ${type} for detail ID: ${id}`);
  };
  const handleCloseModel = () => {
    setEditModel({ id: null, isOpen: false });
  };

  const fetchImages = () => {
    // setMediaModal(true);
    const types = [
      "mri",
      "blood test",
      "ct scan",
      "x-ray",
      "ultrasound",
      "ecg",
      "biopsy",
      "ct angiography",
      "nuclear medicine",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "",
    ];
    interactions.Interactions?.forEach((interaction) => {
      interaction.InteractionDetails.forEach((detail) => {
        if (
          detail.processedFileContent?.split(".").pop().toLowerCase() === "pdf"
        )
          return null;
        if (types.includes(detail.interaction_detail_type)) {
          setImages((prevImages) => [
            ...prevImages,
            detail.processedFileContent,
          ]);
        }
      });
    });
  };

  const sortedInteractions = interactions?.Interactions?.sort((a, b) => {
    return new Date(b.updated_at) - new Date(a.updated_at);
  });

  const handleClose = () => {
    setMediaModal(false);
    setImages([]);
  };

  // const handleShowImages = (imageUrls) => {
  //   setMediaSrc(imageUrls);
  //   setMediaModal(true);
  // };

  const toggleExpand = (index) => {
    setExpanded((prevIndex) => (prevIndex === index ? null : index));
  };

  const toggleImgExpand = (index) => {
    setImgExpanded((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleResize = () => {
    // console.log("inn");
    ref.current.forEach((el, index) => {
      if (el) {
        const lineHeight = parseFloat(window.getComputedStyle(el).lineHeight);
        const maxVisibleHeight = lineHeight * 3; // Height of 3 lines

        // Compare clientHeight and scrollHeight
        const shouldShowReadMore =
          el.scrollHeight > el.clientHeight &&
          el.scrollHeight > maxVisibleHeight;

        setShowReadBtn((prev) => ({
          ...prev,
          [index]: shouldShowReadMore,
        }));
      }
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize(); // Trigger the calculation initially
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref, interactions]);

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      if (doctorDetails) {
        console.log("doctorDetails OK", doctorDetails);
        setDecode(doctorDetails);
      } else {
        const decoded = jwtDecode(token);
        console.log("DESoocedeOK");
        setDecode(decoded);
      }
    } else {
      navigate("/login");
    }
  }, [doctorDetails]);

  const handlePrint = (detailId) => {
    const contentToPrint = ref.current[detailId];

    // Create a hidden iframe element
    const printFrame = document.createElement("iframe");
    printFrame.style.display = "none"; // Make iframe invisible
    document.body.appendChild(printFrame);

    // Set the iframe content to the content to be printed
    const doc = printFrame.contentWindow.document;

    // Add HTML and dynamic content to the iframe
    doc.open();

    const organizationContent =
      decode.organization_id === 8
        ? `
      <img src="${logo}" alt="Logo" />
      <div class="text-right">
        <div class="font-bold">Gunam Super Speciality Hospital</div>
        <div class="text-sm">Health Care You Deserve</div>
        <div class="font-bold">Dr. ${decode.first_name} ${decode.last_name}</div>
      </div>
    `
        : `
      <div class="text-right">
        <div class="font-bold"></div>
        <div class="text-sm"></div>
        <div class="font-bold">Dr. ${decode.first_name} ${decode.last_name}</div>
      </div>
    `;

    doc.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <style>
            /* Add Tailwind CSS styles or other required styles here */
            @import url('https://cdn.tailwindcss.com');
          </style>
        </head>
        <body>
          <div class="flex items-center justify-between">
            ${organizationContent}
          </div>
          <div class="flex items-center gap-1 font-bold">
            <div>Patient Name: ${interactions?.first_name} ${interactions?.last_name}</div>
         
          </div>
          <div>${contentToPrint.innerHTML}</div>
        </body>
      </html>
    `);

    doc.close();

    // Trigger the print dialog using the iframe
    printFrame.contentWindow.focus(); // Focus on the iframe before printing
    printFrame.contentWindow.print();

    // Remove the iframe after printing
    document.body.removeChild(printFrame);
  };

  // console.log(interactions);

  useEffect(() => {
    setUserDetails({
      patientId: interactions?.patient_id,
      hospitalId: interactions?.hospital_patient_id,
      dob: interactions?.date_of_birth,
      phone_number: interactions?.phone_number,
      doctorId: interactions?.doctor_id,
      interactionId: interactions?.Interactions?.interaction_id,
      orgId: interactions?.organization_id,
    });
    if (interactions?.Interactions) {
      setLastInteraction(interactions?.Interactions[0]?.interaction_id);
    }
  }, [interactions]);

  const openFullScreen = (event) => {
    const imgElement = event.target;

    // Ensure `isMobile` function is defined correctly
    if (typeof isMobile === "function" && !isMobile()) {
      // Check for Fullscreen API support
      if (imgElement.requestFullscreen) {
        imgElement.requestFullscreen();
      } else if (imgElement.mozRequestFullScreen) {
        imgElement.mozRequestFullScreen(); // Firefox
      } else if (imgElement.webkitRequestFullscreen) {
        imgElement.webkitRequestFullscreen(); // Chrome, Safari, Opera
      } else if (imgElement.msRequestFullscreen) {
        imgElement.msRequestFullscreen(); // IE/Edge
      } else {
        console.error("Fullscreen API is not supported by this browser.");
        return;
      }

      // Update the imgRef if it's a React ref
      if (imgRef && typeof imgRef === "object" && imgRef.current) {
        imgRef.current = imgElement;
      }
    } else {
      // For mobile, open image in a new tab instead of fullscreen
      const mediaSrcUrl = mediaSrc ? mediaSrc : imgElement.src;
      window.open(mediaSrcUrl, "_blank");
    }
  };

  // console.log(decode);
  // const getSummary = async (patientId) => {
  //   try {
  //     const res = await axios.get(
  //       `${configJson.backend_URL}/patient/summary/${patientId}`
  //     );
  //     // console.log(res);
  //     setPatientSummary(res?.data?.summaryReport);
  //     // console.log(patientSummary)
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // const generateSummary = async (patientId) => {
  //   setLoading(true);
  //   try {
  //     // interactions.IsVisitUpdated
  //     const res = await axios.post(
  //       `${configJson.backend_URL}/patient/generateSummary/${patientId}`
  //     );
  //     getSummary(userDetails?.patientId);
  //   } catch (err) {
  //     console.log(err);
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // console.log(userDetails?.patientId, "lkjhg");

  // useEffect(() => {
  //   if (interactions?.IsVisitUpdated === true) {
  //     generateSummary(userDetails?.patientId);
  //   } else if (interactions?.IsVisitUpdated === false) {
  //     getSummary(userDetails?.patientId);
  //   }
  // }, [userDetails, interactions]);

  useEffect(() => {
    try {
      if (patientSummary?.Vitalsummary) {
        const parsedData = JSON.parse(patientSummary.Vitalsummary);
        setParsedVitals(parsedData);
      }
    } catch (error) {
      console.error("Error parsing Vitalsummary:", error);
      setParsedVitals([]); // Default to an empty array
    }
  }, [patientSummary]);

  // console.log(parsedVitals);
  // console.log(patientSummary?.Vitalsummary);

  // console.log(medicineData, "MEDdata");
  // const handleSaveMedicine = (prescription) => {
  //   const [, value] = Object.entries(prescription)[0]; // Get the value, ignore the key
  //   setMedicineData({
  //     medicines: value.medicines,
  //     otherMedicines: value.other_medicines,
  //   });
  //   setMedicineData(prescription);
  //   setIsPrescModal(true);
  // };

  return (
    <>
      {/* <div>
        <ChatbotQuery
          organizationId={decode.organization_id}
          doctorId={decode.doctor_id}
          patientId={interactions?.patient_id}
          staticQns={staticQns}
          setStaticQns={setStaticQns}
        />
      </div> */}
      {/* <MediaModal
        mediaModal={mediaModal}
        handleClose={handleClose}
        images={images}
        userDetails={userDetails}
      /> */}
      {/* <div className="flex flex-col gap-3 w-full m-auto sticky top-0 z-50 bg-white mb-2 border-b-2 ">
        <div className="flex items-center justify-center w-full m-auto ">
          <div
            className={`m-auto !w-1/3 cursor-pointer border-b-2  ${
              content === "detail"
                ? "!border-medblue-darktwo border-b-4"
                : "border-[#ACACAC]"
            }`}
            style={{ borderColor: "transparent" }}
            onClick={() => {
              setContent("detail");
              if (fetchInteractions) {
                fetchInteractions(userDetails?.patientId);
              }
            }}
          >
            <img src={`/patient-one.png`} className="m-auto" width={32} />
          </div>
          <div
            className={`m-auto !w-1/3 cursor-pointer border-b-2  ${
              content === "summary"
                ? "!border-medblue-darktwo border-b-4"
                : "border-[#ACACAC]"
            }`}
            style={{ borderColor: "transparent" }}
            onClick={() => {
              setContent("summary");
              if (interactions?.IsVisitUpdated === true) {
                generateSummary(userDetails?.patientId);
              } else if (interactions?.IsVisitUpdated === false) {
                getSummary(userDetails?.patientId);
              }
              // generateSummary(userDetails?.patientId);
              // getSummary(userDetails?.patientId);
            }}
          >
            <img src={`/activity-one.png`} className="m-auto" width={32} />
          </div>
          <div
            className={`m-auto !w-1/3 cursor-pointer border-b-2  ${
              content === "chart"
                ? "!border-medblue-darktwo border-b-4"
                : "border-[#ACACAC]"
            }`}
            style={{ borderColor: "transparent" }}
            onClick={() => {
              setContent("chart");
            }}
          >
            <img src={`/report-one.png`} className="m-auto" width={32} />
          </div>
        </div>
      </div>{" "} */}

      <ImgCarousel
        carouselModal={carouselModal}
        setCarouselModal={setCarouselModal}
        images={images}
        setImages={setImages}
      />
      <UploadFiles
        show={uploadModal}
        onClose={() => setUploadModal(false)}
        userDetails={userDetails}
        patientId={
          userDetails?.patientId != undefined ? userDetails?.patientId : patId
        }
        doctorId={
          userDetails?.doctorId !== null ? userDetails?.doctorId : docId
        }
        setUploadModal={setUploadModal}
        fetchInteractions={fetchInteractions}
      />
      <VitalsModal
        vitalsModal={vitalsModal}
        setVitalsModal={setVitalsModal}
        patientId={
          userDetails?.patientId != undefined ? userDetails?.patientId : patId
        }
        doctorId={
          userDetails?.doctorId !== null ? userDetails?.doctorId : docId
        }
        fetchInteractions={fetchInteractions}
        editVitals={editVitals}
        vitalsInteractionId={vitalsInteractionId}
      />
      <PrescModal
        isPrescModal={isPrescModal}
        setIsPrescModal={setIsPrescModal}
        medicineData={medicineData}
        setMedicineData={setMedicineData}
        patientId={
          userDetails?.patientId != undefined ? userDetails?.patientId : patId
        }
        vitalsInteractionId={vitalsInteractionId}
      />
      {content !== "chart" && (
        <div className="w-full">
          <ChatbotQuery
            organizationId={decode.organization_id}
            organizationCountry={decode.country}
            doctorIdDatasheet={decode.doctor_id}
            interactionIdFirst={interactionIdFirst}
            doctorId={decode.doctor_id}
            // role={decode?.speciality_id ? "doctor" : decode?.role}
            // doctorId={
            //   userDetails?.doctorId !== null ? userDetails?.doctorId : docId
            // }
            patientId={interactions}
            audioUrlInteractionId={audioUrlInteractionId}
            fetchInteractions={fetchInteractions}
            showBillingTemplate={showBillingTemplate}
            setShowBillingTemplate={setShowBillingTemplate}
            // staticQns={staticQns}
            // setStaticQns={setStaticQns}
            setUploadModal={setUploadModal}
            setVitalsModal={setVitalsModal}
            setSummaryDrawer={setSummaryDrawer}
            setShowTempComp={setShowTempComp}
            lastInteraction={lastInteraction}
            IsVisitUpdated={interactions?.IsVisitUpdated}
            content={content}
          />
        </div>
      )}
      {(content === "detail" || content === "LiveTranslate") &&(
        <>
          <PatientSummary
            summaryDrawer={summaryDrawer}
            setSummaryDrawer={setSummaryDrawer}
          />
          {/* {showBillingTemplate && <BillingDefaultTemplate />} */}
          <div className="rounded-lg flex flex-col gap-4 pt-4 feed">
            {/* {showTempComp &&
              <div className="p-8 bg-gray-200 m-auto w-full">
                <div>
                  <button onClick={() => setShowTempComp(false)}>Close</button>
                  <BillingDefaultTemplate />
                </div>
              </div>
              } */}
            {sortedInteractions?.length > 0 ? (
              sortedInteractions?.map((data) => {
                const imageUrls = data?.InteractionDetails.filter((detail) =>
                  [
                    "jpg",
                    "jpeg",
                    "png",
                    "gif",
                    "jfif",
                    "avif",
                    "heic",
                    "webp",
                  ].includes(
                    detail?.processedFileContent?.split(".").pop().toLowerCase()
                  )
                ).map((detail) => ({
                  url: detail?.processedFileContent,
                  date: detail?.created_at,
                }));
                return (
                  <div className="m-auto w-full p-2 sm:p-4 py-2 rounded-lg flex flex-col gap-1 cursor-pointer bg-medblue-dark transition-all duration-300 shadow">
                    <div className="flex flex-col gap-2 sticky top-0 p-2 bg-medblue-dark z-40">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center justify-between w-full gap-2">
                          <div className="flex items-center gap-1">
                            <div className="font-semibold sm:text-lg">
                              {interactions?.first_name}{" "}
                              {interactions?.last_name}
                            </div>
                            <div>-</div>
                            <div className="text-sm">
                              {convertToKolkataTimeFormat(data?.updated_at)}
                            </div>
                            {interactions?.age ? (
                              <div className="text-sm">
                                Age-
                                <span className="font-semibold">
                                  {interactions?.age}Y
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            {interactions?.gender ? (
                              <div className="text-sm">
                                Gender-
                                <span className="font-semibold">
                                  {interactions?.gender}
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {imageUrls?.length > 0 ? (
                  <div
                    className="m-auto bg-white p-2"
                    onClick={() => {
                      fetchImages();
                      setCarouselModal(true);
                    }}
                  >
                    {imageUrls.length == 1 && (
                      <div>
                        <img src={imageUrls[0].url} alt="" />
                      </div>
                    )}
                    {imageUrls.length === 2 && (
                      <div className="flex items-center gap-1">
                        <div
                          className="size-[200px] overflow-hidden"
                          style={{
                            backgroundImage: `url(${imageUrls[0].url})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        />
                        <div
                          className="size-[200px] overflow-hidden"
                          style={{
                            backgroundImage: `url(${imageUrls[1].url})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        />
                      </div>
                    )}

                    {imageUrls.length == 3 && (
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-1">
                          <div
                            className="size-[200px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[0].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                          <div
                            className="size-[200px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[1].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                        </div>
                        <div
                          className="w-full h-[120px] overflow-hidden"
                          style={{
                            backgroundImage: `url(${imageUrls[2].url})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        />
                      </div>
                    )}
                    {imageUrls.length === 4 && (
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-1">
                          <div
                            className="size-[200px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[0].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                          <div
                            className="size-[200px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[1].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                        </div>
                        <div className="flex items-center gap-1">
                          <div
                            className="w-[65%] h-[150px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[2].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                          <div
                            className="w-[35%] h-[150px] overflow-hidden flex items-center justify-center text-white text-xl font-semibold"
                            style={{
                              backgroundImage: `url(${imageUrls[3].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {imageUrls.length >= 5 && (
                      <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-1">
                          <div
                            className="size-[200px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[0].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                          <div
                            className="size-[200px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[1].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                        </div>
                        <div className="flex items-center gap-1">
                          <div
                            className="w-1/3 h-[150px] overflow-hidden"
                            style={{
                              backgroundImage: `url(${imageUrls[2].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                          <div
                            className="w-1/3 h-[150px] overflow-hidden flex items-center justify-center text-white text-xl font-semibold"
                            style={{
                              backgroundImage: `url(${imageUrls[3].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          />
                          <div
                            className="w-1/3 h-[150px] overflow-hidden flex items-center justify-center text-white text-xl font-semibold"
                            style={{
                              backgroundImage: `url(${imageUrls[4].url})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                            }}
                          >
                            + {imageUrls.length - 4}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )} */}
                    {/* <div className="gallery">
                  {imageUrls.map((img, index) => {
                    return (
                      <div className="pics" key={index}>
                        <img src={img.url} alt="" style={{width: '100%'}} />
                      </div>
                    )
                  })}
                </div> */}
                    {/* <div className="image-grid">
      {imageUrls.map((img, index) => (
        <div className="image-grid-item" key={index}>
          <img src={img.url} alt={`Image ${index + 1}`} />
        </div>
      ))}
    </div> */}
                    <Masonry
                      breakpointCols={breakpointColumnsObj}
                      className="masonry-grid"
                      columnClassName="masonry-grid-column"
                      onClick={() => {
                        fetchImages();
                        setCarouselModal(true);
                      }}
                    >
                      {imageUrls.map((img, index) => (
                        <div className="image-grid-item" key={index}>
                          <img src={img.url} alt="" />
                        </div>
                      ))}
                    </Masonry>

                    {data?.InteractionDetails.length > 0 ? (
                      data.InteractionDetails.map((detail, index) => {
                        const excludedTypes = [
                          "mri",
                          "blood test",
                          "ct scan",
                          "processed-speech-to-txt",
                          "x-ray",
                          "ultrasound",
                          "ecg",
                          "biopsy",
                          "ct angiography",
                          "nuclear medicine",
                          "1",
                          "2",
                          "3",
                          "4",
                          "5",
                          "6",
                          "7",
                          "8",
                          "9",
                          "soap-error",
                          "raw-audio-txt",
                          "Edited Prescription",
                          "Edited Assessment",
                          // "soap-notes"
                          "past medicine history",
                          "Drawing Template",
                          // "img",
                        ];

                        // if (!detail?.processedFileContent) return null;
                        if (
                          excludedTypes.includes(
                            detail?.interaction_detail_type
                          )
                        )
                          return null;

                        const contentType = renderMedia(
                          detail?.processedFileContent
                        )?.type;

                        // console.log('TYPEE', contentType);

                        const isExpanded = expanded === detail.detail_id;
                        // if (contentType === "img") return null;
                        if (
                          detail?.interaction_detail_type == "" ||
                          (detail?.interaction_detail_type == "img" &&
                            detail?.prescriptions == null)
                        )
                          return null;

                        return (
                          <div
                            key={index}
                            className={`p-2 my-1 ${
                              contentType == "audio"
                                ? ""
                                : "bg-white  border-1 border-gray-300 rounded-2xl"
                            } text-sm sm:text-base`}
                            ref={comnotes}
                          >
                            {Array.isArray(detail?.prescriptions) &&
                              detail?.prescriptions?.length > 0 && (
                                <div>
                                  {detail?.prescriptions?.map(
                                    (prescription, index) => {
                                      const [, value] =
                                        Object.entries(prescription)[0];
                                      return (
                                        <div key={index}>
                                          <div className="flex items-center justify-between">
                                            <div className="font-semibold">
                                              Prescription
                                            </div>
                                            <button
                                              type="button"
                                              className="float-right p-1 w-fit"
                                              onClick={() => {
                                                setMedicineData(prescription);
                                                setVitalsInteractionId(
                                                  detail?.interaction_id
                                                );
                                                setIsPrescModal(true);
                                              }}
                                            >
                                              <RiFileEditFill size={20} />
                                            </button>
                                          </div>

                                          <table
                                            border="1"
                                            style={{
                                              width: "100%",
                                              borderCollapse: "collapse",
                                            }}
                                            className="pointer-events-none"
                                          >
                                            <thead>
                                              <tr>
                                                <th>Medicine Name</th>
                                                <th>Dosage</th>
                                                <th>Duration</th>
                                                <th>Frequency</th>
                                                <th>Instructions</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {value.medicines.map(
                                                (medicine, idx) => (
                                                  <tr key={idx}>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        value={medicine.name}
                                                        // onChange={(e) =>
                                                        //   handleMedicineEdit(
                                                        //     e,
                                                        //     index,
                                                        //     "medicines",
                                                        //     "name"
                                                        //   )
                                                        // }
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        value={
                                                          medicine.dosage ||
                                                          "N/A"
                                                        }
                                                        // onChange={(e) =>
                                                        //   handleMedicineEdit(
                                                        //     e,
                                                        //     index,
                                                        //     "medicines",
                                                        //     "dosage"
                                                        //   )
                                                        // }
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        value={
                                                          medicine?.duration
                                                        }
                                                        // onChange={(e) =>
                                                        //   handleMedicineEdit(
                                                        //     e,
                                                        //     index,
                                                        //     "medicines",
                                                        //     "duration"
                                                        //   )
                                                        // }
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        value={
                                                          medicine?.frequency
                                                        }
                                                        // onChange={(e) =>
                                                        //   handleMedicineEdit(
                                                        //     e,
                                                        //     index,
                                                        //     "medicines",
                                                        //     "frequency"
                                                        //   )
                                                        // }
                                                      />
                                                    </td>
                                                    <td>
                                                      <Input
                                                        type="text"
                                                        value={
                                                          medicine.intake_instructions ||
                                                          "N/A"
                                                        }
                                                        // onChange={(e) =>
                                                        //   handleMedicineEdit(
                                                        //     e,
                                                        //     index,
                                                        //     "medicines",
                                                        //     "intake_instructions"
                                                        //   )
                                                        // }
                                                      />
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>

                                          {value.other_medicines.length > 0 && (
                                            <>
                                              <div className="font-semibold">
                                                Other Medicines
                                              </div>
                                              <table
                                                border="1"
                                                style={{
                                                  width: "100%",
                                                  borderCollapse: "collapse",
                                                }}
                                                className="pointer-events-none"
                                              >
                                                <thead>
                                                  <tr>
                                                    <th>Medicine Name</th>
                                                    <th>Dosage</th>
                                                    <th>Duration</th>
                                                    <th>Frequency</th>
                                                    <th>Instructions</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {value.other_medicines.map(
                                                    (medicine, idx) => (
                                                      <tr key={idx}>
                                                        <td>
                                                          <Input
                                                            type="text"
                                                            value={
                                                              medicine.name
                                                            }
                                                            // onChange={(e) =>
                                                            //   handleMedicineEdit(
                                                            //     e,
                                                            //     index,
                                                            //     "otherMedicines",
                                                            //     "name"
                                                            //   )
                                                            // }
                                                          />
                                                        </td>
                                                        <td>
                                                          <Input
                                                            type="text"
                                                            value={
                                                              medicine.dosage ||
                                                              "N/A"
                                                            }
                                                            // onChange={(e) =>
                                                            //   handleMedicineEdit(
                                                            //     e,
                                                            //     index,
                                                            //     "otherMedicines",
                                                            //     "dosage"
                                                            //   )
                                                            // }
                                                          />
                                                        </td>
                                                        <td>
                                                          <Input
                                                            type="text"
                                                            value={
                                                              medicine.duration
                                                            }
                                                            // onChange={(e) =>
                                                            //   handleMedicineEdit(
                                                            //     e,
                                                            //     index,
                                                            //     "otherMedicines",
                                                            //     "duration"
                                                            //   )
                                                            // }
                                                          />
                                                        </td>
                                                        <td>
                                                          <Input
                                                            type="text"
                                                            value={
                                                              medicine.frequency
                                                            }
                                                            // onChange={(e) =>
                                                            //   handleMedicineEdit(
                                                            //     e,
                                                            //     index,
                                                            //     "otherMedicines",
                                                            //     "frequency"
                                                            //   )
                                                            // }
                                                          />
                                                        </td>
                                                        <td>
                                                          <Input
                                                            type="text"
                                                            value={
                                                              medicine.intake_instructions ||
                                                              "N/A"
                                                            }
                                                            // onChange={(e) =>
                                                            //   handleMedicineEdit(
                                                            //     e,
                                                            //     index,
                                                            //     "otherMedicines",
                                                            //     "intake_instructions"
                                                            //   )
                                                            // }
                                                          />
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </table>
                                            </>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}{" "}
                                </div>
                              )}
                            {detail.interaction_detail_type === "vitals" &&
                              detail?.vitals !== null && (
                                <>
                                  <button
                                    type="button"
                                    className="float-right p-1 w-fit"
                                    onClick={() => {
                                      setEditVitals(detail?.vitals);
                                      setVitalsInteractionId(
                                        detail?.interaction_id
                                      );
                                      setVitalsModal(true);
                                    }}
                                  >
                                    <RiFileEditFill size={15} />
                                  </button>
                                  {/* <div className="pointer-events-none w-fit m-auto"> */}
                                  <div className="pointer-events-none rounded p-2 text-[#666666]">
                                    {detail?.vitals?.height && (
                                      <div className="flex flex-col gap-2">
                                        <div className="font-semibold mb-2 flex items-center justify-between text-xl text-medblue-light">
                                          <div className="">Basic details</div>
                                          <div className="mr-4">
                                            BMI:{" "}
                                            {(
                                              detail?.vitals?.weight /
                                              (((detail?.vitals?.height / 100) *
                                                detail?.vitals?.height) /
                                                100)
                                            ).toFixed(2)}
                                          </div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-3 mb-2 w-full">
                                          <div className="flex items-center gap-4 w-full">
                                            <label className="w-1/2">
                                              Height (cm)
                                              <span className="text-red-600">
                                                &#42;
                                              </span>
                                            </label>
                                            <div className="border-2 p-1 px-4 rounded w-fit border-[#979797]">
                                              {detail?.vitals?.height}
                                            </div>
                                          </div>
                                          <div className="flex items-center gap-4 w-full">
                                            <label className="w-1/2">
                                              Weight (kg)
                                              <span className="text-red-600">
                                                &#42;
                                              </span>
                                            </label>
                                            <div className="border-2 p-1 px-4 rounded w-fit border-[#979797]">
                                              {detail?.vitals?.weight}
                                            </div>
                                          </div>
                                          {detail?.vitals?.temperature && (
                                            <div className="flex items-center gap-4 w-full">
                                              <label className="w-1/2">
                                                Body Temp.(°F)
                                              </label>
                                              <div className="border-2 p-1 px-4 rounded w-fit border-[#979797]">
                                                {detail?.vitals?.temperature}
                                              </div>
                                            </div>
                                          )}
                                          {detail?.vitals?.respRate && (
                                            <div className="flex items-center gap-4 w-full">
                                              <label className="w-1/2">
                                                Respiratory Rate (bpm)
                                              </label>
                                              <div className="border-2 p-1 px-4 rounded w-fit border-[#979797]">
                                                {detail?.vitals?.respRate}
                                              </div>
                                            </div>
                                          )}
                                          {detail?.vitals?.bloodSugar && (
                                            <div className="flex items-center gap-4 w-full">
                                              <label className="w-1/2">
                                                Blood Sugar (mg/dL)
                                              </label>
                                              <div className="border-2 p-1 px-4 rounded w-fit border-[#979797]">
                                                {detail?.vitals?.bloodSugar}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                    <div className="flex flex-col gap-2">
                                      {(detail?.vitals?.systolicBP ||
                                        detail?.vitals?.diastolicBP ||
                                        detail?.vitals?.pulse ||
                                        detail?.vitals?.oxygenSaturation) && (
                                        <div className="text-xl font-semibold text-medblue-light">
                                          Vitals
                                        </div>
                                      )}
                                      {(detail?.vitals?.systolicBP ||
                                        detail?.vitals?.diastolicBP) && (
                                        <div className="border-[#979797] border rounded p-2 flex flex-col gap-2 ">
                                          <div className="font-semibold ">
                                            Blood pressure
                                          </div>
                                          {detail?.vitals?.systolicBP && (
                                            <div className="flex items-center gap-2 justify-around !flex-wrap md:!flex-nowrap">
                                              <div className="md:w-[40%]">
                                                Systolic blood pressure (mmHg)
                                              </div>
                                              <div className="flex items-center gap-2 md:w-[40%]">
                                                <div
                                                  className={`outline-none border-1 p-1 px-4 rounded w-fit ${ detail?.vitals
                                                    ?.systolicBP > 140 ? 'text-red-500' : ''}`}
                                                  style={{
                                                    borderColor:
                                                      detail?.vitals
                                                        ?.systolicBP > 140
                                                        ? "red"
                                                        : "#979797",
                                                  }}
                                                >
                                                  {detail?.vitals?.systolicBP}
                                                </div>
                                                <div className="md:w-[100px]">
                                                  (100 - 140)
                                                </div>
                                              </div>
                                              <Checkbox
                                                checked={
                                                  detail?.vitals?.abnormalSystolic
                                                }
                                                className="md:w-[20%]"
                                              >
                                                Abnormal
                                              </Checkbox>
                                            </div>
                                          )}
                                          {detail?.vitals?.diastolicBP && (
                                            <div className="flex items-center gap-2 justify-around !flex-wrap md:!flex-nowrap">
                                              <div className="md:w-[40%]">
                                                Diastolic blood pressure (mmHg)
                                              </div>
                                              <div className="flex items-center gap-2 md:w-[40%]">
                                                <div
                                                  className={`outline-none border-1 p-1 px-4 rounded w-fit ${detail?.vitals?.diastolicBP > 90 ? 'text-red-500' : ''}`}
                                                  style={{
                                                    borderColor:
                                                      detail?.vitals
                                                        ?.diastolicBP > 90
                                                        ? "red"
                                                        : "#979797",
                                                  }}
                                                >
                                                  {detail?.vitals?.diastolicBP}
                                                </div>
                                                <div className="md:w-[100px]">
                                                  (60 - 90)
                                                </div>
                                              </div>
                                              <Checkbox
                                                checked={
                                                  detail?.vitals?.abnormalDiastolic
                                                }
                                                className="md:w-[20%]"
                                              >
                                                Abnormal
                                              </Checkbox>
                                            </div>
                                          )}
                                          <div className="flex gap-2 flex-col md:flex-row items-center justify-between">
                                            <div className="font-semibold">
                                              Body position
                                            </div>
                                            <div className="grid grid-cols-3 xs:grid-cols-6 gap-1">
                                              {[
                                                {
                                                  label: "Sitting",
                                                  value: "Sitting",
                                                },
                                                {
                                                  label: "Recumbent",
                                                  value: "Recumbent",
                                                },
                                                {
                                                  label: "Unknown",
                                                  value: "Unknown",
                                                },
                                                {
                                                  label: "Other",
                                                  value: "Other",
                                                },
                                                {
                                                  label: "Standing",
                                                  value: "Standing",
                                                },
                                                {
                                                  label: "Fowler",
                                                  value: "Fowler",
                                                },
                                              ].map((item, index) => (
                                                <button
                                                  key={index}
                                                  className={`border p-2 rounded-[5px] text-center ${
                                                    detail?.vitals?.position ==
                                                    item.label
                                                      ? "vitals-border vital-text"
                                                      : ""
                                                  }`}
                                                >
                                                  {item.label}
                                                </button>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      {detail?.vitals?.pulse && (
                                        <div className="flex items-center gap-2 justify-around !flex-wrap md:!flex-nowrap">
                                          <div className="md:w-[40%]">
                                            Pulse (beats/min)
                                          </div>
                                          <div className="flex items-center gap-2 md:w-[40%]">
                                            <div
                                              className={`outline-none border-1 px-4 p-1 rounded w-fit ${detail?.vitals?.pulse > 100 ? 'text-red-500' : ''}`}
                                              style={{
                                                borderColor:
                                                  detail?.vitals?.pulse > 100
                                                    ? "red"
                                                    : "#979797",
                                              }}
                                            >
                                              {detail?.vitals?.pulse}
                                            </div>
                                            <div className="md:w-[100px]">
                                              (60 - 100)
                                            </div>
                                          </div>
                                          <Checkbox
                                            checked={
                                              detail?.vitals?.abnormalPulse
                                            }
                                            className="md:w-[20%]"
                                          >
                                            Abnormal
                                          </Checkbox>
                                        </div>
                                      )}
                                      {detail?.vitals?.oxygenSaturation && (
                                        <div className="flex items-center gap-2 justify-around !flex-wrap md:!flex-nowrap ">
                                          <div className="w-[40%]">
                                            Arterial blood oxygen saturation
                                            (pulse oximeter) (%)
                                          </div>
                                          <div className="flex items-center gap-2 md:w-[40%]">
                                            <div
                                              className={`outline-none border-1 px-4 p-1 rounded w-fit ${detail?.vitals
                                                ?.oxygenSaturation < 95 ? 'text-red-500' : ''}`}
                                              style={{
                                                borderColor:
                                                  detail?.vitals
                                                    ?.oxygenSaturation < 95
                                                    ? "red"
                                                    : "#979797",
                                              }}
                                            >
                                              {detail?.vitals?.oxygenSaturation}
                                            </div>
                                            <div className="md:w-[100px]">
                                              (&gt;95)
                                            </div>
                                          </div>
                                          <Checkbox
                                            checked={
                                              detail?.vitals?.abnormalOxygen
                                            }
                                            className="md:w-[20%]"
                                          >
                                            Abnormal
                                          </Checkbox>
                                        </div>
                                      )}
                                      <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-2 mt-3 border border-medblue-light rounded-lg p-2">
                                        {[
                                          {
                                            label: "Diabetes Mellitus",
                                            value:
                                              detail?.vitals?.diabetesMellitus,
                                          },
                                          {
                                            label: "Cancer",
                                            value: detail?.vitals?.cancer,
                                          },
                                          {
                                            label: "Hypertension",
                                            value: detail?.vitals?.hypertension,
                                          },
                                          {
                                            label: "Arthritis",
                                            value: detail?.vitals?.arthritis,
                                          },
                                          {
                                            label: "Cardiac Diseases",
                                            value:
                                              detail?.vitals?.cardiacDiseases,
                                          },
                                          {
                                            label: "Asthma",
                                            value: detail?.vitals?.asthma,
                                          },
                                          {
                                            label: "Hyperthyroidism",
                                            value:
                                              detail?.vitals?.hyperthyrodism,
                                          },
                                          {
                                            label: "Epilepsy",
                                            value: detail?.vitals?.epilepsy,
                                          },
                                          {
                                            label: "Hypothyroidism",
                                            value:
                                              detail?.vitals?.hypothyroidism,
                                          },
                                          {
                                            label: "Kidney Diseases",
                                            value:
                                              detail?.vitals?.kidneyDiseases,
                                          },
                                        ].map((item, index) => (
                                          <div
                                            key={index}
                                            className={`flex items-center justify-between p-2 rounded-lg `}
                                          >
                                            <div className="flex-1 text-left  font-medium">
                                              {item.label}
                                            </div>
                                            <Checkbox checked={item?.value} />
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                            {(detail?.interaction_detail_type ===
                              "soap-notes" ||
                              detail?.interaction_detail_type ===
                                "record-notes") && (
                              <>
                                {editmodel?.id === detail.detail_id &&
                                  editmodel?.isOpen && (
                                    <MarkdownEditorModal
                                      data={detail.processedFileContent}
                                      id={detail.detail_id}
                                      show={editmodel.isOpen}
                                      patient_id={interactions.patient_id}
                                      name={`${interactions?.first_name} ${interactions?.last_name}`}
                                      handleClose={handleCloseModel}
                                      fetchInteractions={fetchInteractions}
                                    />
                                  )}

                                <div className="flex items-center justify-end">
                                  {copied && (
                                    <span className="copy-feedback">
                                      Copied!
                                    </span>
                                  )}

                                  {/* Separate Edit Button for SOAP Notes */}
                                  {detail.interaction_detail_type ===
                                    "soap-notes" && (
                                    <button
                                      type="button"
                                      className="text-btn-top p-1 w-fit"
                                      onClick={() =>
                                        handelOpenmodel(
                                          "soap-notes",
                                          detail.detail_id
                                        )
                                      }
                                    >
                                      <RiFileEditFill size={15} />
                                    </button>
                                  )}

                                  {/* Separate Edit Button for Record Notes */}
                                  {detail.interaction_detail_type ===
                                    "record-notes" && (
                                    <button
                                      type="button"
                                      className="text-btn-top p-1 w-fit"
                                      onClick={() =>
                                        handelOpenmodel(
                                          "record-notes",
                                          detail.detail_id
                                        )
                                      }
                                    >
                                      <RiFileEditFill size={15} />
                                    </button>
                                  )}

                                  {/* Copy to Clipboard */}
                                  <CopyToClipboard
                                    text={getDivContent()}
                                    onCopy={() => setCopied(true)}
                                  >
                                    <button
                                      type="button"
                                      className="text-btn-top p-1 w-fit"
                                    >
                                      <IoIosCopy size={15} />
                                    </button>
                                  </CopyToClipboard>

                                  {/* Print Button */}
                                  <div
                                    className="p-1 w-fit"
                                    onClick={() =>
                                      handlePrint(detail.detail_id)
                                    }
                                  >
                                    <MdLocalPrintshop />
                                  </div>
                                </div>
                              </>
                            )}
                            {detail.interaction_detail_type !== "vitals" && (
                              <div className={`flex flex-col gap-2`}>
                                <div
                                  className={`${
                                    contentType == "audio" ? "!h-fit" : ""
                                  }`}
                                  style={isExpanded ? null : style}
                                  ref={(el) =>
                                    (ref.current[detail.detail_id] = el)
                                  }
                                  // onClick={() => {
                                  //   fetchImages();
                                  //   setCarouselModal(true);
                                  // }}
                                >
                                  {renderMedia(detail?.processedFileContent)}
                                </div>
                              </div>
                            )}
                            {renderMedia(detail?.processedFileContent)?.type !==
                              "audio" &&
                              showReadBtn[detail.detail_id] && (
                                <div
                                  className="w-fit float-right cursor-pointer text-[#06AEB6] font-semibold"
                                  onClick={() => toggleExpand(detail.detail_id)}
                                >
                                  {isExpanded ? "Show less" : "Read more"}
                                </div>
                              )}
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-center">No details available</div>
                    )}
                  </div>
                );
              })
            ) : (
              <div className="text-center text-lg font-semibold mt-10">
             Start recording now...
              </div>
            )}
          </div>
        </>
      )}
      {content === "summary" && (
        <div className="w-full">
          {loading ? (
            <div className="flex items-center justify-center h-full">
              <Spinner animation="border" />
            </div>
          ) : (
            <>
              {patientSummary?.summary == null &&
              patientSummary?.Vitalsummary == null ? (
                <div className="mt-10 text-center">
                  No summary available for this patient
                </div>
              ) : (
                <div className="flex flex-col gap-6 p-6 w-full">
                  {/* {patientSummary?.summary && (
                    <div>
                      <div className="text-lg font-semibold">Summary:</div>
                      <div>{patientSummary?.summary}</div>
                    </div>
                  )} */}
                  {patientSummary?.summary && (
                    <div>
                      <div className="text-lg font-semibold">Summary:</div>
                      <div>
                        {patientSummary.summary
                          .split("\n")
                          .map((line, index) => (
                            <div key={index}>{line}</div>
                          ))}
                      </div>
                    </div>
                  )}
                  {patientSummary?.Vitalsummary && parsedVitals?.height ? (
                    <div className="pointer-events-none border rounded p-2">
                      {parsedVitals?.height && (
                        <div className="flex flex-col gap-2">
                          <div className="font-semibold mb-2">
                            Basic details
                          </div>
                          {parsedVitals?.BMI && (
                            <div className="font-semibold mb-2">
                              BMI : {parsedVitals?.BMI}
                            </div>
                          )}
                          <div className="grid grid-cols-2 gap-3 mb-2 w-full">
                            <div className="flex items-center gap-4 w-full">
                              <label className="w-1/2">Height (cm)</label>
                              <div className="border-2 p-1 px-4 rounded w-fit bg-slate-200">
                                {parsedVitals?.height}
                              </div>
                            </div>
                            <div className="flex items-center gap-4 w-full">
                              <label className="w-1/2">Weight (kg)</label>
                              <div className="border-2 p-1 px-4 rounded w-fit bg-slate-200">
                                {parsedVitals?.weight}
                              </div>
                            </div>
                            {parsedVitals?.temperature && (
                              <div className="flex items-center gap-4 w-full">
                                <label className="w-1/2">Body Temp.(°F)</label>
                                <div className="border-2 p-1 px-4 rounded w-fit bg-slate-200">
                                  {parsedVitals?.temperature}
                                </div>
                              </div>
                            )}
                            {parsedVitals?.respRate && (
                              <div className="flex items-center gap-4 w-full">
                                <label className="w-1/2">
                                  Respiratory Rate (bpm)
                                </label>
                                <div className="border-2 p-1 px-4 rounded w-fit bg-slate-200">
                                  {parsedVitals?.respRate}
                                </div>
                              </div>
                            )}
                            {parsedVitals?.respRate && (
                              <div className="flex items-center gap-4 w-full">
                                <label className="w-1/2">
                                  Blood Sugar (mg/dL)
                                </label>
                                <div className="border-2 p-1 px-4 rounded w-fit bg-slate-200">
                                  {parsedVitals?.bloodSugar}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="flex flex-col gap-2">
                        {(parsedVitals?.systolicBP ||
                          parsedVitals?.diastolicBP ||
                          parsedVitals?.pulse ||
                          parsedVitals?.oxygenSaturation) && (
                          <div className="text-xl font-semibold">Vitals</div>
                        )}
                        {(parsedVitals?.systolicBP ||
                          parsedVitals?.diastolicBP) && (
                          <div className="border-black border rounded p-2 flex flex-col gap-2 ">
                            <div className="font-semibold ">Blood pressure</div>
                            <div className="flex items-center gap-2 justify-around !flex-wrap md:!flex-nowrap">
                              <div className="md:w-[40%]">
                                Systolic blood pressure (mmHg)
                              </div>
                              <div className="flex items-center gap-2 md:w-[40%]">
                                <div
                                  className={`outline-none border-2 p-1 px-4 rounded w-fit bg-slate-200`}
                                  style={{
                                    borderColor:
                                      parsedVitals?.systolicBP > 140
                                        ? "red"
                                        : "transparent",
                                  }}
                                >
                                  {parsedVitals?.systolicBP}
                                </div>
                                <div className="md:w-[100px]">(100 - 140)</div>
                              </div>
                              <Checkbox
                                checked={parsedVitals?.systolicBP > 140}
                                className="md:w-[20%]"
                              >
                                Abnormal
                              </Checkbox>
                            </div>
                            <div className="flex items-center gap-2 justify-around !flex-wrap md:!flex-nowrap">
                              <div className="md:w-[40%]">
                                Diastolic blood pressure (mmHg)
                              </div>
                              <div className="flex items-center gap-2 md:w-[40%]">
                                <div
                                  className="outline-none border-2 p-1 px-4 rounded w-fit bg-slate-200"
                                  style={{
                                    borderColor:
                                      parsedVitals?.diastolicBP > 90
                                        ? "red"
                                        : "transparent",
                                  }}
                                >
                                  {parsedVitals?.diastolicBP}
                                </div>
                                <div className="md:w-[100px]">(60 - 90)</div>
                              </div>
                              <Checkbox
                                checked={parsedVitals?.diastolicBP > 90}
                                className="md:w-[20%]"
                              >
                                Abnormal
                              </Checkbox>
                            </div>
                            <div className="flex gap-2 flex-col md:flex-row items-center justify-between">
                              <div className="font-semibold">Body position</div>
                              <Radio.Group
                                value={parsedVitals?.position?.toLowerCase()}
                                className="m-auto"
                              >
                                <Radio.Button value="sitting">
                                  Sitting
                                </Radio.Button>
                                <Radio.Button value="recumbent">
                                  Recumbent
                                </Radio.Button>
                                <Radio.Button value="unknown">
                                  Unknown
                                </Radio.Button>
                                <Radio.Button value="other">Other</Radio.Button>
                                <Radio.Button value="standing">
                                  Standing
                                </Radio.Button>
                                <Radio.Button value="fowler">
                                  Fowler
                                </Radio.Button>
                              </Radio.Group>
                            </div>
                          </div>
                        )}
                        {parsedVitals?.pulse && (
                          <div className="flex items-center gap-2 justify-around !flex-wrap md:!flex-nowrap">
                            <div className="md:w-[40%]">Pulse (beats/min)</div>
                            <div className="flex items-center gap-2 md:w-[40%]">
                              <div
                                className="outline-none border-2 px-4 p-1 rounded w-fit bg-slate-200"
                                style={{
                                  borderColor:
                                    parsedVitals?.pulse > 100
                                      ? "red"
                                      : "transparent",
                                }}
                              >
                                {parsedVitals?.pulse}
                              </div>
                              <div className="md:w-[100px]">(60 - 100)</div>
                            </div>
                            <Checkbox
                              checked={parsedVitals?.pulse > 100}
                              className="md:w-[20%]"
                            >
                              Abnormal
                            </Checkbox>
                          </div>
                        )}
                        {parsedVitals?.oxygenSaturation && (
                          <div className="flex items-center gap-2 justify-around !flex-wrap md:!flex-nowrap ">
                            <div className="w-[40%]">
                              Arterial blood oxygen saturation (pulse oximeter)
                              (%)
                            </div>
                            <div className="flex items-center gap-2 md:w-[40%]">
                              <div
                                className="outline-none border-2 px-4 p-1 rounded w-fit bg-slate-200"
                                style={{
                                  borderColor:
                                    parsedVitals?.oxygenSaturation > 95
                                      ? "red"
                                      : "transparent",
                                }}
                              >
                                {parsedVitals?.oxygenSaturation}
                              </div>
                              <div className="md:w-[100px]">(&gt;95)</div>
                            </div>
                            <Checkbox
                              checked={parsedVitals?.oxygenSaturation > 95}
                              className="md:w-[20%]"
                            >
                              Abnormal
                            </Checkbox>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div>
                      {/* {typeof parsedVitals !== "string" && (
                        <>
                          <div className="text-lg font-semibold">
                            Vitals Summary:
                          </div>
                          {Object.entries(parsedVitals).map(([key, value]) => (
                            <div key={key}>
                              <strong>{key}:</strong> {value}
                            </div>
                          ))}
                        </>
                      )} */}
                    </div>
                  )}
                  {patientSummary?.medSummary !== null && (
                    <div>
                      <div className="text-lg font-semibold">Medication:</div>
                      <div>{patientSummary?.medSummary}</div>
                    </div>
                  )}
                  {patientSummary?.images !== null && (
                    <Masonry
                      breakpointCols={breakpointColumnsObj}
                      className="masonry-grid w-full"
                      columnClassName="masonry-grid-column"
                    >
                      {patientSummary?.images.map((data, index) => {
                        return (
                          <img
                            src={data}
                            alt=""
                            className="rounded w-full m-auto image-grid-item"
                            key={index}
                          />
                        );
                      })}
                    </Masonry>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      )}
      {content === "chart" && <DentalChart patientId={patId} decode={decode} interactionIdFirst={interactionIdFirst} />}
    </>
  );
};

export default Feed;
