import React, { useState, useEffect, useRef } from "react";
import { onlyDate } from "../../helpers";
import { Input } from "antd";
import axios from "axios";
import Streamer from "./Streamer";
import Stream from "./Stream";
import MicRecorder from "mic-recorder-to-mp3";
import { v4 as uuidv4 } from "uuid";
import { configJson } from "../../Constent/config";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const DentalChart = ({ patientId, decode, interactionIdFirst }) => {
  const [teeth, setTeeth] = useState([
    {
      toothId: 18,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 17,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 16,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 15,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 14,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 13,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 12,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 11,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 21,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 22,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 23,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 24,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 25,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 26,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 27,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 28,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 48,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 47,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 46,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 45,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 44,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 43,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 42,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 41,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 31,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 32,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 33,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 34,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 35,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 36,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 37,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
    {
      toothId: 38,
      buccal: {
        mobility: "0",
        isImplant: false,
        furcation: [0],
        bleedOnProbe: [false, false, false],
        plaque: [false, false, false],
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
      },
      palatal: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0, 0],
        notes: "",
      },
      lingual: {
        gingivalMargin: [0, 0, 0],
        probingDepth: [0, 0, 0],
        plaque: [false, false, false],
        bleedOnProbe: [false, false, false],
        furcation: [0],
        notes: "",
      },
    },
  ]);
  const teeth1 = teeth.slice(0, 8);
  const teeth2 = teeth.slice(8, 16);
  const teeth3 = teeth.slice(16, 24);
  const teeth4 = teeth.slice(24, 32);

  const maxVal = 12;
  const [width, setWidth] = useState(window.innerWidth * 0.5);
  const height = 100;

  const [isRecording, setIsRecording] = useState(false);
  const streamIntervalRef = useRef(null);
  const [uid, setUid] = useState(""); // Store `uid` for the recording session
  const audioChunksRef = useRef([]); // To store audio chunks for playback
  const [audio, setAudio] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const chunkOrderRef = useRef(0);

  // console.log(audioChunksRef);

  const svgWidth = 440;
  const svgHeight = 40; // Adjust height based on the maximum gingival margin value

  const calculateBuccalPoints = (teeth) => {
    let buccalGingivalPoints = "";
    let buccalProbingPoints = "";

    const toothWidth = 55; // Set max width to 55px
    const maxDepth = 10; // Maximum depth you expect for gingival margin or probing depth
    const scaleY = svgHeight / maxDepth; // Calculate a scaling factor based on svgHeight

    // Iterate over all teeth and calculate continuous points for gingival and probing depths
    for (let tIndex = 0; tIndex < teeth.length; tIndex++) {
      const tooth = teeth[tIndex];

      for (let i = 0; i < tooth.buccal.gingivalMargin.length; i++) {
        const x =
          tIndex * toothWidth +
          (i / (tooth.buccal.gingivalMargin.length - 1)) * toothWidth; // Continuous X-axis across all teeth
        const gingivalY = tooth.buccal.gingivalMargin[i];
        const probingY = gingivalY + tooth.buccal.probingDepth[i];

        // Scale the Y values and reverse the Y-axis to fit within the svgHeight
        const gingivalCoord = svgHeight - gingivalY * scaleY;
        const probingCoord = svgHeight - probingY * scaleY;

        buccalGingivalPoints += `${x},${gingivalCoord} `;
        buccalProbingPoints += `${x},${probingCoord} `;
      }
    }

    return {
      buccalGingivalPoints: buccalGingivalPoints.trim(),
      buccalProbingPoints: buccalProbingPoints.trim(),
    };
  };

  const calculatePalatalPoints = (teeth) => {
    let palatalGingivalPoints = "";
    let palatalProbingPoints = "";

    const toothWidth = 55; // Set max width to 55px
    const maxDepth = 10; // Maximum depth you expect for gingival margin or probing depth
    const scaleY = svgHeight / maxDepth; // Calculate a scaling factor based on svgHeight

    // Iterate over all teeth and calculate continuous points for gingival and probing depths
    for (let tIndex = 0; tIndex < teeth.length; tIndex++) {
      const tooth = teeth[tIndex];

      for (let i = 0; i < tooth.palatal.gingivalMargin.length; i++) {
        const x =
          tIndex * toothWidth +
          (i / (tooth.palatal.gingivalMargin.length - 1)) * toothWidth; // Continuous X-axis across all teeth
        const gingivalY = tooth.palatal.gingivalMargin[i];
        const probingY = gingivalY + tooth.palatal.probingDepth[i];

        // Scale the Y values and reverse the Y-axis to fit within the svgHeight
        const gingivalCoord = svgHeight - gingivalY * scaleY;
        const probingCoord = svgHeight - probingY * scaleY;

        palatalGingivalPoints += `${x},${gingivalCoord} `;
        palatalProbingPoints += `${x},${probingCoord} `;
      }
    }

    return {
      palatalGingivalPoints: palatalGingivalPoints.trim(),
      palatalProbingPoints: palatalProbingPoints.trim(),
    };
  };

  const calculateLingualPoints = (teeth) => {
    let lingualGingivalPoints = "";
    let lingualProbingPoints = "";

    const toothWidth = 55; // Set max width to 55px
    const maxDepth = 10; // Maximum depth you expect for gingival margin or probing depth
    const scaleY = svgHeight / maxDepth; // Calculate a scaling factor based on svgHeight

    // Iterate over all teeth and calculate continuous points for gingival and probing depths
    for (let tIndex = 0; tIndex < teeth.length; tIndex++) {
      const tooth = teeth[tIndex];

      for (let i = 0; i < tooth.lingual.gingivalMargin.length; i++) {
        const x =
          tIndex * toothWidth +
          (i / (tooth.lingual.gingivalMargin.length - 1)) * toothWidth; // Continuous X-axis across all teeth
        const gingivalY = tooth.lingual.gingivalMargin[i];
        const probingY = gingivalY + tooth.lingual.probingDepth[i];

        // Scale the Y values and reverse the Y-axis to fit within the svgHeight
        const gingivalCoord = svgHeight - gingivalY * scaleY;
        const probingCoord = svgHeight - probingY * scaleY;

        lingualGingivalPoints += `${x},${gingivalCoord} `;
        lingualProbingPoints += `${x},${probingCoord} `;
      }
    }

    return {
      lingualGingivalPoints: lingualGingivalPoints.trim(),
      lingualProbingPoints: lingualProbingPoints.trim(),
    };
  };

  const {
    buccalGingivalPoints: buccalGingivalPoints1,
    buccalProbingPoints: buccalProbingPoints1,
  } = calculateBuccalPoints(teeth1);
  const {
    buccalGingivalPoints: buccalGingivalPoints2,
    buccalProbingPoints: buccalProbingPoints2,
  } = calculateBuccalPoints(teeth2);

  const {
    palatalGingivalPoints: palatalGingivalPoints1,
    palatalProbingPoints: palatalProbingPoints1,
  } = calculatePalatalPoints(teeth1);
  const {
    palatalGingivalPoints: palatalGingivalPoints2,
    palatalProbingPoints: palatalProbingPoints2,
  } = calculatePalatalPoints(teeth2);

  const {
    lingualGingivalPoints: lingualGingivalPoints1,
    lingualProbingPoints: lingualProbingPoints1,
  } = calculateLingualPoints(teeth3);
  const {
    lingualGingivalPoints: lingualGingivalPoints2,
    lingualProbingPoints: lingualProbingPoints2,
  } = calculateLingualPoints(teeth4);

  const {
    buccalGingivalPoints: buccalGingivalPoints3,
    buccalProbingPoints: buccalProbingPoints3,
  } = calculateBuccalPoints(teeth3);
  const {
    buccalGingivalPoints: buccalGingivalPoints4,
    buccalProbingPoints: buccalProbingPoints4,
  } = calculateBuccalPoints(teeth4);

  const startRecording = () => {
    // Generate a unique `uid` at the start of the recording session
    setAudio(null);
    const id = uuidv4();
    setUid(id);
    chunkOrderRef.current = 0;
    Mp3Recorder.start()
      .then(() => {
        setIsRecording(true);
        // Start streaming chunks
        streamIntervalRef.current = setInterval(() => {
          sendAudioChunk(null, true, id, chunkOrderRef.current++);
        }, 5000); // Increment chunk order after each chunk is sent
      })
      .catch((e) => console.error("Error starting recording:", e));
  };

  const stopRecording = () => {
    clearInterval(streamIntervalRef.current); // Stop streaming
    streamIntervalRef.current = null;
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        setIsRecording(false);
        // Save the final chunk for playback
        audioChunksRef.current.push(blob);
        // Combine all chunks and prepare for playback
        // const completeBlob = new Blob(audioChunksRef.current, {
        //   type: "audio/mp3",
        // });
        // const audioUrl = URL.createObjectURL(completeBlob);
        // setAudio(audioUrl);
        // console.log("Playback URL:1", audioUrl); // You can set this URL to an <audio> element
        sendAudioChunk(blob, false, uid, chunkOrderRef.current++); // Send the final chunk
        playAudio();
      })
      .catch((e) => console.error("Error stopping recording:", e));
  };

  const sendAudioChunk = async (
    chunk = null,
    isStreaming = true,
    recordingUid,
    chunkOrder
  ) => {
    try {
      let audioBlob = chunk;
      // If no chunk provided, retrieve a fresh chunk
      if (!audioBlob) {
        const [buffer, blob] = await Mp3Recorder.stop().getMp3();
        Mp3Recorder.start(); // Restart recording immediately for streaming
        audioBlob = blob;
      }
      // Save chunk for playback
      if (isStreaming) {
        audioChunksRef.current.push(audioBlob);
      }
      const formData = new FormData();
      formData.append("question_audio", audioBlob, "audio.mp3");
      formData.append("uuid_id", recordingUid); // Use the provided `uid`
      formData.append("uuid_bool", isStreaming); // Pass true for streaming, false for final chunk
      // formData.append("chunk_order", chunkOrder); // Pass the order number of the chunk

      const response = await axios.post(
        "https://ffmpeg-app.medscribe.in/transcribe-audio/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      const updatedTeeth = response ?.data ?.transcription ?.periodontalChart;
      // console.log(`Chunk ${chunkOrder} transcription:`, updatedTeeth);
      setTeeth((prevTeeth) =>
        prevTeeth.map((tooth) => {
          // Find the corresponding updated tooth from the response
          const updatedTooth = updatedTeeth ?.find(
            (update) => update.toothId == tooth.toothId
          );

          // If there's no updated data for this tooth, return it as is
          if (!updatedTooth) return tooth;

          // Merge updated tooth data, falling back to default values for missing fields
          return {
            ...tooth,
            buccal: {
              ...tooth.buccal,
              ...updatedTooth.buccal,
              // Ensure all arrays and properties have their default structure
              furcation:
              updatedTooth ?.buccal ?.furcation.length > 0
                ? updatedTooth.buccal.furcation
                : tooth.buccal.furcation,
              bleedOnProbe:
              updatedTooth ?.buccal ?.bleedOnProbe.length > 0
                ? updatedTooth.buccal.bleedOnProbe
                : tooth.buccal.bleedOnProbe,
              plaque:
              updatedTooth ?.buccal ?.plaque.length > 0
                ? updatedTooth.buccal.plaque
                : tooth.buccal.plaque,
              gingivalMargin:
              updatedTooth ?.buccal ?.gingivalMargin.length > 0
                ? updatedTooth.buccal.gingivalMargin
                : tooth.buccal.gingivalMargin,
              probingDepth:
              updatedTooth ?.buccal ?.probingDepth.length > 0
                ? updatedTooth.buccal.probingDepth
                : tooth.buccal.probingDepth,
            },
            palatal: {
              ...tooth.palatal,
              ...updatedTooth.palatal,
              gingivalMargin:
              updatedTooth.palatal ?.gingivalMargin.length > 0
                ? updatedTooth.palatal ?.gingivalMargin
                  : tooth.palatal.gingivalMargin,
              probingDepth:
              updatedTooth.palatal ?.probingDepth.length > 0
                ? updatedTooth.palatal ?.probingDepth
                  : tooth.palatal.probingDepth,
              plaque:
              updatedTooth.palatal ?.plaque.length > 0
                ? updatedTooth.palatal ?.plaque
                  : tooth.palatal.plaque,
              bleedOnProbe:
              updatedTooth.palatal ?.bleedOnProbe.length > 0
                ? updatedTooth.palatal ?.bleedOnProbe
                  : tooth.palatal.bleedOnProbe,
              furcation:
              updatedTooth.palatal ?.furcation.length > 0
                ? updatedTooth.palatal ?.furcation
                  : tooth.palatal.furcation,
              notes: updatedTooth.palatal ?.notes || tooth.palatal.notes,
            },
            lingual: {
              ...tooth.lingual,
              ...updatedTooth.lingual,
              gingivalMargin:
              updatedTooth.lingual ?.gingivalMargin.length > 0
                ? updatedTooth.lingual ?.gingivalMargin
                  : tooth.lingual.gingivalMargin,
              probingDepth:
              updatedTooth.lingual ?.probingDepth.length > 0
                ? updatedTooth.lingual ?.probingDepth
                  : tooth.lingual.probingDepth,
              plaque:
              updatedTooth.lingual ?.plaque.length > 0
                ? updatedTooth.lingual ?.plaque
                  : tooth.lingual.plaque,
              bleedOnProbe:
              updatedTooth.lingual ?.bleedOnProbe.length > 0
                ? updatedTooth.lingual ?.bleedOnProbe
                  : tooth.lingual.bleedOnProbe,
              furcation:
              updatedTooth.lingual ?.furcation.length > 0
                ? updatedTooth.lingual ?.furcation
                  : tooth.lingual.furcation,
              notes: updatedTooth.lingual ?.notes || tooth.lingual.notes,
            },
          };
        })
      );
    } catch (error) {
      console.error("Error sending audio chunk:", error);
    }
  };

  const handleInputChange = (index, region, field, value, position) => {
    setTeeth((prevTeeth) => {
      // Create a shallow copy of the teeth array
      const updatedTeeth = [...prevTeeth];

      // Check if the field is an array (like 'gingivalMargin')
      if (Array.isArray(updatedTeeth[index][region][field])) {
        // Create a shallow copy of the specific array to update
        const updatedArray = [...updatedTeeth[index][region][field]];

        // Update the value at the specific position in the array
        updatedArray[position] = value;

        // Update the field with the new array
        updatedTeeth[index] = {
          ...updatedTeeth[index],
          [region]: { ...updatedTeeth[index][region], [field]: updatedArray },
        };
      } else {
        // If the field isn't an array, directly update the field
        updatedTeeth[index] = {
          ...updatedTeeth[index],
          [region]: { ...updatedTeeth[index][region], [field]: value },
        };
      }

      return updatedTeeth;
    });
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth <= 800) {
  //       setWidth(window.innerWidth * 1);
  //     }
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  // const calculatePoints = (teethData, startOffset = 0) => {
  //   return teethData.flatMap((tooth, index) => {
  //     const xSpacing = width / (teethData.length * tooth.gingival.length);
  //     return tooth.gingival.map((gin, ginIndex) => [
  //       xSpacing * (index * tooth.gingival.length + ginIndex) + startOffset,
  //       height - (gin / maxVal) * height,
  //     ]);
  //   });
  // };

  // const polylinePoints = calculatePoints(teeth);

  // const polylinePoints1 = calculatePoints(teeth1);
  // const polylinePointsStr = polylinePoints.map((p) => p.join(",")).join(" ");
  // const polylinePoints1Str = polylinePoints1.map((p) => p.join(",")).join(" ");

  const handleAudioFile = async (blob) => {
    const formData = new FormData();
    formData.append("file_name", `audio_files/${patientId}_${Date.now()}.mp3`);
    formData.append("audio_file", blob, `${patientId}.mp3`);

    console.log(formData, "FORMDATA");

    try {
      const response = await fetch(
        `${configJson.backend_URL}/soapPrompt/upload`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        const url = result.gcs_uri;
        await updatedata(url);
      }
    } catch (error) {
      console.error("Error uploading audio:", error);
    }
  };

  const playAudio = () => {
    if (audioChunksRef.current && audioChunksRef.current.length > 0) {
      console.log("Merging blobs...");

      // Merge all chunks into a single Blob
      const mergedBlob = new Blob(audioChunksRef.current, {
        type: "audio/mp3",
      });
      const url = URL.createObjectURL(mergedBlob);
      setAudioUrl(url);

      // Pass the mergedBlob to handleAudioFile
      handleAudioFile(mergedBlob);
      audioChunksRef.current = []
    } else {
      console.warn("No audio chunks available");
    }
  };

  async function updatedata(url) {
    // console.log("KKk");
    const mydata = {
      patient_id: patientId,
      doctor_id: decode ?.doctor_id,
      interaction_date: Date.now(),
      interaction_id: interactionIdFirst,
      organization_id: decode ?.organization_id,
      audioUrl: url,
      // types: decode.role,
    };
    try {
      const response = await axios.post(
        `${configJson.backend_URL}/interaction/feedRecorder`,
        mydata,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // console.log(response);
      // if (response.data.status === true) {
      //   // console.log("Audio upload success full");
      //   dispatch(addPatientToList(response?.data?.result));
      //   const changeStatus_color = {
      //     patient_id: response?.data?.result?.patient_id,
      //     interaction_status: "0",
      //   };
      //   dispatch(updatePatientStatus(changeStatus_color));
      //   checkInteractionStatus(response?.data?.result?.interaction_id);
      // }
    } catch (error) {
      console.error("Error:", error);
      // setFile([]);
    } finally {
      // setLoading(false);
      // if (fetchInteractions) {
      //   fetchInteractions(patientId);
      // }
      // setFile([]);
      localStorage.removeItem("interactionIdFirst");
    }
  }

  // console.log(audioUrl, 'url')

  return (
    <>
    {/* <Stream /> */ }
    < Streamer
        isRecording= { isRecording }
  startRecording = { startRecording }
  stopRecording = { stopRecording }
    />
    <div className="p-4">
      <div className="flex flex-col sm:flex-row items-center justify-between mb-2">
        <h1 className="text-xl sm:text-3xl font-bold mb-4">
          PERIODONTAL CHART
          </h1>
        <div className="flex items-center gap-2">
          <label className="block text-sm font-medium">Date</label>
          <div className="p-2 py-1 border rounded-md shadow-sm border-blue-500">
            {onlyDate(Date.now())}
          </div>
        </div>
      </div>
      {/* <div>
          <button onClick={playAudio}>PPPP</button>
      {audioUrl && (
        <audio controls>
          <source src={audioUrl} type="audio/mp3" />
          Your browser does not support the audio tag.
        </audio>
      )}
    </div> */}

      {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div className="flex items-center gap-2">
            <label className="block text-sm font-medium">
              Patient Last Name
            </label>
            <input
              type="text"
              className="p-2 py-1 w-1/2 border rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500"
            />
          </div>
          <div className="flex items-center gap-2">
            <label className="block text-sm font-medium">First Name</label>
            <input
              type="text"
              className="p-2 py-1 w-1/2 border rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500"
            />
          </div>
          <div className="flex items-center gap-2">
            <label className="block text-sm font-medium">Date Of Birth</label>
            <input
              type="text"
              className="p-2 py-1 w-1/2 border rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500"
            />
          </div>
        </div> */}
      {/* <div className="flex flex-col md:flex-row justify-between items-start mb-4">
          <div className="flex items-center gap-6">
            <div className="flex items-center space-x-2 mb-2 md:mb-0">
              <input
                type="checkbox"
                id="initialExam"
                className="border-gray-300 rounded focus:ring focus:ring-blue-200 focus:border-blue-500"
              />
              <label htmlFor="initialExam" className="text-sm font-medium">
                Initial Exam
              </label>
            </div>

            <div className="flex items-center space-x-2 mb-2 md:mb-0">
              <input
                type="checkbox"
                id="re-evaluation"
                className="border-gray-300 rounded focus:ring focus:ring-blue-200 focus:border-blue-500"
              />
              <label htmlFor="reevaluation" className="text-sm font-medium">
                Reevaluation
              </label>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <label className="block text-sm font-medium">Clinician</label>
            <input
              type="text"
              className="p-2 py-1 w-full md:w-auto border rounded-md shadow-sm focus:ring focus:ring-blue-200 focus:border-blue-500"
            />
          </div>
        </div> */}
    </div>
    <div className="overflow-x-auto">
      <div className="w-[720px] md:w-full">
        <div className="text-xs">
          <div className="h-[250px] md:w-full flex items-center gap-6">
            {/* Table-1 */}
            <table className="w-full">
              <thead>
                <tr>
                  <th className="border-none">{"  "}</th>
                  {teeth1.map((tooth, index) => (
                    <th key={index}>{tooth.toothId}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="!border-none text-right">Mobility</td>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        value={tooth.buccal.mobility || 0}
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                        defaultValue={0}
                      // min={0}
                      // max={20}
                      // onChange={(e) =>
                      //   handleInputChange(
                      //     index,
                      //     "mobility",
                      //     parseInt(e.target.value, 10)
                      //   )
                      // }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Implant</td>
                  {teeth1.map((tooth, index) => (
                    <td
                      key={index}
                      className="p-1"
                    // onClick={() =>
                    //   handleInputChange(index, "implant", !tooth.implant)
                    // }
                    >
                      <div className="bg-gray-200">
                        <div
                          className={`m-auto size-3 ${
                            tooth.buccal.isImplant ? "bg-black" : ""
                            }`}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Furcation</td>
                  {teeth1.map((tooth, index) => {
                    if (index > 2 || tooth.buccal.isImplant)
                      return <td key={index}>-</td>;
                    return (
                      <td key={index}>
                        <div className="bg-gray-200">
                          {tooth ?.buccal.furcation != null ? (
                            <div className="w-[14px] m-auto z-40">
                              {tooth ?.buccal ?.furcation[0] === 1 && (
                                <img src={`/dental/nulll.png`} />
                              )}
                              {tooth ?.buccal ?.furcation[0] === 2 && (
                                <img src={`/dental/half.png`} />
                              )}
                              {tooth ?.buccal ?.furcation[0] === 3 && (
                                <img src={`/dental/full.png`} />
                              )}
                            </div>
                          ) : (
                              <div>-</div>
                            )}
                        </div>
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  <td className="!border-none text-right">Bleeding</td>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.buccal.bleedOnProbe.map(
                          (bleed, bleedIndex) => (
                            <button
                              // onClick={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "bleeding",
                              //     !bleed,
                              //     bleedIndex
                              //   )
                              // }
                              className={`${
                                bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                            ></button>
                          )
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Plaque</td>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.buccal.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                          // onClick={() =>
                          //   handleInputChange(index, "plaque", !pl, plIndex)
                          // }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">GingivalMargin</td>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.buccal.gingivalMargin.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "buccal",
                                "gingivalMargin",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">ProbingDepth</td>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.buccal.probingDepth.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "buccal",
                                "probingDepth",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>

            {/* Table-2 */}
            <table className="w-[85%] h-[240px]">
              <thead>
                <tr>
                  {teeth2.map((tooth, index) => (
                    <th key={index}>{tooth.toothId}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        value={tooth.buccal.mobility || 0}
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                      // min={0}
                      // max={20}
                      // onChange={(e) =>
                      //   handleInputChange(
                      //     index,
                      //     "mobility",
                      //     parseInt(e.target.value, 10)
                      //   )
                      // }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth2.map((tooth, index) => (
                    <td
                      key={index}
                      className="p-1"
                    // onClick={() =>
                    //   handleInputChange(index, "implant", !tooth.implant)
                    // }
                    >
                      <div className="bg-gray-200">
                        <div
                          className={`m-auto size-3 ${
                            tooth.buccal.isImplant ? "bg-black" : ""
                            }`}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth2.map((tooth, index) => {
                    if (index < 5 || tooth.buccal.isImplant)
                      return <td key={index}>-</td>;
                    return (
                      <td key={index}>
                        <div className="bg-gray-200">
                          {tooth ?.buccal.furcation != null ? (
                            <div className="w-[14px] m-auto z-40">
                              {tooth ?.buccal ?.furcation[0] === 1 && (
                                <img src={`/dental/nulll.png`} />
                              )}
                              {tooth ?.buccal ?.furcation[0] === 2 && (
                                <img src={`/dental/half.png`} />
                              )}
                              {tooth ?.buccal ?.furcation[0] === 3 && (
                                <img src={`/dental/full.png`} />
                              )}
                            </div>
                          ) : (
                              <div>-</div>
                            )}
                        </div>
                      </td>
                    );
                  })}
                </tr>

                <tr>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.buccal.bleedOnProbe.map(
                          (bleed, bleedIndex) => (
                            <button
                              // onClick={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "bleeding",
                              //     !bleed,
                              //     bleedIndex
                              //   )
                              // }
                              className={`${
                                bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                            ></button>
                          )
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.buccal.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                          // onClick={() =>
                          //   handleInputChange(index, "plaque", !pl, plIndex)
                          // }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.buccal.gingivalMargin.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin || 0}
                            onChange={(e) =>
                              handleInputChange(
                                index + 8,
                                "buccal",
                                "gingivalMargin",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.buccal.probingDepth.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob || 0}
                            onChange={(e) =>
                              handleInputChange(
                                index + 8,
                                "buccal",
                                "probingDepth",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex items-center mt-2 relative">
            <div className="text-xl font-semibold mr-4">Buccal</div>
            <div className="flex items-center justify-around relative gap-6 mt-4 !w-full">
              {/* Image-1 */}
              <div className="flex items-center ml-3">
                {teeth1.map((tooth, index) => {
                  return (
                    <div
                      key={index}
                      className="relative !w-[53px] lgl:!w-[55px]"
                    >
                      <img
                        src={
                          tooth.buccal.isImplant
                            ? `/dental/table1/implant/periodontograma-dientes-arriba-tornillo-${
                            18 - index
                            }.png`
                            : `/dental/table1/periodontograma-dientes-arriba-${
                            18 - index
                            }.png`
                        }
                        className="!w-[53px] lgl:!w-[55px] h-[80px] md:h-[120px]"
                      />
                      {tooth ?.buccal ?.furcation != null && (
                        <div className="absolute top-12 left-5 w-4 z-40">
                          {tooth ?.buccal ?.furcation[0] === 1 && (
                            <img src={`/dental/nulll.png`} />
                          )}
                          {tooth ?.buccal ?.furcation[0] === 2 && (
                            <img src={`/dental/half.png`} />
                          )}
                          {tooth ?.buccal ?.furcation[0] === 3 && (
                            <img src={`/dental/full.png`} />
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
                <svg
                  width={svgWidth}
                  height={svgHeight}
                  viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                  className="absolute top-10 z-50"
                >
                  <polyline
                    points={buccalGingivalPoints1}
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                  // transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                  <polyline
                    points={buccalProbingPoints1}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                  // transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                </svg>
              </div>

              {/* Image-2 */}
              <div className="flex items-center mt-2">
                {teeth2.map((tooth, index) => {
                  return (
                    <div className="relative !w-[53px] lgl:!w-[55px]">
                      <img
                        key={index}
                        src={
                          tooth.implant
                            ? `/dental/table2/implant/periodontograma-dientes-arriba-tornillo-${
                            21 + index
                            }.png`
                            : `/dental/table2/periodontograma-dientes-arriba-${
                            21 + index
                            }.png`
                        }
                        className="!w-[53px] lgl:!w-[55px] h-[80px] md:h-[120px]"
                      />
                      {tooth ?.furcation != null && (
                        <img
                          src={`/dental/${
                            tooth.furcation === 1
                              ? "nulll"
                              : tooth.furcation === 2
                                ? "half"
                                : "full"
                            }.png`}
                          className="absolute top-12 left-5 w-4 z-40"
                        />
                      )}
                    </div>
                  );
                })}
                <svg
                  width={svgWidth}
                  height={svgHeight}
                  viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                  className="absolute top-10 z-50"
                >
                  <polyline
                    points={buccalGingivalPoints2}
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                  // transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                  <polyline
                    points={buccalProbingPoints2}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                  // transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                </svg>
              </div>

              <svg
                width={width} // Use dynamic width for both SVGs
                height={height}
                className="absolute left-8 bottom-10"
              >
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}

                <polyline
                  // points={polylinePointsStr}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
              </svg>

              <svg
                width={width}
                height={height}
                className="absolute right-5 bottom-10"
              >
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}

                <polyline
                  // points={polylinePoints1Str}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="text-xs">
          <div className="flex items-center mt-2 relative">
            <div className="text-xl font-semibold mr-4">Palatal</div>
            <div className="flex items-center justify-around relative gap-6 !w-full">
              {/* Image- 3 */}
              <div className="flex items-center ml-6">
                {teeth1.map((tooth, index) => (
                  <div className="relative !w-[53px] lgl:!w-[55px]">
                    <img
                      key={index}
                      src={
                        tooth.buccal.isImplant
                          ? `/dental/table3/implant/periodontograma-dientes-arriba-tornillo-${
                          18 - index
                          }b.png`
                          : `/dental/table3/periodontograma-dientes-arriba-${
                          18 - index
                          }b.png`
                      }
                      className="!w-[53px] lgl:!w-[55px] h-[80px] md:h-[120px]"
                    />
                    {tooth ?.palatal ?.furcation != null && (
                        <>
                      <div className="absolute top-12 left-1 w-4 z-40">
                        {tooth ?.palatal ?.furcation[0] == 1 && (
                          <img src={`/dental/nulll.png`} />
                        )}
                        {tooth ?.palatal ?.furcation[0] == 2 && (
                          <img src={`/dental/half.png`} />
                        )}
                        {tooth ?.palatal ?.furcation[0] == 3 && (
                          <img src={`/dental/full.png`} />
                        )}
                      </div>
                      <div className="absolute top-11 right-2 w-4 z-40">
                        {tooth ?.palatal ?.furcation[1] == 1 && (
                          <img src={`/dental/nulll.png`} />
                        )}
                        {tooth ?.palatal ?.furcation[1] == 2 && (
                          <img src={`/dental/half.png`} />
                        )}
                        {tooth ?.palatal ?.furcation[1] == 3 && (
                          <img src={`/dental/full.png`} />
                        )}
                      </div>
                        </>
                      )}
                    </div>
                ))}
                <svg
                  width={svgWidth}
                  height={svgHeight}
                  viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                  className="absolute -top-2 z-50"
                >
                  <polyline
                    points={palatalGingivalPoints1}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                  // transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                  <polyline
                    points={palatalProbingPoints1}
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                  // transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                </svg>
              </div>

              {/* Image-4 */}
              <div className="flex items-center mr-6">
                {teeth2.map((tooth, index) => (
                  <div className="relative !w-[53px] lgl:!w-[55px]">
                    <img
                      key={index}
                      src={
                        tooth.buccal.isImplant
                          ? `/dental/table4/implant/periodontograma-dientes-arriba-tornillo-${
                          21 + index
                          }b.png`
                          : `/dental/table4/periodontograma-dientes-arriba-${
                          21 + index
                          }b.png`
                      }
                      className="!w-[53px] lgl:!w-[55px] h-[80px] md:h-[120px]"
                    />
                    {tooth ?.palatal ?.furcation != null && (
                        <>
                      <div className="absolute top-12 left-1 w-4 z-40">
                        {tooth ?.palatal ?.furcation[0] == 1 && (
                          <img src={`/dental/nulll.png`} />
                        )}
                        {tooth ?.palatal ?.furcation[0] == 2 && (
                          <img src={`/dental/half.png`} />
                        )}
                        {tooth ?.palatal ?.furcation[0] == 3 && (
                          <img src={`/dental/full.png`} />
                        )}
                      </div>
                      <div className="absolute top-11 right-2 w-4 z-40">
                        {tooth ?.palatal ?.furcation[1] == 1 && (
                          <img src={`/dental/nulll.png`} />
                        )}
                        {tooth ?.palatal ?.furcation[1] == 2 && (
                          <img src={`/dental/half.png`} />
                        )}
                        {tooth ?.palatal ?.furcation[1] == 3 && (
                          <img src={`/dental/full.png`} />
                        )}
                      </div>
                        </>
                      )}
                    </div>
                ))}
                <svg
                  width={svgWidth}
                  height={svgHeight}
                  viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                  className="absolute -top-2 z-50"
                >
                  <polyline
                    points={palatalGingivalPoints2}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                  // transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                  <polyline
                    points={palatalProbingPoints2}
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                  // transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                </svg>
              </div>

              <svg
                width={width} // Use dynamic width for both SVGs
                height={height}
                className="absolute left-8 top-8"
              >
                <polyline
                  // points={polylinePointsStr}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}
              </svg>

              <svg
                width={width}
                height={height}
                className="absolute right-8 top-8"
              >
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}

                <polyline
                  // points={polylinePoints1Str}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
          <div className="h-[250px] md:w-full flex items-center gap-6">
            {/* Table- 3 */}
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="!border-none text-right">GingivalMargin</td>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.palatal.gingivalMargin.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "palatal",
                                "gingivalMargin",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">ProbingDepth</td>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.palatal.probingDepth.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "palatal",
                                "probingDepth",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Plaque</td>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.palatal.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                          // onClick={() =>
                          //   handleInputChange(index, "plaque", !pl, plIndex)
                          // }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="!border-none text-right">Bleeding</td>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.palatal.bleedOnProbe.map(
                          (bleed, bleedIndex) => (
                            <button
                              // onClick={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "bleeding",
                              //     !bleed,
                              //     bleedIndex
                              //   )
                              // }
                              className={`${
                                bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                            ></button>
                          )
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Furcation</td>
                  {teeth1.map((tooth, index) => {
                    if (index == 3 || index > 4 || tooth.buccal.isImplant)
                      return <td key={index}></td>;
                    return (
                      <td key={index}>
                        <div className="flex items-center gap-1">
                          <div className="bg-gray-200 w-1/2">
                            {tooth ?.palatal.furcation != null ? (
                              <div className="w-[14px] m-auto z-40">
                                {tooth ?.palatal ?.furcation[0] === 0 && (
                                  <div>-</div>
                                )}
                                {tooth ?.palatal ?.furcation[0] === 1 && (
                                  <img src={`/dental/nulll.png`} />
                                )}
                                {tooth ?.palatal ?.furcation[0] === 2 && (
                                  <img src={`/dental/half.png`} />
                                )}
                                {tooth ?.palatal ?.furcation[0] === 3 && (
                                  <img src={`/dental/full.png`} />
                                )}
                              </div>
                            ) : (
                                <div>-</div>
                              )}
                          </div>
                          <div className="bg-gray-200 w-1/2">
                            {tooth ?.palatal.furcation != null ? (
                              <div className="w-[14px] m-auto z-40">
                                {tooth ?.palatal ?.furcation[1] === 0 && (
                                  <div>-</div>
                                )}
                                {tooth ?.palatal ?.furcation[1] === 1 && (
                                  <img src={`/dental/nulll.png`} />
                                )}
                                {tooth ?.palatal ?.furcation[1] === 2 && (
                                  <img src={`/dental/half.png`} />
                                )}
                                {tooth ?.palatal ?.furcation[1] === 3 && (
                                  <img src={`/dental/full.png`} />
                                )}
                              </div>
                            ) : (
                                <div>-</div>
                              )}
                          </div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td className="!border-none text-right">Note</td>
                  {teeth1.map((tooth, index) => (
                    <td key={index}>
                      <div>{tooth.palatal.notes || "-"}</div>
                      {/* <Input
                          type="text"
                          className="w-1/2 p-0 outline-none block m-auto text-center"
                          value=
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "furcation",
                              e.target.value
                            )
                          }
                        /> */}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
            {/* Table- 4 */}
            <table className="w-[85%] h-[175px]">
              <tbody>
                <tr>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.palatal.gingivalMargin.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange(
                                index + 8,
                                "palatal",
                                "gingivalMargin",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.palatal.probingDepth.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange(
                                index + 8,
                                "palatal",
                                "probingDepth",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-1 justify-center">
                        {tooth.palatal.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                          // onClick={() =>
                          //   handleInputChange(
                          //     index,
                          //     "plaque",
                          //     !pl,
                          //     plIndex
                          //   )
                          // }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-1 justify-center">
                        {tooth.palatal.bleedOnProbe.map(
                          (bleed, bleedIndex) => (
                            <button
                              // onClick={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "bleeding",
                              //     !bleed,
                              //     bleedIndex
                              //   )
                              // }
                              className={`${
                                bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                            ></button>
                          )
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth2.map((tooth, index) => {
                    if (index < 3 || index == 4 || tooth.buccal.isImplant)
                      return <td key={index}></td>;
                    return (
                      <td key={index}>
                        <div className="flex items-center gap-1">
                          <div className="bg-gray-200 w-1/2">
                            {tooth ?.palatal.furcation != null ? (
                              <div className="w-[14px] m-auto z-40">
                                {tooth ?.palatal ?.furcation[0] === 0 && (
                                  <div>-</div>
                                )}
                                {tooth ?.palatal ?.furcation[0] === 1 && (
                                  <img src={`/dental/nulll.png`} />
                                )}
                                {tooth ?.palatal ?.furcation[0] === 2 && (
                                  <img src={`/dental/half.png`} />
                                )}
                                {tooth ?.palatal ?.furcation[0] === 3 && (
                                  <img src={`/dental/full.png`} />
                                )}
                              </div>
                            ) : (
                                <div>-</div>
                              )}
                          </div>
                          <div className="bg-gray-200 w-1/2">
                            {tooth ?.palatal.furcation != null ? (
                              <div className="w-[14px] m-auto z-40">
                                {tooth ?.palatal ?.furcation[1] === 0 && (
                                  <div>-</div>
                                )}
                                {tooth ?.palatal ?.furcation[1] === 1 && (
                                  <img src={`/dental/nulll.png`} />
                                )}
                                {tooth ?.palatal ?.furcation[1] === 2 && (
                                  <img src={`/dental/half.png`} />
                                )}
                                {tooth ?.palatal ?.furcation[1] === 3 && (
                                  <img src={`/dental/full.png`} />
                                )}
                              </div>
                            ) : (
                                <div>-</div>
                              )}
                          </div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  {teeth2.map((tooth, index) => (
                    <td key={index}>
                      <div>{tooth.palatal.notes || "-"}</div>
                      {/* <Input
                          type="text"
                          className="w-1/2 p-0 outline-none block m-auto text-center"
                          value={tooth.palatal.notes || '-'}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "furcation",
                              e.target.value
                            )
                          }
                        /> */}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="!w-[720px] md:!w-full">
        <div className="text-xs">
          <div className="h-[250px] md:w-full flex items-center gap-6">
            {/* Table-5 */}
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="!border-none text-right">Note</td>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <div>{tooth.lingual.notes || "-"}</div>
                      {/* <Input
                          type="text"
                          className="w-1/2 p-0 outline-none block m-auto text-center"
                          value=
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "furcation",
                              e.target.value
                            )
                          }
                        /> */}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Furcation</td>
                  {teeth3.map((tooth, index) => {
                    if (index == 3 || index > 4 || tooth.buccal.isImplant)
                      return <td key={index}>-</td>;
                    return (
                      <td key={index}>
                        <div className="bg-gray-200">
                          {tooth ?.lingual.furcation != null ? (
                            <div className="w-[14px] m-auto z-40">
                              {tooth ?.lingual ?.furcation[0] === 1 && (
                                <img src={`/dental/nulll.png`} />
                              )}
                              {tooth ?.lingual ?.furcation[0] === 2 && (
                                <img src={`/dental/half.png`} />
                              )}
                              {tooth ?.lingual ?.furcation[0] === 3 && (
                                <img src={`/dental/full.png`} />
                              )}
                            </div>
                          ) : (
                              <div>-</div>
                            )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td className="!border-none text-right">Bleeding</td>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.lingual.bleedOnProbe.map(
                          (bleed, bleedIndex) => (
                            <button
                              // onClick={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "bleeding",
                              //     !bleed,
                              //     bleedIndex
                              //   )
                              // }
                              className={`${
                                bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                            ></button>
                          )
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Plaque</td>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.lingual.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                          // onClick={() =>
                          //   handleInputChange(index, "plaque", !pl, plIndex)
                          // }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">GingivalMargin</td>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.lingual.gingivalMargin.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange(
                                index + 16,
                                "lingual",
                                "gingivalMargin",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">ProbingDepth</td>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.lingual.probingDepth.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange(
                                index + 16,
                                "lingual",
                                "probingDepth",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>

            {/* Table-6 */}
            <table className="w-[85%] h-[175px]">
              <tbody>
                <tr>
                  {teeth4.map((tooth, index) => (
                    <td key={index}>
                      <div>{tooth.lingual.notes || "-"}</div>
                      {/* <Input
                          type="text"
                          className="w-1/2 p-0 outline-none block m-auto text-center"
                          value={tooth.lingual.notes || '-'}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "furcation",
                              e.target.value
                            )
                          }
                        /> */}
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth4.map((tooth, index) => {
                    if (index < 3 || index == 4 || tooth.buccal.isImplant)
                      return <td key={index}>-</td>;
                    return (
                      <td key={index}>
                        <div className="bg-gray-200">
                          {tooth ?.lingual.furcation != null ? (
                            <div className="w-[14px] m-auto z-40">
                              {tooth ?.lingual ?.furcation[0] === 1 && (
                                <img src={`/dental/nulll.png`} />
                              )}
                              {tooth ?.lingual ?.furcation[0] === 2 && (
                                <img src={`/dental/half.png`} />
                              )}
                              {tooth ?.lingual ?.furcation[0] === 3 && (
                                <img src={`/dental/full.png`} />
                              )}
                            </div>
                          ) : (
                              <div>-</div>
                            )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  {teeth4.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-1 justify-center">
                        {tooth.lingual.bleedOnProbe.map(
                          (bleed, bleedIndex) => (
                            <button
                              // onClick={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "bleeding",
                              //     !bleed,
                              //     bleedIndex
                              //   )
                              // }
                              className={`${
                                bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                            ></button>
                          )
                        )}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth4.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-1 justify-center">
                        {tooth.lingual.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                          // onClick={() =>
                          //   handleInputChange(
                          //     index,
                          //     "plaque",
                          //     !pl,
                          //     plIndex
                          //   )
                          // }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth4.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.lingual.gingivalMargin.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange(
                                index + 24,
                                "lingual",
                                "gingivalMargin",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth4.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.lingual.probingDepth.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange(
                                index + 24,
                                "lingual",
                                "probingDepth",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="flex items-center mt-2 relative">
            <div className="text-xl font-semibold mr-4">Lingual</div>
            <div className="flex items-center justify-around relative gap-6 !w-full">
              {/* Image-5 */}
              <div className="flex items-center ml-3">
                {teeth3.map((tooth, index) => (
                  <div className="relative !w-[53px] lgl:!w-[55px]">
                    <img
                      key={index}
                      src={
                        tooth.buccal.isImplant
                          ? `/dental/table5/implant/periodontograma-dientes-abajo-tornillo-${
                          48 - index
                          }.png`
                          : `/dental/table5/periodontograma-dientes-abajo-${
                          48 - index
                          }.png`
                      }
                      className="!w-[53px] lgl:!w-[55px] h-[80px] md:h-[120px]"
                    />
                    {tooth ?.lingual ?.furcation != null && (
                      <div className="absolute top-12 left-5 w-4 z-40">
                        {tooth ?.lingual ?.furcation[0] === 1 && (
                          <img src={`/dental/nulll.png`} />
                        )}
                        {tooth ?.lingual ?.furcation[0] === 2 && (
                          <img src={`/dental/half.png`} />
                        )}
                        {tooth ?.lingual ?.furcation[0] === 3 && (
                          <img src={`/dental/full.png`} />
                        )}
                      </div>
                    )}
                  </div>
                ))}
                <svg
                  width={svgWidth}
                  height={svgHeight}
                  viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                  className="absolute bottom-2 z-50"
                >
                  <polyline
                    points={lingualGingivalPoints1}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                    transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                  <polyline
                    points={lingualProbingPoints1}
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                    transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                </svg>
              </div>

              {/* Image-6 */}
              <div className="flex items-center mt-2">
                {teeth4.map((tooth, index) => (
                  <div className="relative !w-[53px] lgl:!w-[55px]">
                    <img
                      key={index}
                      src={
                        tooth.buccal.isImplant
                          ? `/dental/table6/implant/periodontograma-dientes-abajo-tornillo-${
                          31 + index
                          }.png`
                          : `/dental/table6/periodontograma-dientes-abajo-${
                          31 + index
                          }.png`
                      }
                      className="!w-[53px] lgl:!w-[55px] h-[80px] md:h-[120px]"
                    />
                    {tooth ?.lingual ?.furcation != null && (
                      <div className="absolute top-12 left-5 w-4 z-40">
                        {tooth ?.lingual ?.furcation[0] === 1 && (
                          <img src={`/dental/nulll.png`} />
                        )}
                        {tooth ?.lingual ?.furcation[0] === 2 && (
                          <img src={`/dental/half.png`} />
                        )}
                        {tooth ?.lingual ?.furcation[0] === 3 && (
                          <img src={`/dental/full.png`} />
                        )}
                      </div>
                    )}
                  </div>
                ))}
                <svg
                  width={svgWidth}
                  height={svgHeight}
                  viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                  className="absolute bottom-2 z-50"
                >
                  <polyline
                    points={lingualGingivalPoints2}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                    transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                  <polyline
                    points={lingualProbingPoints2}
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                    transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                </svg>
              </div>

              <svg
                width={width} // Use dynamic width for both SVGs
                height={height}
                className="absolute left-8 bottom-10"
              >
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}

                <polyline
                  // points={polylinePointsStr}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
              </svg>

              <svg
                width={width}
                height={height}
                className="absolute right-5 bottom-10"
              >
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}

                <polyline
                  // points={polylinePoints1Str}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="text-xs">
          <div className="flex items-center mt-2 relative">
            <div className="text-xl font-semibold mr-4">Buccal</div>
            <div className="flex items-center justify-around relative gap-6 !w-full">
              {/* Image- 7 */}
              <div className="flex items-center ml-6">
                {teeth3.map((tooth, index) => (
                  <div className="relative !w-[53px] lgl:!w-[55px]">
                    <img
                      key={index}
                      src={
                        tooth.buccal.isImplant
                          ? `/dental/table7/implant/periodontograma-dientes-abajo-tornillo-${
                          48 - index
                          }b.png`
                          : `/dental/table7/periodontograma-dientes-abajo-${
                          48 - index
                          }b.png`
                      }
                      className="!w-[53px] lgl:!w-[55px] h-[80px] md:h-[120px]"
                    />
                    {tooth ?.buccal ?.furcation != null && (
                        <>
                      <div className="absolute top-12 left-6 w-4 z-40">
                        {tooth ?.buccal ?.furcation[0] == 1 && (
                          <img src={`/dental/nulll.png`} />
                        )}
                        {tooth ?.buccal ?.furcation[0] == 2 && (
                          <img src={`/dental/half.png`} />
                        )}
                        {tooth ?.buccal ?.furcation[0] == 3 && (
                          <img src={`/dental/full.png`} />
                        )}
                      </div>
                        </>
                      )}
                    </div>
                ))}
                <svg
                  width={svgWidth}
                  height={svgHeight}
                  viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                  className="absolute -top-2 z-50"
                >
                  <polyline
                    points={buccalGingivalPoints3}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                  // transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                  <polyline
                    points={buccalProbingPoints3}
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                  // transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                </svg>
              </div>

              {/* Image-8 */}
              <div className="flex items-center mr-6">
                {teeth4.map((tooth, index) => (
                  <div className="relative !w-[53px] lgl:!w-[55px]">
                    <img
                      key={index}
                      src={
                        tooth.buccal.isImplant
                          ? `/dental/table8/implant/periodontograma-dientes-abajo-tornillo-${
                          31 + index
                          }b.png`
                          : `/dental/table8/periodontograma-dientes-abajo-${
                          31 + index
                          }b.png`
                      }
                      className="!w-[53px] lgl:!w-[55px] h-[80px] md:h-[120px]"
                    />
                    {tooth ?.buccal ?.furcation != null && (
                        <>
                      <div className="absolute top-12 left-6 w-4 z-40">
                        {tooth ?.buccal ?.furcation[0] == 1 && (
                          <img src={`/dental/nulll.png`} />
                        )}
                        {tooth ?.buccal ?.furcation[0] == 2 && (
                          <img src={`/dental/half.png`} />
                        )}
                        {tooth ?.buccal ?.furcation[0] == 3 && (
                          <img src={`/dental/full.png`} />
                        )}
                      </div>
                        </>
                      )}
                    </div>
                ))}
                <svg
                  width={svgWidth}
                  height={svgHeight}
                  viewBox={`0 0 ${svgWidth} ${svgHeight}`}
                  className="absolute -top-2 z-50"
                >
                  <polyline
                    points={buccalGingivalPoints4}
                    fill="none"
                    stroke="blue"
                    strokeWidth="2"
                  // transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                  <polyline
                    points={buccalProbingPoints4}
                    fill="none"
                    stroke="red"
                    strokeWidth="2"
                  // transform={`scale(1, -1) translate(0, -${svgHeight})`}
                  />
                </svg>
              </div>

              <svg
                width={width} // Use dynamic width for both SVGs
                height={height}
                className="absolute left-8 top-8"
              >
                <polyline
                  // points={polylinePointsStr}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}
              </svg>

              <svg
                width={width}
                height={height}
                className="absolute right-8 top-8"
              >
                {Array.from({ length: maxVal }).map((_, i) => (
                  <line
                    key={i}
                    x1={0}
                    y1={(i / maxVal) * height}
                    x2={width}
                    y2={(i / maxVal) * height}
                    stroke="#ddd"
                    strokeWidth="2"
                  />
                ))}

                <polyline
                  // points={polylinePoints1Str}
                  fill="none"
                  stroke="blue"
                  strokeWidth="2"
                />
              </svg>
            </div>
          </div>
          <div className="h-[250px] md:w-full flex items-center gap-6 mt-3">
            {/* Table- 7 */}
            <table className="w-full">
              <tbody>
                <tr>
                  <td className="!border-none text-right">GingivalMargin</td>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.buccal.gingivalMargin.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange(
                                index + 16,
                                "buccal",
                                "gingivalMargin",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">ProbingDepth</td>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.buccal.probingDepth.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange(
                                index + 16,
                                "buccal",
                                "probingDepth",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Plaque</td>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.buccal.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                          // onClick={() =>
                          //   handleInputChange(index, "plaque", !pl, plIndex)
                          // }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="!border-none text-right">Bleeding</td>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.buccal.bleedOnProbe.map(
                          (bleed, bleedIndex) => (
                            <button
                              // onClick={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "bleeding",
                              //     !bleed,
                              //     bleedIndex
                              //   )
                              // }
                              className={`${
                                bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                            ></button>
                          )
                        )}
                      </div>
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="!border-none text-right">Furcation</td>
                  {teeth3.map((tooth, index) => {
                    if (index > 2 || tooth.buccal.isImplant)
                      return <td key={index}>-</td>;
                    return (
                      <td key={index}>
                        <div className="bg-gray-200">
                          {tooth ?.buccal.furcation != null ? (
                            <div className="w-[14px] m-auto z-40">
                              {tooth ?.buccal ?.furcation[0] === 1 && (
                                <img src={`/dental/nulll.png`} />
                              )}
                              {tooth ?.buccal ?.furcation[0] === 2 && (
                                <img src={`/dental/half.png`} />
                              )}
                              {tooth ?.buccal ?.furcation[0] === 3 && (
                                <img src={`/dental/full.png`} />
                              )}
                            </div>
                          ) : (
                              <div>-</div>
                            )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <td className="!border-none text-right">Implant</td>
                  {teeth3.map((tooth, index) => (
                    <td
                      key={index}
                      className="p-1"
                    // onClick={() =>
                    //   handleInputChange(index, "implant", !tooth.implant)
                    // }
                    >
                      <div className="bg-gray-200">
                        <div
                          className={`m-auto size-3 ${
                            tooth.buccal.isImplant ? "bg-black" : ""
                            }`}
                        />
                      </div>
                    </td>
                  ))}
                </tr>

                <tr>
                  <td className="!border-none text-right">Mobility</td>
                  {teeth3.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        value={tooth.buccal.mobility || 0}
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                      // min={0}
                      // max={20}
                      // onChange={(e) =>
                      //   handleInputChange(
                      //     index,
                      //     "mobility",
                      //     parseInt(e.target.value, 10)
                      //   )
                      // }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="border-none">{"  "}</th>
                  {teeth3.map((tooth, index) => (
                    <th key={index}>{tooth.toothId}</th>
                  ))}
                </tr>
              </tbody>
            </table>
            {/* Table- 8 */}
            <table className="w-[85%] h-[225px]">
              <tbody>
                <tr>
                  {teeth4.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.buccal.gingivalMargin.map((gin, ginIndex) => (
                          <Input
                            type="number"
                            value={gin}
                            onChange={(e) =>
                              handleInputChange(
                                index + 24,
                                "buccal",
                                "gingivalMargin",
                                parseInt(e.target.value, 10),
                                ginIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth4.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center justify-center">
                        {tooth.buccal.probingDepth.map((prob, probIndex) => (
                          <Input
                            type="number"
                            value={prob}
                            onChange={(e) =>
                              handleInputChange(
                                index + 24,
                                "buccal",
                                "probingDepth",
                                parseInt(e.target.value, 10),
                                probIndex
                              )
                            }
                            className="p-0 w-1/3 bg-gray-200 text-center"
                          />
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>

                <tr>
                  {teeth4.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.buccal.plaque.map((pl, plIndex) => (
                          <button
                            className={`${
                              pl ? "bg-blue-500" : "bg-gray-200"
                              } p-[6px]`}
                          // onClick={() =>
                          //   handleInputChange(index, "plaque", !pl, plIndex)
                          // }
                          ></button>
                        ))}
                      </div>
                    </td>
                  ))}
                </tr>

                <tr>
                  {teeth4.map((tooth, index) => (
                    <td key={index}>
                      <div className="flex items-center gap-[2px] justify-center">
                        {tooth.buccal.bleedOnProbe.map(
                          (bleed, bleedIndex) => (
                            <button
                              // onClick={(e) =>
                              //   handleInputChange(
                              //     index,
                              //     "bleeding",
                              //     !bleed,
                              //     bleedIndex
                              //   )
                              // }
                              className={`${
                                bleed ? "bg-red-500" : "bg-gray-200"
                                } p-[6px]`}
                            ></button>
                          )
                        )}
                      </div>
                    </td>
                  ))}
                </tr>

                <tr>
                  {teeth4.map((tooth, index) => {
                    if (index < 5 || tooth.buccal.isImplant)
                      return <td key={index}>-</td>;
                    return (
                      <td key={index}>
                        <div className="bg-gray-200">
                          {tooth ?.buccal.furcation != null ? (
                            <div className="w-[14px] m-auto z-40">
                              {tooth ?.buccal ?.furcation[0] === 1 && (
                                <img src={`/dental/nulll.png`} />
                              )}
                              {tooth ?.buccal ?.furcation[0] === 2 && (
                                <img src={`/dental/half.png`} />
                              )}
                              {tooth ?.buccal ?.furcation[0] === 3 && (
                                <img src={`/dental/full.png`} />
                              )}
                            </div>
                          ) : (
                              <div>-</div>
                            )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  {teeth4.map((tooth, index) => (
                    <td
                      key={index}
                      className="p-1"
                    // onClick={() =>
                    //   handleInputChange(index, "implant", !tooth.implant)
                    // }
                    >
                      <div className="bg-gray-200">
                        <div
                          className={`m-auto size-3 ${
                            tooth.buccal.isImplant ? "bg-black" : ""
                            }`}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth4.map((tooth, index) => (
                    <td key={index}>
                      <Input
                        type="number"
                        value={tooth.buccal.mobility || 0}
                        className="w-1/2 p-0 outline-none block m-auto text-center"
                      // min={0}
                      // max={20}
                      // onChange={(e) =>
                      //   handleInputChange(
                      //     index,
                      //     "mobility",
                      //     parseInt(e.target.value, 10)
                      //   )
                      // }
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  {teeth4.map((tooth, index) => (
                    <th key={index}>{tooth.toothId}</th>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default DentalChart;
