import React, { useEffect, useState, useRef } from "react";
// import Sidebar from "../Home/Components/Sidebar/Sidebar";
// import { TokenPageContainer } from "../Doctor-Page/Doctorstyle";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { configJson } from "../../Constent/config";
// import { formatDate, renderMedia } from "../../helpers";
// import { jwtDecode } from "jwt-decode";
// import { FaCamera, FaPrint } from "react-icons/fa";
import { Col, Row, Spinner } from "react-bootstrap";
// import UploadFiles from "../Engine-Page/mainEngine/Components/UploadFiles";
import Feed from "./feed";
// import OutpatientDataSheet from "../Engine-Page/mainEngine/Components/OutPatientDefaultTemplate";
// import InteractionPatientList from "../Home/Components/Sidebar/InteractionPatientList";
import axios from "axios";

const SidePatients = () => {
  const [loading, setLoading] = useState(true);
  const [interactions, setInteractions] = useState([]);
  const [interactionsAudio, setInteractionsAudio] = useState([]);
  const { state } = useLocation();
  // console.log("locationstate",state)

  const [selectedPatientName, setSelectedPatientName] = useState("");
  // const [name, setName] = useState("");
  const [content, setContent] = useState("detail");
  const [patientSummary, setPatientSummary] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const ref = useRef([]);
  const location = useLocation();
  const interactionIdFirst =
    location ?.state ?.interaction_id ||
      localStorage.getItem("interactionIdFirst");
  // console.log(interactionIdFirst, "interactionIdinlocation"); // Logs the value of interaction_id

  const queryParams = new URLSearchParams(location.search);
  const selectedPatientId = queryParams.get("id");
  // console.log(selectedPatientId, typeof(selectedPatientId), 'VVVV')

  const fetchInteractions = async (patientId, name) => {
    setLoading(true);
    try {
      const response = await fetch(`
            ${configJson.backend_URL}/interactionDetail/getAllInteractionDetails/patient/${patientId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch interactions");
      }
      const result = await response.json();
      setInteractions(result);
      setSelectedPatientName(name);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchInteractionsByAudio = async (patientId, name) => {
    setLoading(true);
    try {
      // Construct the endpoint URL
      const response = await fetch(
        `${configJson.backend_URL}/interactionDetail/getRawAudioTextDetailsByPatientId/patient/${patientId}`
      );

      // Check if the response is successful
      if (!response.ok) {
        throw new Error("Failed to fetch interactions");
      }

      // Parse the response JSON
      const result = await response.json();
      // console.log("Result audio interaction", result);

      // Handle the result (process the data if needed)
      if (result && result.Interactions && result.Interactions.length > 0) {
        setInteractionsAudio(result.Interactions); // Set the interactions
      } else {
        // console.log("No raw-audio-txt interactions found for this patient.");
        setInteractionsAudio([]); // Empty interactions if none are found
      }
    } catch (err) {
      console.error("Error fetching interactions:", err);
    } finally {
      setLoading(false); // Stop the loading state
    }
  };
  useEffect(() => {
    // Store interactionIdFirst in localStorage when it is present
    if (location ?.state ?.interaction_id) {
      localStorage.setItem("interactionIdFirst", location.state.interaction_id);
    }
  }, [location ?.state ?.interaction_id]);
  useEffect(() => {
    const storedPatients = JSON.parse(localStorage.getItem("patients")) || [];
    // console.log(storedPatients)
    // if(storedPatients.length===0){
    //   navigate("/activity")
    // }
    const updatedPatients = storedPatients.filter(
      (patient) => patient.id === id
    );
    // setName(updatedPatients[0].name);
    setInteractions([]);
    fetchInteractions(selectedPatientId, updatedPatients[0] ?.name);
    fetchInteractionsByAudio(selectedPatientId, updatedPatients[0] ?.name);
  }, [id, location]);
  // console.log(interactionsAudio?.[0]?.InteractionDetails?.[0]?.interaction_id, "audiourlinteractionid");

  const audioUrlInteractionId = interactionsAudio
    ?.flatMap((interaction) =>
      interaction.InteractionDetails ?.filter(
        (detail) => detail.interaction_detail_type === "Audio url"
      )
    ) // Flatten the array of details for all interactions with "Audio url" type
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by created_at, latest first
      ?.shift() ?.interaction_id; // Get the interaction_id of the latest "Audio url"

  // console.log(audioUrlInteractionId);

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      // const decoded = jwtDecode(token);
      // console.log("DESoocede", decoded);
      // setDecode(decoded);
    } else {
      navigate("/login");
    }
  }, []);

  const getSummary = async (patientId) => {
    try {
      const res = await axios.get(
        `${configJson.backend_URL}/patient/summary/${patientId}`
      );
      // console.log(res);
      setPatientSummary(res ?.data ?.summaryReport);
      // console.log(patientSummary)
    } catch (err) {
      console.log(err);
    }
  };
  const generateSummary = async (patientId) => {
    setLoading(true);
    try {
      // interactions.IsVisitUpdated
      const res = await axios.post(
        `${configJson.backend_URL}/patient/generateSummary/${patientId}`
      );
      getSummary(selectedPatientId);
    } catch (err) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    {/* // <Sidebar setuser={true} setInteractions={setInteractions}>
    // <div className="container mt-2">
    //   <Row>
    //     <Col md={3}>
    //       <>
    //         <InteractionPatientList />
    //       </>
    //     </Col>
    //     <Col md={9}> */}
      {/*< div
        className= {`flex flex-col gap-3 w-full m-auto sticky top-0 z-50 bg-white mb-2 border-b-2 ${
    selectedPatientId == null ? "pointer-events-none" : ""
    }`
}
      >
<div className="flex items-center justify-center w-full m-auto ">
    {/* <div
      className={`flex items-center justify-center m-auto !w-1/3 cursor-pointer border-b-2 ${
        content === "detail"
          ? "!border-medblue-darktwo border-b-4"
          : "border-[#ACACAC]"
        }`}
      style={{ borderColor: "transparent" }}
      onClick={() => {
        setContent("detail");
        if (fetchInteractions) {
          fetchInteractions(selectedPatientId);
        }
      }}
    >
      <div className="flex w-auto justify-center flex-shrink-0">
        <img src="/activity-feed.png" alt="Activity Icon" />
      </div>
      <span className="ml-2">Activity</span>
    </div> */}


    {/*Live Translate*/}
    {/* <div
      className={`flex items-center justify-center m-auto !w-1/3 cursor-pointer border-b-2 ${
        content === "LiveTranslate"
          ? "!border-medblue-darktwo border-b-4"
          : "border-[#ACACAC]"
        }`}
      style={{ borderColor: "transparent" }}
      onClick={() => {
        setContent("LiveTranslate");
        if (fetchInteractions) {
          fetchInteractions(selectedPatientId);
        }
      }}
    >
      <div className="flex w-auto justify-center flex-shrink-0">
        <img src="/translation.png"  alt="translate Icon" />
      </div>
      <span className="ml-2">Streaming Language</span>
    </div> */}
    {/* <div
            className={`m-auto !w-1/3 cursor-pointer border-b-2  ${
              content === "summary"
                ? "!border-medblue-darktwo border-b-4"
                : "border-[#ACACAC]"
            }`}
            style={{ borderColor: "transparent" }}
            onClick={() => {
              setContent("summary");
              if (interactions?.IsVisitUpdated === true) {
                generateSummary(selectedPatientId);
              } else if (interactions?.IsVisitUpdated === false) {
                getSummary(selectedPatientId);
              }
              // generateSummary(userDetails?.patientId);
              // getSummary(userDetails?.patientId);
            }}
          >
            <img src={`/activity-one.png`} className="m-auto" width={32} />
          </div> */}

    {/* Periodontal chart
          <div
  className={`flex items-center justify-center m-auto !w-1/3 cursor-pointer border-b-2 ${
    content === "chart"
      ? "!border-medblue-darktwo border-b-4"
      : "border-[#ACACAC]"
  }`}
  style={{ borderColor: "transparent" }}
  onClick={() => {
    setContent("chart");
  }}
>
  <div className="flex w-auto justify-center flex-shrink-0">
    <img src="/tooth.png" className="w-[32px]" alt="Tooth Icon" />
  </div>
  <span className="ml-2">Periodontal chart</span>
</div>

  </div>
  </div > { " " }*/}
{
  selectedPatientId != null ? (
        <>
    { loading?(
            <div className= "flex items-center justify-center h-full" >
    <Spinner animation="border" />
            </div >
          ) : (
    <div className="feed-page px-2 sm:px-4">
      <Feed
        interactions={interactions}
        interactionIdFirst={interactionIdFirst}
        selectedPatientName={selectedPatientName}
        fetchInteractions={fetchInteractions}
        audioUrlInteractionId={audioUrlInteractionId}
        patientSummary={patientSummary}
        content={content}
      />
    </div>
  )
}
        </>
      ) : (
  <div className="text-xl text-center mt-10">Select a patient.</div>
)}
{/* </Col>
      </Row>
    </div> */}
    </>
  );
};

export default SidePatients;
