import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { configJson } from "../../Constent/config"

export const fetchTemplates = createAsyncThunk(
    "medicationTemplates/fetchTemplates",
    async (organization_id, { rejectWithValue }) => {
      try {
        const response = await fetch(
          `${configJson.backend_URL}/medicationtemplates/organization/${organization_id}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch medication templates");
        }
        const data = await response.json();
        return data.data;
      } catch (error) {
        return rejectWithValue(error.message);
      }
    }
  );

// Create slice
const medicationTemplateSlice = createSlice({
  name: "medicationTemplates",
  initialState: {
    templates: [],
    loading: false,
    error: null,
  },
  reducers: {
    updateData: (state, action) => {
      const { id, updatedTemplate } = action.payload;
      // const index = state.templates.findIndex((template) => template.id === id);
      // if (index !== -1) {
      //   state.templates[index] = { ...state.templates[index], ...updatedTemplate };
      // }
      const index = state.templates.findIndex((a) => a.template_id === action.payload.template_id);
      if (index !== -1) {
        state.templates[index] = { ...state.templates[index], ...action.payload };
      }
    },
    addtemplates: (state, action) => {
      state.templates.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.templates = action.payload;
      })
      .addCase(fetchTemplates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions and reducer
export const { updateData,addtemplates } = medicationTemplateSlice.actions;
export default medicationTemplateSlice.reducer;
