import React, { useEffect, useState } from "react";
import { configJson } from "../../../../Constent/config";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { updateOutpatientQuestion } from "../../../../redux/slices/userSlice";

const CheckboxForm = () => {
  const fields = [
    "Diagnosis",
    "C/O",
    "H/O Present illness",
    "Past Medical History",
    "Personal History",
    "DD",
    "PD",
    "Physical Examination",
    "CVS",
    "CNS",
    "RS",
    "Per Abdominal Examination (P/A)",
    "Urogenital System",
    "Inspection",
    "Palpation",
    "Percussion",
    "Auscultation",
    "Movement",
    "Measurement",
    "Plan",
  ];

  const [checkedFields, setCheckedFields] = useState({});
  const { details, loading } = useSelector((state) => state.user);
const dispatch=useDispatch();
    // Pre-fill the checkboxes based on `details.outpatient_question`
    useEffect(() => {
      if (details?.outpatient_question) {
        // Remove the "Generate an outpatient datasheet based on" prefix
        const prefix = "Generate an outpatient datasheet based on ";
        const questionText = details.outpatient_question.startsWith(prefix)
          ? details.outpatient_question.slice(prefix.length)
          : details.outpatient_question;
  
        const parsedQuestions = questionText.split(","); // Assuming it's a comma-separated string
        const preChecked = fields.reduce((acc, field) => {
          acc[field] = parsedQuestions.includes(field);
          return acc;
        }, {});
        setCheckedFields(preChecked);
      }
    }, [details]);

  const handleChange = (field) => {
    setCheckedFields((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedFields = Object.entries(checkedFields)
      .filter(([_, isChecked]) => isChecked)
      .map(([field]) => field);
    console.log("Selected Fields:", selectedFields);
    // alert(`You selected: ${selectedFields.join(", ")}`);
    const outpatientQuestion =`Generate an outpatient datasheet based on ${selectedFields?.join(",")}`;
    //Generate an outpatient datasheet based on Diagnosis, Presenting Complaint (PC), and Past Medical History (PMH).
    console.log(outpatientQuestion);
    console.log(details)
    if (details) {
      try {
        const response = await fetch(
          `${configJson.backend_URL}/doctor/outpatient_question/${details?.doctor_id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ outpatient_question: outpatientQuestion }),
          }
        );

        if (!response.ok) {
          const error = await response.json();
          toast.error(error.message || "Failed to update outpatient question");
        }

        const data = await response.json();
        toast.success(
          data.message || "Outpatient question updated successfully"
        );
        dispatch(updateOutpatientQuestion(data?.outpatient_question))
      } catch (error) {
        console.error("Error:", error.message);
        toast.error(error); // Rethrow the error to handle it in the calling component
      }
    }
  };

  return (
    <div className="p-4">
      <h3 className="text-lg font-semibold mb-4">Select Diagnosis Column</h3>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {fields.map((field, index) => (
            <div key={index} className="flex items-center">
              <input
                type="checkbox"
                id={`checkbox-${index}`}
                className="h-5 w-5 text-gray-600bg-medblue-light border-gray-400 rounded focus:medblue-light"
                checked={checkedFields[field] || false}
                onChange={() => handleChange(field)}
              />
              <label
                htmlFor={`checkbox-${index}`}
                className="ml-2 text-gray-700 text-sm"
              >
                {field}
              </label>
            </div>
          ))}
        </div>
        <button
          type="submit"
          className="mt-4 px-4 py-2  bg-medblue-light text-white text-sm rounded shadow "
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default CheckboxForm;
