import React from "react";

const ConsentRequestForm = () => {
  return (
    <div className="flex justify-start items-start min-h-screen bg-gray-100 p-6">
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-md">
        <h1 className="text-xl font-semibold text-gray-800 mb-4">
          Select Patient / Create New
        </h1>
      </div>
    </div>
  );
};

export default ConsentRequestForm;
