import React, { useState, useRef, useEffect } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import axios from "axios";
import { configJson } from "../../../../Constent/config";

const SignaturePad = ({ doctorId }) => {
  const canvasRef = useRef(null);
  const [isEraser, setIsEraser] = useState(false);
  const [doctorDetails, setDoctorDetails] = useState(null); // Holds doctor details
  const [error, setError] = useState(null);
  const[loading,setLoading]=useState(false)

  // Fetch doctor details, including signature
  const fetchDoctorDetails = async (doctorId) => {
    try {
      const response = await fetch(`${configJson.backend_URL}/doctor/doctorwithbase/${doctorId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer`,
        },
      });

      if (response.ok) {
        const doctorInfo = await response.json();
        console.log(doctorInfo, "infodoc");
        setDoctorDetails(doctorInfo.doctor);
      } else {
        setError("Failed to fetch doctor details.");
      }
    } catch (error) {
      console.error("Error fetching doctor details:", error);
      setError("An error occurred while fetching doctor details.");
    }
  };

  useEffect(() => {
    if (doctorId) {
      fetchDoctorDetails(doctorId);
    }
  }, [doctorId]);

  const handleClear = () => {
    if (canvasRef.current) {
      canvasRef.current.clearCanvas();
    }
  };

  const handleExportImageUpload = async () => {

    if (canvasRef.current) {
      try {
        setLoading(true); // Set loading back to false after the delay
        const image = await canvasRef.current.exportImage("png");
        const response = await fetch(image);
        const blob = await response.blob();

        const fileName = `${doctorId}_${Date.now()}.png`;

        const formData = new FormData();
        formData.append("doctor_id", parseFloat(doctorId)); // Only sending doctor_id
        formData.append("file", blob, fileName);
        formData.append("file_name", `files/${fileName}`);

        console.log("Uploading image...");
        const result = await axios.post(
          `${configJson.backend_URL}/interaction/signatureupdate`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (result?.status) {
          console.log("Image uploaded successfully:", result.data); 

          // Update signature URL in doctor details
          const updatedDoctor = { ...doctorDetails, signature_base64: result.data.signature_base64 };
          setDoctorDetails(updatedDoctor);
          await fetchDoctorDetails(doctorId)
          setLoading(false);
        }
      } catch (error) {
        console.error("Error exporting or uploading the image:", error);
      }
    } else {
      console.error("Canvas reference is not available");
    }
  };

  const handleModifySignature = () => {
    setDoctorDetails({ ...doctorDetails, signature_base64: null });
  };

  return (
    <div
      style={{
        marginTop: "20px",
        padding: "20px",
        borderRadius: "8px",
        maxWidth: "900px",
        margin: "10px auto",
        backgroundColor: "#f9f9f9",
        boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
      }}
    >
      {/* Heading Section */}
      <div style={{ marginBottom: "20px" }}>
        <h3 style={{ textAlign: "center", color: "#333" }}>Signature Pad</h3>
        <p style={{ textAlign: "center", fontSize: "14px", color: "#555" }}>
          Please use the signature pad below to add or modify your signature.
        </p>
      </div>

      {/* Signature Display or Pad */}
      {doctorDetails?.signature_base64 ? (
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img
            src={doctorDetails?.signature_base64} // Update path as needed
            alt="Doctor's Signature"
            style={{
              maxWidth: "100%",
              margin: "0 auto",
              height: "150px",
              objectFit: "contain",
              border: "1px solid #ccc",
              borderRadius: "5px",
            }}
          />
          <p style={{ fontSize: "14px", color: "#555" }}>
            Your existing signature is displayed above.
          </p>
          <button
            style={{
              marginTop: "10px",
              background: "rgb(255, 87, 87)",
              color: "#fff",
              padding: "8px 20px",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
              fontSize: "14px",
            }}
            onClick={handleModifySignature}
          >
            Modify Signature
          </button>
        </div>
      ) : (
        <ReactSketchCanvas
          ref={canvasRef}
          style={{
            border: "1px solid #ccc",
            borderRadius: "5px",
            width: "100%",
            maxWidth: "300px",
            height: "150px",
            margin: "0 auto",
          }}
          strokeWidth={2}
          strokeColor="black"
        />
      )}

      {/* Button Section */}
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        {!doctorDetails?.signature_base64 && (
          <>
              <button
              style={{
                marginRight: "10px",
                background: "rgb(6, 174, 182)",
                color: "#fff",
                padding: "8px 20px",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={()=>{
                fetchDoctorDetails(doctorId)
              }}
            >
              Back
            </button>
            <button
              style={{
                marginRight: "10px",
                background: "rgb(6, 174, 182)",
                color: "#fff",
                padding: "8px 20px",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={handleClear}
            >
              Clear
            </button>
            <button
              style={{
                background: "rgb(6, 174, 182)",
                color: "#fff",
                padding: "8px 20px",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={handleExportImageUpload}
            >
              {loading?"loading..":"Save Signature"}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default SignaturePad;
