import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabContent, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "../Home/Components/Sidebar/Sidebar";
import AssessmentPage from "../AssessmentTemplate/AssessmentPage";
import DoctorDetails from "./DoctorDetails";
import "./DoctorDetails.css";
import MedicationTemplates from "../Templates/MedicationTemplates";
import KollaConnect from "../kolla/kollaToken";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import BillingInfoDoctor from "../Billing/BillingInformationDoctor";
import TemplateIntegration from "./Components/TemplateSettings/TemplateIntegration";
import CheckboxForm from "./Components/TemplateSettings/CheckboxForm";
import SignaturePad from "./Components/TemplateSettings/SignaturePad";

const Settings = () => {
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  const [decode, setDecode] = useState(null);

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      setDecode(decoded);
      setRole(decoded.role);
    } else {
      navigate("/login");
    }
  }, [navigate]);
  console.log(decode?.doctor_id, "docsignatureid"); // Use optional chaining
const doctorId = decode?.doctor_id
  return (
    // <Sidebar setuser={true}>
    <div>
      <div class="w-full">
        <div className="flex items-center justify-between">
          <h4 className="text-lg text-gray-800 font-semibold m-0 p-0">
            Settings
          </h4>
          <p className="text-sm text-gray-800 m-0" style={{paddingRight:"10px"}}>
            Version: <span class="font-medium">1.2.1</span>
          </p>
        </div>

        <div className="w-full h-[2px] bg-gray-300 mb-1"></div>
      </div>
      {role === "doctor" && (
        <Tabs defaultActiveKey="doctordetails" id="settings-tabs">
          <Tab eventKey="doctordetails" title="General">
            <TabContent>
              <div className="p-3">
                <DoctorDetails />
              </div>
            </TabContent>
          </Tab>
          <Tab eventKey="doctorsignature" title="Signature">
            <TabContent>
              <div className="p-3">
                <SignaturePad doctorId={doctorId} />
              </div>
            </TabContent>
          </Tab>
          <Tab eventKey="integration" title="Integration">
            <TabContent>
              <div className="p-3">
                <KollaConnect />
              </div>
            </TabContent>
          </Tab>
          <Tab eventKey="assessment" title="Assessment Setup">
            <TabContent>
              <div className="p-3">
                <AssessmentPage />
              </div>
            </TabContent>
          </Tab>
          <Tab eventKey="medication templates" title="Medication Setup">
            <TabContent>
              <div className="p-3">
                <MedicationTemplates />
              </div>
            </TabContent>
          </Tab>
          <Tab eventKey="Diagnosis Column" title="Diagnosis Column">
            <TabContent>
              <div className="p-3">
                <CheckboxForm />
              </div>
            </TabContent>
          </Tab>
          {/* <Tab eventKey="templates selection" title="Templates Setup">
            <TabContent>
              <div className="p-3">
              <TemplateIntegration />
              </div>
            </TabContent>
          </Tab> */}
          {/* <Tab eventKey="profile" title="Business Profile">
          <TabContent>
            <div className="p-3">
              <h4>Business Profile</h4>
              <p>Settings and information related to your business profile.</p>
            </div>
          </TabContent>
        </Tab>
        <Tab eventKey="billing" title="Billing">
          <TabContent>
            <div className="p-3">
              <h4>Billing</h4>
              <p>Manage your billing information here.</p>
            </div>
          </TabContent>
        </Tab>
        <Tab eventKey="staff" title="My Staff">
          <TabContent>
            <div className="p-3">
              <h4>My Staff</h4>
              <p>Details about staff members.</p>
            </div>
          </TabContent>
        </Tab>
        <Tab eventKey="opportunities" title="Opportunities">
          <TabContent>
            <div className="p-3">
              <h4>Opportunities</h4>
              <p>Manage opportunities and leads here.</p>
            </div>
          </TabContent>
        </Tab> */}
        </Tabs>
      )}

      {role === "front office staff" && (
        <Tabs defaultActiveKey="integration" id="settings-tabs">
          <Tab eventKey="integration" title="Integration">
            <TabContent>
              <div className="p-3">
                <KollaConnect />
              </div>
            </TabContent>
          </Tab>
          <Tab eventKey="billing" title="Billing">
            <TabContent>
              <div className="p-3">
                <p>Update Billing Details for the Doctor</p>
                <BillingInfoDoctor />
              </div>
            </TabContent>
          </Tab>
        </Tabs>
      )}
    </div>
    // </Sidebar>
  );
};

export default Settings;
