export const configJson = {};

// configJson.backend_URL = "http://localhost:5000";
// configJson.CHATBOT_URL = "https://medscribe-server-sand.medscribe.in";
// configJson.ABC = 19;
// configJson.CID = 15;

// // //production

configJson.backend_URL = "";
configJson.CHATBOT_URL = "https://medscribe-server.medscribe.in";
configJson.ABC = 15;
configJson.CID = 12;


// // DEV

// configJson.backend_URL = "";
// configJson.CHATBOT_URL = "https://medscribe-server-sand.medscribe.in";
// configJson.ABC = 19;
// configJson.CID = 15;
