import React, { useState, useEffect } from "react";
import { Table, Button, Modal, Form, Alert } from "react-bootstrap";
import "../../App.css"; // Corrected the import
import { configJson } from "../../Constent/config";
import { useDispatch } from "react-redux";
import { deleteAssessment, updateAssessment } from "../../redux/slices/assessmentSlice";

const AssessmentList = ({ assessmentList, setAssessmentList }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [newTemplateName, setNewTemplateName] = useState("");
  const [newTestList, setNewTestList] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const dispatch=useDispatch();
  // const [loading, setLoading] = useState(true); 
console.log(assessmentList)
    // Fetch assessments when component mounts
    useEffect(() => {
      // if (assessmentList.length === 0) {
      //   fetchAssessments()
      //     .then(() => setLoading(false)) 
      //     .catch(() => setLoading(false));
      // } else {
      //   setLoading(false); // If assessmentList is already filled, no need to fetch
      // }
    }, []);

  const handleEditClick = (assessment) => {
    setSelectedAssessment(assessment);
    setNewTemplateName(assessment.assessment_template);
    setNewTestList(assessment.template_tests);
    setShowEditModal(true);
  };

  const handleSaveEdit = async () => {
    try {
      const response = await fetch(
        `${configJson.backend_URL}/assessment/update/${selectedAssessment.assessment_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            assessment_template: newTemplateName,
            template_tests: newTestList,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data.assessment,"response.data.assessment")
        // setAssessmentList((prevList) =>
        //   prevList.map((assessment) =>
        //     assessment.assessment_id === selectedAssessment.assessment_id
        //       ? { ...assessment, assessment_template: newTemplateName, template_tests: newTestList }
        //       : assessment
        //   )
        // );
        dispatch(updateAssessment(data.assessment))
        setShowEditModal(false);
        setFeedbackMessage("Assessment updated successfully.");
      } else {
        setFeedbackMessage("Error updating assessment.");
      }
    } catch (error) {
      console.error("Error updating assessment:", error);
      setFeedbackMessage("Error updating assessment.");
    }
  };

  const handleDeleteClick = async (assessment_id) => {
    if (window.confirm("Are you sure you want to delete this assessment?")) {
      try {
        const response = await fetch(`${configJson.backend_URL}/assessment/delete/${assessment_id}`, {
          method: "DELETE",
        });

        if (response.ok) {
       //   setAssessmentList((prevList) => prevList.filter((assessment) => assessment.assessment_id !== assessment_id));
          const data = await response.json();
          dispatch(deleteAssessment(assessment_id))
          setFeedbackMessage("Assessment deleted successfully.");
        } else {
          setFeedbackMessage("Error deleting assessment.");
        }
      } catch (error) {
        console.error("Error deleting assessment:", error);
        setFeedbackMessage("Error deleting assessment.");
      }
    }
  };

  return (
    <>
      <h4 className="mt-5 text-center">Manage Assessment List</h4>
      {feedbackMessage && <Alert variant="info">{feedbackMessage}</Alert>}
      <Table striped bordered hover responsive className="table-sm text-center mb-5 templateForm">
  <thead className="table-dark">
    <tr>
      <th>Template Name</th>
      <th>Test List</th>
      <th className="col-3">Actions</th>
    </tr>
  </thead>
  <tbody>
    { assessmentList.length > 0 ? (
      assessmentList.map((assessment, index) => (
        <tr key={index}>
          <td className="mt-3 my-2 p-4">{assessment.assessment_template}</td> {/* Increased padding */}
          <td className="mt-3 my-2 p-4">{assessment.template_tests}</td> {/* Increased padding */}
          <td className="col-3 p-4"> {/* Increased padding */}
            <Button
              className="addTestclass mt-3 btn btn-success mx-2 my-2"
              variant="success"
              onClick={() => handleEditClick(assessment)}
            >
              Edit
            </Button>
            <Button
              className="addTestclass mt-3 btn btn-success mx-2 my-2"
              variant="danger"
              onClick={() => handleDeleteClick(assessment.assessment_id)}
            >
              Delete
            </Button>
          </td>
        </tr>
      ))
    ) : (
      <tr>
        <td colSpan="3">No Templates available</td>
      </tr>
    )}
  </tbody>
</Table>


      {/* Edit Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Assessment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formTemplateName">
              <Form.Label>Template Name</Form.Label>
              <Form.Control
                type="text"
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="formTestList">
              <Form.Label>Test List (Comma Separated)</Form.Label>
              <Form.Control
                type="text"
                value={newTestList}
                onChange={(e) => setNewTestList(e.target.value)}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleSaveEdit}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssessmentList;
