import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { configJson } from "../../Constent/config";

// Async thunk to fetch medication data based on a templateId
export const fetchMedicationData = createAsyncThunk(
  "medicationData/fetchMedicationData",
  async (templateId, { rejectWithValue }) => {
    try {
      const token = sessionStorage.getItem("orgtoken");
      const response = await fetch(
        `${configJson.backend_URL}/medicationList/medication/temp/${templateId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch template medication data");
      }

      const data = await response.json();
      return data?.data || []; // Ensure always an array
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const medicationDataSlice = createSlice({
  name: "medicationData",
  initialState: {
    data: [], // Added to hold the medication list
    loading: false,
    error: null,
  },
  reducers: {
    // Update a medication item
    updateMedicationData: (state, action) => {
      const updatedItem = action.payload; // Updated medication item
      const index = state.data.findIndex(
        (item) => item.medication_id === updatedItem.medication_id
      );
      if (index !== -1) {
        state.data[index] = updatedItem; // Replace existing item
      }
    },

    // Delete a medication item
    deleteMedicationData: (state, action) => {
      const idToDelete = action.payload; // Medication ID to delete
      state.data = state.data.filter(
        (item) => item.medication_id !== idToDelete
      );
    },

    // Create/Add new medication(s)
    createMedicationData: (state, action) => {
      console.log("Payload received in createMedicationData:", action.payload);

      if (Array.isArray(action.payload)) {
        // If payload is an array, spread and add all medications
        state.data = [...state.data, ...action.payload];
      } else {
        // If payload is a single object, add it individually
        state.data.push(action.payload);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMedicationData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMedicationData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload; // Set fetched medications
      })
      .addCase(fetchMedicationData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store error message
      });
  },
});

// Export actions to be used in components
export const { updateMedicationData, deleteMedicationData, createMedicationData } =
  medicationDataSlice.actions;

// Export reducer to be added to the Redux store
export default medicationDataSlice.reducer;
