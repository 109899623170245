import React, { useEffect, useRef, useState } from "react";
import { Button, InputGroup } from "react-bootstrap";

export default function ChatAudioText({ setVoiceInputText }) {
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [inputAudioText, setInputAudioText] = useState("");
  const recognition = useRef(null);
  console.log(recordingStatus);
  const finalTranscript = useRef("");
  const startRecording = () => {
    finalTranscript.current = "";
    setRecordingStatus("recording");
    recognition.current.start();
  };

  const stopRecording = () => {
    setRecordingStatus("inactive");
    recognition.current.stop();
  };
  useEffect(() => {
    if (!window.webkitSpeechRecognition) {
      alert("Your browser does not support Speech Recognition API");
      return;
    }
    recognition.current = new window.webkitSpeechRecognition();
    recognition.current.interimResults = true;
    recognition.current.lang = "en-US";

    if (/Mobi|Android/i.test(navigator.userAgent)) {
      recognition.current.continuous = false;
    } else {
      recognition.current.continuous = true;
    }
    recognition.current.onresult = (event) => {
      let interimTranscript = "";

      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcriptResult = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript.current += transcriptResult;
        } else {
          interimTranscript += transcriptResult;
        }
      }

      const combinedTranscript =
        finalTranscript.current.trim() + " " + interimTranscript.trim();
      setVoiceInputText(combinedTranscript.trim());

      if (finalTranscript.current) {
        setInputAudioText(finalTranscript.current);
      }
    };
  }, [setVoiceInputText]);

  return (
    <>
      {recordingStatus === "inactive" ? (
        <div className="mike">
          <Button type="button" variant="link" onClick={startRecording}>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-mic-mute-fill mute-mike"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                d="M13 8c0 .564-.094 1.107-.266 1.613l-.814-.814A4.02 4.02 0 0 0 12 8V7a.5.5 0 0 1 1 0v1zm-5 4c.818 0 1.578-.245 2.212-.667l.718.719a4.973 4.973 0 0 1-2.43.923V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 1 0v1a4 4 0 0 0 4 4zm3-9v4.879L5.158 2.037A3.001 3.001 0 0 1 11 3z"
                fill="#06AEB6"
              ></path>{" "}
              <path
                d="M9.486 10.607 5 6.12V8a3 3 0 0 0 4.486 2.607zm-7.84-9.253 12 12 .708-.708-12-12-.708.708z"
                fill="#06AEB6"
              ></path>{" "}
            </svg> */}
            <img src={`/microphone.png`} width={25} />
          </Button>
        </div>
      ) : null}
      {recordingStatus === "recording" ? (
        <div className="mike mic-active">
          <Button type="button" variant="link" onClick={stopRecording}>
            <div className="pulse-ring"></div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-mic-fill enable-mike"
              viewBox="0 0 16 16"
            >
              {" "}
              <path
                d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z"
                fill="#06AEB6"
              ></path>{" "}
              <path
                d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"
                fill="#06AEB6"
              ></path>{" "}
            </svg>
          </Button>
        </div>
      ) : null}
    </>
  );
}
