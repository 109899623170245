// import React, { useState, useRef, useEffect } from "react";
// import MicRecorder from "mic-recorder-to-mp3";
// import { v4 as uuidv4 } from "uuid";
// import { Mic as MicIcon, Stop as StopIcon } from "@mui/icons-material";

// const Stream = () => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [ws, setWs] = useState(null);
//   const [uid, setUid] = useState("");
//   const Mp3Recorder = useRef(null);
//   const [teeth, setTeeth] = useState([]);

//   useEffect(() => {
//     Mp3Recorder.current = new MicRecorder({ bitRate: 128 });
//     return () => {
//       if (ws) {
//         ws.close();
//       }
//     };
//   }, []);

//   useEffect(() => {
//     if (ws) {
//       ws.onmessage = (event) => {
//         try {
//           const data = JSON.parse(event.data);
//           if (data?.transcription?.periodontalChart) {
//             const updatedTeeth = data?.transcription?.periodontalChart;
//             setTeeth((prevTeeth) =>
//               prevTeeth.map((tooth) => {
//                 const updatedTooth = updatedTeeth?.find(
//                   (update) => update.toothId === tooth.toothId
//                 );
//                 // If an updated value exists for this tooth, merge it
//                 return updatedTooth ? { ...tooth, ...updatedTooth } : tooth;
//               })
//             );
//           }
//         } catch (error) {
//           console.error("Error parsing WebSocket message:", error);
//         }
//       };

//       ws.onopen = () => {
//         console.log("WebSocket connection opened");
//       };

//       ws.onclose = (event) => {
//         console.log("WebSocket connection closed:", event);
//       };

//       ws.onerror = (error) => {
//         console.error("WebSocket error:", error);
//       };
//     }
//   }, [ws]);

//   const startRecording = async () => {
//     const id = uuidv4();
//     setUid(id);

//     try {
//       await Mp3Recorder.current.start();
//       setIsRecording(true);

//       const newWs = new WebSocket(
//         "wss://ffmpeg-app.medscribe.in/ws/transcribe/"
//       );
//       setWs(newWs);
//     } catch (e) {
//       console.error("Error starting recording:", e);
//     }
//   };

//   const sendAudioChunk = async () => {
//     if (!isRecording) return;

//     try {
//       const [buffer, blob] = await Mp3Recorder.current.stop().getMp3();
//       await Mp3Recorder.current.start();
//       if (ws && ws.readyState === WebSocket.OPEN) {
//         const formData = new FormData();
//         formData.append("question_audio", blob, "audio.mp3");
//         formData.append("uuid_id", uid); // Use the provided `uid`
//         formData.append("uuid_bool", true); // Pass true for streaming, false for final chunk

//         ws.send(JSON.stringify(Object.fromEntries(formData)));
//       }

//       sendAudioChunk();
//     } catch (error) {
//       console.error("Error sending audio chunk:", error);
//     }
//   };
//   const stopRecording = async () => {
//     setIsRecording(false);

//     try {
//       const [buffer, blob] = await Mp3Recorder.current.stop().getMp3();
//       if (ws && ws.readyState === WebSocket.OPEN) {
//         const formData = new FormData();
//         formData.append("question_audio", blob, "audio.mp3");
//         formData.append("uuid_id", uid); // Use the provided `uid`
//         formData.append("uuid_bool", false); // Pass true for streaming, false for final chunk
//         ws.send(JSON.stringify(Object.fromEntries(formData)));
//         ws.close();
//         setWs(null);
//       }
//     } catch (e) {
//       console.error("Error stopping recording:", e);
//     }
//   };

//   return (
//     <div>
//     <button
//       onClick={isRecording ? stopRecording : startRecording}
//       className={`relative inline-flex items-center justify-center p-3 overflow-hidden text-white font-medium transition duration-300 ease-out rounded-full ${
//         isRecording
//           ? "bg-red-600 hover:bg-red-700 animate-pulse"
//           : "bg-green-600 hover:bg-green-700"
//       }`}
//     >
//       {isRecording ? (
//         <StopIcon sx={{ fontSize: 30 }} />
//       ) : (
//         <MicIcon sx={{ fontSize: 30 }} />
//       )}
//     </button>
//   </div>
//   );
// };

// export default Stream;

// import React, { useState, useRef, useEffect } from "react";
// import MicRecorder from "mic-recorder-to-mp3";
// import { v4 as uuidv4 } from "uuid";
// import { Mic as MicIcon, Stop as StopIcon } from "@mui/icons-material";
// import PipeCat from "pipecat";

// const Stream = () => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [ws, setWs] = useState(null);
//   const [uid, setUid] = useState("");
//   const Mp3Recorder = useRef(null);
//   const [teeth, setTeeth] = useState([]);
//   const [pipeCat, setPipeCat] = useState(null); // Add pipeCat state

//   useEffect(() => {
//     Mp3Recorder.current = new MicRecorder({ bitRate: 128 });
//     return () => {
//       if (ws) {
//         ws.close();
//       }
//       if (pipeCat) {
//           pipeCat.close(); // Close the pipeCat instance on unmount
//       }
//     };
//   }, []);

//     useEffect(() => {
//         if (pipeCat) {
//           pipeCat.on("data", async (chunk) => {
//             if (ws && ws.readyState === WebSocket.OPEN) {
//                 const formData = new FormData();
//                 formData.append("question_audio", new Blob([chunk]), "audio.mp3");
//                 formData.append("uuid_id", uid);
//                 formData.append("uuid_bool", true);
//                 try {
//                     ws.send(JSON.stringify(Object.fromEntries(formData)));
//                   } catch (error) {
//                     console.error("Error sending audio chunk:", error);
//                   }
//             }
//           });
//         }
//       }, [pipeCat, ws, uid]);
//   useEffect(() => {
//     if (ws) {
//       ws.onmessage = (event) => {
//         try {
//           const data = JSON.parse(event.data);
//           if (data?.transcription?.periodontalChart) {
//             const updatedTeeth = data?.transcription?.periodontalChart;
//             setTeeth((prevTeeth) =>
//               prevTeeth.map((tooth) => {
//                 const updatedTooth = updatedTeeth?.find(
//                   (update) => update.toothId === tooth.toothId
//                 );
//                 // If an updated value exists for this tooth, merge it
//                 return updatedTooth ? { ...tooth, ...updatedTooth } : tooth;
//               })
//             );
//           }
//         } catch (error) {
//           console.error("Error parsing WebSocket message:", error);
//         }
//       };

//       ws.onopen = () => {
//         console.log("WebSocket connection opened");
//       };

//       ws.onclose = (event) => {
//         console.log("WebSocket connection closed:", event);
//       };

//       ws.onerror = (error) => {
//         console.error("WebSocket error:", error);
//       };
//     }
//   }, [ws]);

//   const startRecording = async () => {
//     const id = uuidv4();
//     setUid(id);

//     try {
//       await Mp3Recorder.current.start();
//       setIsRecording(true);
//         const newPipeCat = new PipeCat();
//         setPipeCat(newPipeCat);
//          // Set up the data event for sending audio
//          newPipeCat.start(); // Start the pipeCat instance

//       const newWs = new WebSocket(
//         "wss://ffmpeg-app.medscribe.in/ws/transcribe/"
//       );
//       setWs(newWs);

//       Mp3Recorder.current.ondataavailable = (blob) => {
//           newPipeCat.push(blob)
//       };
//     } catch (e) {
//       console.error("Error starting recording:", e);
//     }
//   };

//   const stopRecording = async () => {
//     setIsRecording(false);

//     try {
//       const [buffer, blob] = await Mp3Recorder.current.stop().getMp3();

//       if (ws && ws.readyState === WebSocket.OPEN) {
//         const formData = new FormData();
//         formData.append("question_audio", blob, "audio.mp3");
//         formData.append("uuid_id", uid);
//         formData.append("uuid_bool", false);
//         ws.send(JSON.stringify(Object.fromEntries(formData)));
//         ws.close();
//         setWs(null);
//       }

//         if (pipeCat) {
//           pipeCat.close(); // Close the pipeCat instance
//           setPipeCat(null);
//         }

//     } catch (e) {
//       console.error("Error stopping recording:", e);
//     }
//   };

//   return (
//     <div>
//     <button
//       onClick={isRecording ? stopRecording : startRecording}
//       className={`relative inline-flex items-center justify-center p-3 overflow-hidden text-white font-medium transition duration-300 ease-out rounded-full ${
//         isRecording
//           ? "bg-red-600 hover:bg-red-700 animate-pulse"
//           : "bg-green-600 hover:bg-green-700"
//       }`}
//     >
//       {isRecording ? (
//         <StopIcon sx={{ fontSize: 30 }} />
//       ) : (
//         <MicIcon sx={{ fontSize: 30 }} />
//       )}
//     </button>
//   </div>
//   );
// };

import { useEffect, useState } from "react";
import RecordRTC from "recordrtc";

const Stream = () => {
  const [socket, setSocket] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [transcription, setTranscription] = useState("");

  useEffect(() => {
    const ws = new WebSocket("wss://d1a3-144-48-48-73.ngrok-free.app");
    ws.onopen = () => console.log("Connected to WebSocket");
    ws.onerror = (err) => console.error("WebSocket Error:", err);
    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);
      // if (response.transcription) {
      //     setTranscription(response.transcription);
      // }
      console.log(response, "RESS");
    };
    setSocket(ws);

    return () => ws.close();
  }, []);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const audioRecorder = new RecordRTC(stream, {
      type: "audio",
      mimeType: "audio/wav",
      recorderType: RecordRTC.StereoAudioRecorder,
      numberOfAudioChannels: 1,
      desiredSampRate: 44100,
      timeSlice: 500, // Send chunks every 500ms
      ondataavailable: (blob) => {
        if (socket && socket.readyState === WebSocket.OPEN) {
          blob.arrayBuffer().then((buffer) => socket.send(buffer));
        }
      },
    });

    audioRecorder.startRecording();
    setRecorder(audioRecorder);
  };

  const stopRecording = () => {
    if (recorder) {
      recorder.stopRecording(() => {
        console.log("Recording stopped");
      });
    }
    if (socket) {
      socket.close();
    }
  };

  return (
    <div>
      <button onClick={startRecording}>Start Recording</button>
      <button onClick={stopRecording}>Stop Recording</button>
      <h3>Transcription:</h3>
      <p>{transcription}</p>
    </div>
  );
};

export default Stream;
