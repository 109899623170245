import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { configJson } from "../../Constent/config"; // Make sure the path is correct

// Async thunk for fetching user details
export const fetchUserDetails = createAsyncThunk(
  "user/fetchUserDetails",
  async (userId, { rejectWithValue }) => {
    try {
      // console.log(`Fetching user details for userId: ${userId}`);
      const response = await fetch(`${configJson.backend_URL}/doctor/specific/${userId}`);
      
      // Check if response is ok
      if (!response.ok) {
        const errorMessage = `HTTP error! status: ${response.status}`;
        console.error(errorMessage);
        throw new Error(errorMessage);
      }

      const data = await response.json(); // Parse response JSON
      console.log("Fetched user data:", data);
      return data?.doctor      ;
    } catch (error) {
      console.error("Error in fetchUserDetails:", error.message);
      return rejectWithValue(error.message);
    }
  }
);

// Slice creation
const userSlice = createSlice({
  name: "user",
  initialState: {
    details: null,
    loading: false,
    errors: null,
  },
  reducers: {
    updateUserDetails: (state, action) => {
      // Merge new data with the existing state.details object
      state.details = { ...state.details, ...action.payload };
    },
    updateOutpatientQuestion: (state, action) => {
      // Specifically update only the outpatient_question field
      state.details = {
        ...state.details,
        outpatient_question: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.pending, (state) => {
        console.log("Fetching user details: pending...");
        state.loading = true;
        state.errors = null;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        console.log("Fetching user details: success!", action.payload);
        state.loading = false;
        state.details = action.payload;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        console.error("Fetching user details: failed", action.payload);
        state.loading = false;
        state.errors = action.payload;
      });
  },
});

export const { updateUserDetails,updateOutpatientQuestion } = userSlice.actions;

export default userSlice.reducer;
