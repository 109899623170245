import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { configJson } from "../../Constent/config"

// Async thunk to fetch assessments from API
export const fetchAssessments = createAsyncThunk(
  'assessments/fetchAssessments',
  async (organization_id) => {
    const response = await fetch(`${configJson.backend_URL}/assessment/list/${organization_id}`);
    if (!response.ok) {
      throw new Error('Failed to fetch assessments');
    }
    const data = await response.json();
    return data.assessments || []; // Ensure assessments is always an array
  }
);

const assessmentSlice = createSlice({
  name: 'assessments',
  initialState: {
    data: [], // Stores assessments
    loading: false,
    error: null
  },
  reducers: {
    // Create Assessment
    addAssessment: (state, action) => {
      state.data.push(action.payload);
    },

    // Update Assessment
    updateAssessment: (state, action) => {
      const index = state.data.findIndex((a) => a.assessment_id === action.payload.assessment_id);
      if (index !== -1) {
        state.data[index] = { ...state.data[index], ...action.payload };
      }
    },

    // Delete Assessment
    deleteAssessment: (state, action) => {
    console.log(" action.payload", action.payload)
      state.data = state.data.filter((a) => a.assessment_id !== action.payload);
      console.log(" action.payload",  state.data )
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssessments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAssessments.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload; // Save assessments in the store
      })
      .addCase(fetchAssessments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { addAssessment, updateAssessment, deleteAssessment } = assessmentSlice.actions;
export default assessmentSlice.reducer;
