import React, { useState, useEffect } from "react";
// import Sidebar from "../Home/Components/Sidebar/Sidebar";
import AssessmentTemplateForm from "./AssessmentTemplateForm";
import AssessmentList from "./AssessmentList";
import { jwtDecode } from "jwt-decode";
import { configJson } from "../../Constent/config";
import "../../App.css"; // Corrected the import
import { useSelector } from "react-redux";

const AssessmentPage = () => {
  const [doctorId, setDoctorId] = useState(null);
  const [organizationId, setorganizationId] = useState(null);
  const [assessmentList, setAssessmentList] = useState([]);
  const { data: assessments } = useSelector((state) => state.assessments);

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      const decoded = jwtDecode(token);
      if(decoded.organization_id){
      setDoctorId(decoded?.doctor_id);
      setorganizationId(decoded?.organization_id)
      //fetchAssessments(decoded?.organization_id);
      }
    }
  }, []);

  useEffect(()=>{
    console.log(assessments);
    setAssessmentList(assessments);
  },[assessments])

  // const fetchAssessments = async (organizationid) => {
  //   if(!organizationid){
  //     return
  //   }
  //   try {
  //     const response = await fetch(
  //       `${configJson.backend_URL}/assessment/list/${organizationid}`
  //     );
  //     const data = await response.json();
  //     setAssessmentList(data.assessments || []); // Ensure it's always an array
  //   } catch (error) {
  //     console.error("Error fetching assessments:", error);
  //   }
  // };

  return (

      <div className="container mt-4">
        <AssessmentTemplateForm
          doctorId={doctorId}
          setAssessmentList={setAssessmentList}
          organizationId={organizationId}
        />
        <AssessmentList
          assessmentList={assessmentList}
          // fetchAssessments={fetchAssessments}
          setAssessmentList={setAssessmentList}
        />
      </div>

  );
};

export default AssessmentPage;
