import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import settingsReducer from './slices/settingsSlice';
import patientsReducer from './slices/patientsSlice';
import assessmentReducer from './slices/assessmentSlice';
import userReducer from "./slices/userSlice"
import medicationTemplateReducer from "./slices/medicationTemplateSlice";
import medicationDataReducer from "./slices/medicationDataSlice"; 


const store = configureStore({
    reducer: {
        auth: authReducer,
        settings: settingsReducer,
        patients: patientsReducer,
        assessments: assessmentReducer,
        user: userReducer,
        medicationTemplates: medicationTemplateReducer,
        medicationData: medicationDataReducer,

    },
});

export default store;