import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { DateTime } from "luxon";
import { Image } from "react-bootstrap";
import Mammoth from "mammoth";
import "./App.css";

// const imgRef = React.createRef();

export const formatDate = (dateString) => {
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  }; // Format: 24 Apr, 2023
  return new Date(dateString).toLocaleDateString("en-US", options); // Use 'en-GB' for this format
};

export const onlyDate = (dateString) => {
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
  }; // Format: 24 Apr, 2023
  return new Date(dateString).toLocaleDateString("en-US", options); // Use 'en-GB' for this format
};

export const renderMedia = (url, detail) => {
  if (!url) {
    return;
  }
  const extension = url.split(".").pop().toLowerCase();

  if (
    extension === "mp3" ||
    extension === "wav" ||
    extension === "m4a" ||
    extension === "webm"
  ) {
    return (
      <audio
        controls
        className="w-full !relative !z-[1] border-1 border-medblue-light rounded-3xl"
        onError={() => console.error("Audio playback failed.")}
      >
        <source
          src={url}
          type={extension === "m4a" ? "audio/mp4" : `audio/${extension}`}
        />
        Your browser does not support the audio element or the file format is
        not supported.
      </audio>
    );
  } else if (
    ["jpg", "jpeg", "png", "gif", "avif", "heic"].includes(extension)
  ) {
    return null;
  } else if (extension === "mp4" || extension === "ogg") {
    return (
      <video controls className="w-full !relative !z-[1]">
        <source src={url} type={`video/${extension}`} />
        Your browser does not support the video element.
      </video>
    );
  } else if (extension === "pdf" || extension === "docx") {
    return <PdfViewer url={url} />;
  } else if (extension === "txt") {
    return (
      <p>
        <ReactMarkdown>{url}</ReactMarkdown>
      </p>
    );
  } else if (extension == "docx") {
    return <DocxViewer url={url} />
  } else {
    return (
      <p>
        <ReactMarkdown>{url}</ReactMarkdown>
      </p>
    );
  }
};

const timeZone = sessionStorage.getItem("timeZone");
// console.log(timeZone)

export function convertToKolkataTime(utcDateTime) {
  return DateTime.fromISO(utcDateTime, { zone: "utc" })
    .setZone(timeZone)
    .toFormat("yyyy-MM-dd, HH:mm:ss");
}
export function convertToKolkataTimes(utcDateTime, time) {
  return DateTime.fromISO(utcDateTime, { zone: "utc" })
    .setZone(time || "Asia/Kolkata")
    .toFormat("yyyy-MM-dd, HH:mm:ss");
}
const PdfViewer = ({ url }) => {
  const [showIframe, setShowIframe] = useState(false);
  const getFileName = (url) => {
    return url.split("/").pop(); // Gets the last segment after the last '/'
  };

  return (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      {/* Display the URL */}
      <p
        style={{
          marginBottom: "10px",
          color: "black",
          wordBreak: "break-word",
        }}
      >
        <strong>PDF URL:</strong>
        <a
          style={{ color: "black" }}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {getFileName(url)}
        </a>
      </p>

      {/* Always display the PDF */}
      <iframe
        src={`${url}#toolbar=0&navpanes=0&scrollbar=0`}
        // width="100%"
        // height="1000px"
        title="PDF Viewer"
        style={{
          maxWidth: "794px", // Restrict to A4 width
          width: "100%", // Responsive
          height: "300px",
          border: "none", // Remove border
          display: "block",
          margin: "0 auto", // Center horizontally
          backgroundColor: "white", // Set iframe background
        }}
      ></iframe>
    </div>
  );
};

export function convertToUTC() {
  // Get the current date and time in Asia/Kolkata
  const kolkataDateTime = DateTime.now().setZone(timeZone);

  // Convert to UTC and return in ISO format
  return kolkataDateTime.setZone("utc").toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
}

export function convertToKolkataTimeFormat(utcDateTime) {
  return DateTime.fromISO(utcDateTime, { zone: "utc" })
    .setZone(timeZone)
    .toFormat("MMM dd, yyyy, h:mm a");
}

// export function convertToKolkataTimes(utcDateTime, time) {
//   return DateTime.fromISO(utcDateTime, { zone: "utc" })
//     .setZone(time || "Asia/Kolkata")
//     .toFormat("yyyy-MM-dd, HH:mm:ss");
// }

export function convertToKolkataTimeDOB(utcDateTime) {
  return DateTime.fromISO(utcDateTime, { zone: "utc" })
    .setZone(timeZone)
    .toFormat("dd-MM-yyyy");
}
export function searchDateField(utcDateTime) {
  return DateTime.fromISO(utcDateTime, { zone: "utc" })
    .setZone(timeZone)
    .toFormat("dd-MM-yyyy");
}

export function convertToKolkataTimeFormatfeed(utcDateTime) {
  return DateTime.fromISO(utcDateTime, { zone: "utc" })
    .setZone(timeZone)
    .toFormat("MMM dd, yyyy, h:mm a");
}

const DocxViewer = ({ url }) => {
  const [content, setContent] = React.useState("");

  React.useEffect(() => {
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) =>
        Mammoth.extractRawText({ arrayBuffer }).then((result) =>
          setContent(result.value)
        )
      )
      .catch((error) => console.error("Error rendering DOCX file:", error));
  }, [url]);

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};
