import React, { useEffect, useState } from "react";
import { Modal, Checkbox, Input, Radio, message } from "antd";
import "./table.css";
import axios from "axios";
import { configJson } from "../../Constent/config";
import Spinner from "react-bootstrap/Spinner";

const VitalsModal = ({
  vitalsModal,
  setVitalsModal,
  patientId,
  doctorId,
  fetchInteractions,
  editVitals,
  vitalsInteractionId,
}) => {
  const [vitalsData, setVitalsData] = useState({});
  // const [bmi, setBmi] = useState();
  const [loading, setLoading] = useState(false);

  const handleInputChange = (key, value) => {
    setVitalsData((prev) => ({ ...prev, [key]: value }));
  };

  const handleCheckboxChange = (key, checked) => {
    setVitalsData((prev) => ({ ...prev, [key]: checked }));
  };

  const handleSubmit = async () => {
    if (!vitalsData.height || !vitalsData.weight) {
      return message.info("Please enter Height and Weight");
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${configJson.backend_URL}/interaction/saveVitals`,
        {
          patient_id: patientId,
          // doctor_id: doctorId,
          interaction_date: new Date()
            .toISOString()
            .slice(0, 19)
            .replace("T", " "),
          vitalsData,
        }
      );
      fetchInteractions(patientId);
      setVitalsModal(false);
    } catch (error) {
      console.error("Error saving vitals:", error);
      fetchInteractions(patientId);
      setLoading(false);
      setVitalsModal(false);
    } finally {
      setLoading(false);
      fetchInteractions(patientId);
    }
  };

  useEffect(() => {
    if (editVitals) {
      setVitalsData(editVitals);
    }
  }, [editVitals]);

  const updateVitals = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${configJson.backend_URL}/interaction/updateVitals/${vitalsInteractionId}`,
        {
          patient_id: patientId,
          vitalsData,
        }
      );
      fetchInteractions(patientId);
      setVitalsModal(false);
    } catch (error) {
      console.error("Error updating vitals:", error);
      fetchInteractions(patientId);
      setLoading(false);
      setVitalsModal(false);
    } finally {
      setLoading(false);
      fetchInteractions(patientId);
    }
  };

  return (
    <Modal
      open={vitalsModal}
      footer={null}
      onCancel={() => setVitalsModal(false)}
      width={620}
    >
      <div className="w-full text-[#666666]">
        <div>
          <div className="flex flex-col gap-2">
            <div className="text-xl font-semibold mb-2 text-medblue-light">
              Basic details
            </div>
            <div className="grid grid-cols-2 gap-x-4 mb-2 w-full">
              <div className="flex items-center gap-4 w-full">
                <label className="w-1/2">
                  Height (cm)<span className="text-red-600">&#42;</span>
                </label>
                <Input
                  type="number"
                  placeholder="Height in cm"
                  className="outline-none border p-1 rounded flex-1"
                  style={{ borderColor: "#979797" }}
                  value={vitalsData.height}
                  min={0}
                  max={200}
                  onChange={(e) => handleInputChange("height", e.target.value)}
                />
              </div>
              <div className="flex items-center gap-4 w-full">
                <label className="w-1/2">
                  Weight (kg)<span className="text-red-600">&#42;</span>
                </label>
                <Input
                  type="number"
                  placeholder="Weight in kg"
                  className="outline-none border p-1 rounded flex-1"
                  style={{ borderColor: "#979797" }}
                  value={vitalsData.weight}
                  min={0}
                  max={200}
                  onChange={(e) => handleInputChange("weight", e.target.value)}
                />
              </div>
              <div className="flex items-center gap-4 w-full">
                <label className="w-1/2">Body Temp.(°F)</label>
                <Input
                  type="number"
                  placeholder="Temperature"
                  className="outline-none border p-1 rounded flex-1"
                  style={{ borderColor: "#979797" }}
                  min={0}
                  value={vitalsData.temperature}
                  onChange={(e) =>
                    handleInputChange("temperature", e.target.value)
                  }
                />
              </div>
              <div className="flex items-center gap-4 w-full">
                <label className="w-1/2">Respiratory Rate (bpm)</label>
                <Input
                  type="number"
                  placeholder="Respiratory rate"
                  className="outline-none border p-1 rounded flex-1"
                  style={{ borderColor: "#979797" }}
                  min={0}
                  value={vitalsData.respRate}
                  onChange={(e) =>
                    handleInputChange("respRate", e.target.value)
                  }
                />
              </div>
              <div className="flex items-center gap-4 w-full">
                <label className="w-1/2">Blood Sugar (mg/dL)</label>
                <Input
                  type="number"
                  placeholder="Blood Sugar"
                  className="outline-none border p-1 rounded flex-1"
                  style={{ borderColor: "#979797" }}  
                  min={0}
                  value={vitalsData.bloodSugar}
                  onChange={(e) =>
                    handleInputChange("bloodSugar", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <div className="text-xl font-semibold text-medblue-light">
                Vitals
              </div>
              <div className="flex items-center w-1/3">
                <label className="w-1/2">BMI</label>
                <Input
                  type="number"
                  placeholder="BMI"
                  className="outline-none border p-1 rounded w-full pointer-events-none"
                  value={(
                    vitalsData?.weight /
                    (((vitalsData?.height / 100) * vitalsData?.height) / 100)
                  ).toFixed(2)}
                />
              </div>
            </div>
            <div className="vitals-border border rounded p-2 flex flex-col gap-2 ">
              <div className="font-semibold ">Blood Pressure</div>
              <div className="flex items-center gap-2 justify-around !flex-wrap md:!flex-nowrap">
                <div className="md:w-[40%]">Systolic Blood Pressure (mmHg)</div>
                <div className="flex items-center gap-2 md:w-[40%]">
                  <Input
                    type="number"
                    className={`outline-none border-1 p-1 rounded w-fit ${vitalsData.systolicBP > 140 ? "text-red-500" : ""}`}
                    value={vitalsData.systolicBP}
                    min={0}
                    onChange={(e) =>
                      handleInputChange("systolicBP", e.target.value)
                    }
                    style={{
                      borderColor:
                        vitalsData.systolicBP > 140 ? "red" : "#979797",
                        outline: "none",
                    }}
                  />
                  <div className="md:w-[100px]">(100 - 140)</div>
                </div>
                <Checkbox
                  checked={vitalsData.abnormalSystolic}
                  onChange={(e) =>
                    handleCheckboxChange("abnormalSystolic", e.target.checked)
                  }
                  className="md:w-[20%]"
                >
                  Abnormal
                </Checkbox>
              </div>
              <div className="flex items-center gap-2 justify-around !flex-wrap md:!flex-nowrap">
                <div className="md:w-[40%]">
                  Diastolic Blood Pressure (mmHg)
                </div>
                <div className="flex items-center gap-2 md:w-[40%]">
                  <Input
                    type="number"
                    className={`outline-none border-1 p-1 rounded w-fit ${vitalsData.diastolicBP > 90 ? "text-red-500" : ""}`}
                    value={vitalsData.diastolicBP}
                    min={0}
                    onChange={(e) =>
                      handleInputChange("diastolicBP", e.target.value)
                    }
                    style={{
                      borderColor:
                        vitalsData.diastolicBP > 90 ? "red" : "#979797",
                        outline: "none",
                    }}
                  />
                  <div className="md:w-[100px]">(60 - 90)</div>
                </div>
                <Checkbox
                  checked={vitalsData.abnormalDiastolic}
                  onChange={(e) =>
                    handleCheckboxChange("abnormalDiastolic", e.target.checked)
                  }
                  className="md:w-[20%]"
                >
                  Abnormal
                </Checkbox>
              </div>
              <div className="flex gap-2 flex-col md:flex-row items-center justify-between">
                <div className="font-semibold ">Body Position</div>
              </div>
              <div className="grid grid-cols-3 xs:grid-cols-6 gap-1">
                {[
                  { label: "Sitting", value: "Sitting" },
                  { label: "Recumbent", value: "Recumbent" },
                  { label: "Unknown", value: "Unknown" },
                  { label: "Other", value: "Other" },
                  { label: "Standing", value: "Standing" },
                  { label: "Fowler", value: "Fowler" },
                ].map((item, index) => (
                  <button
                    key={index}
                    className={`border p-2 rounded-[5px] text-center ${
                      vitalsData.position == item.label
                        ? "vitals-border vital-text"
                        : ""
                    }`}
                    onClick={() => handleInputChange("position", item.value)}
                  >
                    {item.label}
                  </button>
                ))}
              </div>
            </div>
            <div className="flex items-center gap-2 justify-around !flex-wrap md:!flex-nowrap mt-2">
              <div className="md:w-[40%]">Pulse (beats/min)</div>
              <div className="flex items-center gap-2 md:w-[40%]">
                <Input
                  type="number"
                  className={`outline-none border-1 p-1 rounded w-fit ${vitalsData.pulse > 100 ? "text-red-500" : ""}`}
                  value={vitalsData.pulse}
                  min={0}
                  onChange={(e) => handleInputChange("pulse", e.target.value)}
                  style={{
                    borderColor: vitalsData.pulse > 100 ? "red" : "#979797",
                  }}
                />
                <div className="md:w-[100px]">(60 - 100)</div>
              </div>
              <Checkbox
                checked={vitalsData.abnormalPulse}
                onChange={(e) =>
                  handleCheckboxChange("abnormalPulse", e.target.checked)
                }
                className="md:w-[20%]"
              >
                Abnormal
              </Checkbox>
            </div>
            <div className="flex items-center gap-2 justify-around !flex-wrap md:!flex-nowrap ">
              <div className="md:w-[40%]">
                Arterial Blood Oxygen Saturation (pulse oximeter) (%)
              </div>
              <div className="flex items-center gap-2 md:w-[40%]">
                <Input
                  type="number"
                  className={`outline-none border-1 p-1 rounded w-fit ${vitalsData.oxygenSaturation < 95 ? "text-red-500" : ""}`}
                  minLength={0}
                  value={vitalsData.oxygenSaturation}
                  min={0}
                  onChange={(e) =>
                    handleInputChange("oxygenSaturation", e.target.value)
                  }
                  style={{
                    borderColor:
                      vitalsData.oxygenSaturation < 95 ? "red" : "#979797",
                  }}
                />
                <div className="md:w-[100px]">(&gt;95)</div>
              </div>
              <Checkbox
                checked={vitalsData.abnormalOxygen}
                className="md:w-[20%]"
                onChange={(e) =>
                  handleCheckboxChange("abnormalOxygen", e.target.checked)
                }
              >
                Abnormal
              </Checkbox>
            </div>
          </div>
          <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-2 mt-3 border vitals-border rounded-lg p-2">
            {[
              { label: "Diabetes Mellitus", key: "diabetesMellitus" },
              { label: "Cancer", key: "cancer" },
              { label: "Hypertension", key: "hypertension" },
              { label: "Arthritis", key: "arthritis" },
              { label: "Cardiac Diseases", key: "cardiacDiseases" },
              { label: "Asthma", key: "asthma" },
              { label: "Hyperthyroidism", key: "hyperthyrodism" },
              { label: "Epilepsy", key: "epilepsy" },
              { label: "Hypothyroidism", key: "hypothyroidism" },
              { label: "Kidney Diseases", key: "kidneyDiseases" },
            ].map((item, index) => (
              <div
                key={item.key}
                className={`flex items-center justify-between p-2 rounded-lg`}
              >
                <div className="text-left">
                  {item.label}
                </div>
                <Checkbox
                  checked={vitalsData[item.key]}
                  onChange={(e) =>
                    handleCheckboxChange(item.key, e.target.checked)
                  }
                  className="cursor-pointer transform scale-125 transition duration-200 hover:scale-110"
                />
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-end gap-2">
          <button
            className="rounded-3xl text-[#9C9C9C] bg-[#f2f2f2] border-1 border-[#06AEB6] py-2 px-3 w-32 mt-4"
            onClick={() => setVitalsModal(false)}
          >
            Cancel
          </button>
          <button
            className="rounded-3xl text-white py-2 px-3 w-32 mt-4 bg-[#06AEB6]"
            // style={{
            //   background: "linear-gradient(to right, #1C1B1B, #5B5B5B)",
            // }}
            onClick={() => {
              if (vitalsInteractionId) {
                updateVitals();
              } else {
                handleSubmit();
              }
            }}
            disabled={vitalsData.height === "" || vitalsData.weight === ""}
          >
            {loading ? <Spinner animation="border" size="sm" /> : "Save"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default VitalsModal;
