import React, { useState, useEffect, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Avatar, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { configJson } from "../../Constent/config";
import ReactMarkdown from "react-markdown";
import { jwtDecode } from "jwt-decode";
import "./table.css";
import Notes from "./notes";
import Feed from "./feed";
import Spinner from "react-bootstrap/Spinner";
import UploadFiles from "../Engine-Page/mainEngine/Components/UploadFiles";
import { Button } from "react-bootstrap";
import { convertToKolkataTimeFormat } from "../../helpers";
import { FaX } from "react-icons/fa6";

const Patients = ({ setIsContent, doctorDetails }) => {
  const [patientList, setPatientList] = useState([]);
  const navigate = useNavigate();
  const [interactions, setInteractions] = useState([]);
  const [interactionsAudio, setInteractionsAudio] = useState([]);
  const [selectedPatientName, setSelectedPatientName] = useState("");
  // const [filteredPatients, setFilteredPatients] = useState(patientList);
  const [isPatientHistory, setIsPatientHistory] = useState(false);
  const [searchPatient, setSearchPatient] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isInteractionDetail, setIsInteractionDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [notes, setNotes] = useState("");
  const [doctorNotes, setDoctorNotes] = useState("");
  const [speech, setSpeech] = useState("");
  const [audio, setAudio] = useState("");
  const [id, setId] = useState();
  const [date, setDate] = useState();
  // const [decodeValue, setDecodeValue] = useState([]);
const content="detail"
  useEffect(() => {}, []);

  // const fetchInteractionDetails = async (interactionId) => {
  //   console.log(interactionId)
  //   try {
  //     const requestOptions = {
  //       method: "GET",
  //       headers: { "Content-Type": "application/json" },
  //     };
  //     const response = await fetch(
  //       `${configJson.backend_URL}/interactionDetail/${interactionId}`,
  //       requestOptions
  //     );

  //     const result = await response.json();
  //     if (response.ok) {
  //       const result = await response.json();
  //       //console.log(result)
  //       if (result) {
  //         const soapNote = result.find(
  //           (item) =>
  //             item.interaction_detail_type === "soap-notes" ||
  //             item.interaction_detail_type === "processed-speech-to-txt"
  //         );
  //         const processedSpeechToText = result.find(
  //           (item) =>
  //             item.interaction_detail_type === "raw-audio-txt" ||
  //             item.interaction_detail_type === "processed-audio"
  //         );
  //         const audio_data = result.find(
  //           (item) => item.interaction_detail_type === "Audio url"
  //         );
  //         if (soapNote) {
  //          // console.log(soapNote.processedFileContent);
  //             const text = soapNote.processedFileContent.replace(/^## SOAP Notes\s*/i, '');  //soap nodes removed
  //           //const text = soapNote.processedFileContent;
  //           setNotes(text);
  //         } else {
  //           setNotes("soap notes Empty");
  //         }
  //         if (processedSpeechToText) {
  //           setSpeech(processedSpeechToText.processedFileContent);
  //         } else {
  //           setSpeech("speech to text Empty");
  //         }
  //         if (audio_data) {
  //           setAudio(audio_data);
  //           if (audio_data.detail_notes !== null) {
  //             setDoctorNotes(audio_data.detail_notes);
  //           }
  //         }
  //       } else {
  //         alert(result.message);
  //       }
  //     } else {
  //       alert(response);
  //       console.log(response);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  const fetchPatientList = async (doctor_id) => {
    setLoading(true);
    try {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };
      const response = await fetch(
        `${configJson.backend_URL}/interaction/patient/${doctor_id}`,
        requestOptions
      );
      // const response = await fetch(
      //   `${configJson.backend_URL}/interaction/${doctor_id}`,
      //   requestOptions
      // );

      const data = await response.json();
      if (response.ok) {
        setPatientList(data);
      } else {
        alert("Error fetching patients");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  // console.log(patientList)

  useEffect(() => {
    const token = sessionStorage.getItem("orgtoken");
    if (token) {
      if (doctorDetails) {
        fetchPatientList(doctorDetails?.data.doctor_id);
      } else {
        const decoded = jwtDecode(token);
        fetchPatientList(decoded.doctor_id);
      }
    } else {
      navigate("/login");
    }
  }, [navigate, doctorDetails]);

  // const formatDate = (dateString) => {
  //   const options = { day: "numeric", month: "short", year: "numeric" }; // Format: 24 Apr, 2023
  //   return new Date(dateString).toLocaleDateString("en-GB", options); // Use 'en-GB' for this format
  // };

  // const renderMedia = (url) => {
  //   const extension = url.split(".").pop();

  //   if (extension === "mp3" || extension === "wav") {
  //     return (
  //       <audio controls className="w-full">
  //         <source src={url} type="audio/mp3" />
  //         Your browser does not support the audio element.
  //       </audio>
  //     );
  //   } else if (
  //     extension === "jpg" ||
  //     extension === "jpeg" ||
  //     extension === "png" ||
  //     extension === "gif"
  //   ) {
  //     return <img src={url} alt="attachment" className="rounded-lg m-auto" />;
  //   } else if (extension === "txt") {
  //     return (
  //       <p>
  //         <ReactMarkdown>{url}</ReactMarkdown>
  //       </p>
  //     );
  //   } else {
  //     return (
  //       <p>
  //         <ReactMarkdown>{url}</ReactMarkdown>
  //       </p>
  //     );
  //   }
  // };

  const fetchInteractions = async (patientId, name) => {
    // console.log(patientId);
    setLoading(true);
    try {
      // const response = await fetch(`
      //   ${configJson.backend_URL}/interactionDetail/${patientId}`);
      const response = await fetch(`
        ${configJson.backend_URL}/interactionDetail/getAllInteractionDetails/patient/${patientId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch interactions");
      }
      const result = await response.json();
      setInteractions(result);
      setSelectedPatientName(name);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchInteractionsByAudio = async (patientId, name) => {

    try {
      // Construct the endpoint URL
      const response = await fetch(
        `${configJson.backend_URL}/interactionDetail/getRawAudioTextDetailsByPatientId/patient/${patientId}`
      );

      // Check if the response is successful
      if (!response.ok) {
        throw new Error("Failed to fetch interactions");
      }

      // Parse the response JSON
      const result = await response.json();
      console.log("Result audio interaction", result);

      // Handle the result (process the data if needed)
      if (result && result.Interactions && result.Interactions.length > 0) {
        setInteractionsAudio(result.Interactions); // Set the interactions
      } else {
        console.log("No raw-audio-txt interactions found for this patient.");
        setInteractionsAudio([]); // Empty interactions if none are found
      }
    } catch (err) {
      console.error("Error fetching interactions:", err);
    } finally {
      setLoading(false); // Stop the loading state
    }
  };

  const handleSearch = (e) => {
    setSearchPatient(e.target.value);
  };

  const filteredPatients = patientList?.filter((patient) =>
    patient?.Patient?.first_name
      ?.toLowerCase()
      .includes(searchPatient.toLowerCase())
  );

  const filteredByDate = (list) => {
    if (!startDate && !endDate) return list;

    const start = new Date(startDate);
    const end = new Date(endDate);

    return list.filter((data) => {
      const updatedAt = new Date(data.last_visited);
      return (
        (!startDate || updatedAt >= start) && (!endDate || updatedAt <= end)
      );
    });
  };


  const audioUrlInteractionId = interactionsAudio
  ?.flatMap(interaction => 
    interaction.InteractionDetails?.filter(detail => detail.interaction_detail_type === "Audio url")
  ) // Flatten the array of details for all interactions with "Audio url" type
  .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort by created_at, latest first
  ?.shift()?.interaction_id;

  const filteredInteractions = filteredByDate(filteredPatients);
  // console.log(filteredInteractions)

  const patientsPerPage = 5;
  const visiblePageButtons = 5;

  const totalPages = Math.ceil(filteredInteractions.length / patientsPerPage);

  // Get current patients to display
  const indexOfLastPatient = currentPage * patientsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;

  const currentPatients = filteredInteractions
    .sort((a, b) => new Date(b.last_visited) - new Date(a.last_visited))
    .slice(indexOfFirstPatient, indexOfLastPatient);

  const nextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const previousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  const startPage = Math.max(
    1,
    currentPage - Math.floor(visiblePageButtons / 2)
  );
  const endPage = Math.min(totalPages, startPage + visiblePageButtons - 1);

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  // useEffect(() => {
  //   const token = sessionStorage.getItem("orgtoken");
  //   if (token) {
  //     const decoded = jwtDecode(token);
  //     setDecodeValue(decoded);
  //     // console.log(decoded)
  //   } else {
  //     navigate("/login");
  //   }
  // }, [navigate]);

  const handlePatients = (patientId, patientName) => {
    // Get the existing array of patients from localStorage, or an empty array if not present
    const existingPatients = JSON.parse(localStorage.getItem("patients")) || [];

    // Check if the patient already exists by ID
    const isPatientExists = existingPatients.some(
      (patient) => patient.id === patientId
    );

    if (!isPatientExists) {
      // Add the new patient object to the array
      existingPatients.push({ id: patientId, name: patientName });
      localStorage.setItem("patients", JSON.stringify(existingPatients));
    }
  };

  return (
    <>
      {/* <UploadFiles
        show={uploadModal}
        onClose={() => setUploadModal(false)}
        interactionId={userDetails?.interactionId}
        patientId={userDetails?.patientId}
      /> */}
      {!isInteractionDetail ? (
        <div className="mt-">
          {/* <div className={`flex justify-between items-center gap-2 ${!isPatientHistory ? 'mb-3' : ''}`}>
            {isPatientHistory && selectedPatientName != "" ? (
              <div
                className="flex items-center justify-between  bg-white p-2 w-full"
                // style={{ marginTop: "100px" }}
              >
                <div className="text-lg font-semibold flex items-center gap-2">
                  <Avatar src={`/`} />
                  <div>
                    {interactions?.first_name} {interactions?.last_name} visit
                    history
                  </div>
                </div>
                <div className="flex items-center gap-2 text-sm">
                  <button
                    className="px-4 py-2 rounded-3xl text-white"
                    onClick={() => {
                      setIsPatientHistory(false);
                      setInteractions([]);
                      setSelectedPatientName("");
                      setIsContent(false);
                    }}
                    style={{
                      background: "linear-gradient(to right, #1C1B1B, #5B5B5B)",
                    }}
                  >
                    Back
                  </button>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {!isPatientHistory && (
              <div className="flex flex-col sm:flex-row items-center gap-2">
                <div className="flex items-center gap-2">
                  <div className="flex items-center gap-2 text-xs p-2 border-2 w-fit rounded-lg">
                    <input
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="outline-none cursor-pointer bg-transparent"
                    />
                    <input
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      className="outline-none cursor-pointer bg-transparent"
                    />
                    <img src={`/calendar.png`} alt="" width={12} />
                  </div>
                </div>
                <div className="flex items-center p-2 py-1 border-2 rounded-lg text-sm placeholder:text-sm">
                  <input
                    className="ml-2 bg-transparent outline-none"
                    type="text"
                    placeholder="Search by Name"
                    onChange={handleSearch}
                    value={searchPatient}
                  />
                  {searchPatient === "" ? (
                    <SearchIcon />
                  ) : (
                    <FaX
                      className="cursor-pointer"
                      onClick={() => setSearchPatient("")}
                    />
                  )}
                </div>
              </div>
            )}
          </div> */}
          {!isPatientHistory ? (
            <div className="flex flex-col gap-2">
              {loading ? (
                <div className="flex items-center justify-center h-full">
                  <Spinner animation="border" />
                </div>
              ) : (
                <>
                  <div className="overflow-x-auto m-auto w-full feed-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Patient Name</th>
                          {/* <th>Patient Id</th> */}
                          <th>Doctor Name</th>
                          {/* <th>First Visit</th> */}
                          <th>Last Visit</th>
                          {/* <th>Assessment</th> */}
                          {/* <th>Plan</th> */}
                          <th>Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {interactions?.length > 0
                    ? interactions.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)).map((data, index) => (
                    <tr key={index}>
                    <td className="hover:text-blue-600 cursor-pointer">
                        {selectedPatientName}
                    </td>
                    <td>{data.patient_id}</td>
                    <td>John</td>
                    <td>{formatDate(data.created_at)}</td>
                    <td>{formatDate(data.updated_at)}</td>
                    <td>Potential diagnoses...</td>
                    <td>Treatment: An X-ray..</td>
                    <td
                    onClick={() => {
                      fetchInteractionDetails(data.interaction_id)
                      setIsInteractionDetail(true)
                      }}
                    >
                        <img
                        src={`/details.png`}
                        alt=""
                        width={20}
                        className="m-auto cursor-pointer"
                        />
                    </td>
                    </tr>
                ))
                :  */}
                        {currentPatients
                          .sort(
                            (a, b) =>
                              new Date(b.last_visited) -
                              new Date(a.last_visited)
                          )
                          .map((data, index) => {
                            // console.log(data)
                            return (
                              <tr
                                key={index}
                                className="cursor-pointer hover:bg-gray-200"
                                onClick={() => {
                                  fetchInteractions(
                                    data?.Patient?.patient_id,
                                    `${data?.Patient?.first_name} ${data?.Patient?.last_name}`
                                  );
                                  // Fetch interactions by audio
                                  fetchInteractionsByAudio(
                                    data?.Patient?.patient_id,
                                    `${data?.Patient?.first_name} ${data?.Patient?.last_name}`
                                  );
                                  setIsPatientHistory(true);
                                  setIsContent(true);
                                  handlePatients(
                                    data?.Patient?.patient_id,
                                    `${data?.Patient?.first_name} ${data?.Patient?.last_name}`
                                  );
                                }}
                              >
                                <td
                                  className="hover:text-blue-600 cursor-pointer"
                                  // onClick={() => {
                                  //   fetchInteractions(
                                  //     data?.Patient?.patient_id,
                                  //     `${data?.Patient?.first_name} ${data?.Patient?.last_name}`
                                  //   );
                                  //   setIsPatientHistory(true);
                                  //   setIsContent(true)
                                  // }}
                                >
                                  {data?.Patient?.first_name}{" "}
                                  {data?.Patient?.last_name}
                                </td>
                                {/* <td>{data.patient_id}</td> */}
                                <td>
                                  {data?.Doctor?.first_name}{" "}
                                  {data?.Doctor?.last_name}
                                </td>
                                {/* <td>{formatDate(data.created_at)}</td> */}
                                <td>
                                  {convertToKolkataTimeFormat(
                                    data?.last_visited
                                  )}
                                </td>
                                {/* <td>Potential diagnoses...</td> */}
                                {/* <td>Treatment: An X-ray..</td> */}
                                <td
                                // onClick={() => {
                                //   fetchInteractionDetails(data.interaction_id);
                                //   setId(data.interaction_id);
                                //   setIsInteractionDetail(true);
                                //   setSelectedPatientName(`${data?.Patient?.first_name} ${data?.Patient?.last_name}`)
                                //   setDate(data?.interaction_date)
                                //   setIsContent(true)
                                // }}
                                // onClick={() => {
                                //   fetchInteractions(
                                //     data?.Patient?.patient_id,
                                //     `${data?.Patient?.first_name} ${data?.Patient?.last_name}`
                                //   );
                                //   setIsPatientHistory(true);
                                //   setIsContent(true)
                                // }}
                                >
                                  <img
                                    src={`/details.png`}
                                    alt=""
                                    width={20}
                                    className="m-auto cursor-pointer"
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="flex items-center justify-center sm:justify-between mt-4">
                    <button
                      onClick={previousPage}
                      disabled={currentPage === 1}
                      className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l ${
                        currentPage === 1 ? "invisible" : ""
                      } hidden sm:block`}
                    >
                      Previous
                    </button>

                    <div className="flex items-center gap-2">
                      {/* Always show the first page */}
                      {startPage > 1 && (
                        <>
                          <Button
                            className={`!border-none ${
                              currentPage === 1
                                ? "!bg-gray-400"
                                : "!bg-[#414040]"
                            }`}
                            onClick={() => handlePageClick(1)}
                          >
                            1
                          </Button>
                          {startPage > 2 && <span>...</span>}
                        </>
                      )}

                      {/* Show visible page buttons */}
                      {pageNumbers.map((pageNumber) => (
                        <Button
                          key={pageNumber}
                          className={`!border-none ${
                            pageNumber === currentPage
                              ? "!bg-gray-400"
                              : "!bg-[#414040]"
                          }`}
                          onClick={() => handlePageClick(pageNumber)}
                        >
                          {pageNumber}
                        </Button>
                      ))}

                      {/* Always show the last page */}
                      {endPage < totalPages && (
                        <>
                          {endPage < totalPages - 1 && <span>...</span>}
                          <Button
                            className={`!border-none ${
                              currentPage === totalPages
                                ? "!bg-gray-400"
                                : "!bg-[#414040]"
                            }`}
                            onClick={() => handlePageClick(totalPages)}
                          >
                            {totalPages}
                          </Button>
                        </>
                      )}
                    </div>

                    <button
                      onClick={nextPage}
                      disabled={currentPage === totalPages}
                      className={`bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r ${
                        currentPage === totalPages ? "invisible" : ""
                      } hidden sm:block`}
                    >
                      Next
                    </button>
                  </div>
                </>
              )}

              {/* <div className="flex justify-end">
              <div className="flex items-center gap-1">
                <div>Next</div>
                <img
                  src={`/right_arrow.png`}
                  alt=""
                  width={13}
                  className="mt-[3px]"
                />
              </div>
            </div> */}
            </div>
          ) : loading ? (
            <div className="flex items-center justify-center">
              <Spinner animation="border" />
            </div>
          ) : (
            <div className="">
              <Feed
                interactions={interactions}
                selectedPatientName={selectedPatientName}
                fetchInteractions={fetchInteractions}
                doctorDetails={doctorDetails?.data ? doctorDetails?.data : ""}
                audioUrlInteractionId={audioUrlInteractionId}
                content={content}
              />
            </div>
          )}
        </div>
      ) : (
        <Notes
          setIsInteractionDetail={setIsInteractionDetail}
          interaction_id={id}
          patientName={selectedPatientName}
          interaction_date={date}
          setIsContent={setIsContent}
        />
      )}
    </>
  );
};

export default Patients;
